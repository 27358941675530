import React, { useEffect, useState } from 'react'
import { Col, Container, Nav, Navbar, OverlayTrigger, Popover, Row } from 'react-bootstrap'
import headerHome from '../assets/icon/Header_Home.png'
import headerAboutUs from '../assets/image/Header_About_Us.jpg'
import ezIcon from '../assets/icon/ezeelink_icon.png'
import ezIconCHN from '../assets/icon/ezeelink_icon_chn.png'
import dropDownWhite from '../assets/icon/drop_down_white.svg'
import remittanceIcon from '../assets/icon/icon_Remittance_Business.svg'
import remittancePersonalIcon from '../assets/icon/icon_Remittance_personal.svg'
import offshoreBankIcon from '../assets/icon/icon_offshore_bank.svg'
import emoneyIcon from '../assets/icon/icon_emoney.svg'
import whiteLabelWalletIcon from '../assets/icon/icon_white_label_wallet.svg'
import paymentCollectionIcon from '../assets/icon/icon_payment_collection.svg'
import cardlessCashIcon from '../assets/icon/icon_cardless_cash.svg'
import transferIcon from '../assets/icon/icon_Transfer.svg'
import voucherIcon from '../assets/icon/icon_voucher.svg'
import qrisIcon from '../assets/icon/icon_qris.svg'
import pulsaTagihanIcon from '../assets/icon/icon_pulsa_tagihan.svg'
import loyaltyIcon from '../assets/icon/icon_loyalty.svg'
import flagIdRoundIcon from '../assets/icon/flag_id_round.svg'
import flagEnRoundIcon from '../assets/icon/flag_en_round.svg'
import flagRrtRoundIcon from '../assets/icon/flag_rrt_round.svg'
import './navbar.css'
import { useHistory, useLocation } from 'react-router-dom'
import chatMessageIcon from '../assets/icon/chat_message.svg'
import { waEzeelink } from '../function/helpers'
import menuIcon from '../assets/icon/menu_icon.svg'
import headerMobile from '../assets/image/header_about_mobile.jpg'
import mobile from 'is-mobile'

function Navbar3() {

    const [push, setPush] = useState(false)
    const history = useHistory()
    const location = useLocation()
    const [isAboutUS, setIsAboutUS] = useState(false)
    const [showPopover, setShowPopover] = useState(false)
    const [showPopoverLang, setShowPopoverLang] = useState(false)
    const language = sessionStorage.getItem('lang')
    const isMobile = mobile()

    function chnLang() {
        sessionStorage.setItem('lang', 'chn');
        window.location.reload();
    }

    function engLang() {
        sessionStorage.setItem('lang', 'eng');
        window.location.reload();
    }

    function inaLang() {
        sessionStorage.removeItem('lang');
        window.location.reload();
    }

    function toMenu () {
        setPush(true)
        history.push('/Menu', {prevURL: location.pathname})
        window.location.reload();
    }

    function navbarHandle(menuName) {
        if (menuName === "tentang-kami") {
            history.push("/AboutUs")
            window.location.reload()
        } else if (menuName === 'pertanyaan') {
            history.push("/faq")
            window.location.reload()
        }
    }

    function navigateToHome(section, isAboutUsPage, isRemitancePage) {
        if (isAboutUsPage) {
            history.push('/', { section: section });
            window.location.reload();
        } 
    }

    useEffect(() => {
        if (location.pathname === "/AboutUs") {
            setIsAboutUS(true)
        }
    }, [location])

    return (
        <header className="header-home ">
            <Navbar bg="transparent" expand="lg" className={isMobile ? "d-flex position-absolute justify-content-between align-items-center mt-4 px-3" : "d-flex position-absolute justify-content-between align-items-center mt-4"} style={{ width: "100%", padding: isMobile ? "" : "0rem 6rem" }}>
                {/* <Container> */}
                    <Navbar.Brand href="/" style={{ zIndex: 1 }}><img src={language === 'chn' ? ezIconCHN : ezIcon} alt='icon-ez' width = {isMobile ? 85.96 : 90} height = {isMobile ? 45.58 : 48} className="img-fluid" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toMenu} style={{ color: "#FFFFFF", border: "unset", zIndex: 1 }} ><img src={menuIcon} alt="menu"/></Navbar.Toggle>
                    {push === true ?
                        "" :
                        <>
                            <Navbar.Collapse id="basic-navbar-nav" style={{ width: "100%"}}>
                                <Nav className='text-center' style={{ width: "100%"}}>
                                    <div className='text-center' style={{ width: "100%", zIndex: 1 }}>
                                        <OverlayTrigger
                                            show={showPopover}
                                            trigger={["hover", "focus"]}
                                            key={1}
                                            placement="bottom"
                                            overlay={
                                                <Popover style={{ maxWidth: 600, maxHeight: 550 }} onMouseEnter={() => setShowPopover(true)} onMouseLeave={() => setShowPopover(false)} id='menu'>
                                                    <table className="table-products-item2 mt-4 mb-3">
                                                        <thead>
                                                            <th>{language === 'eng' ? 'Corporate & SME' : language === 'chn' ? '企业和出海企业' : 'Korporat & UMKM'}</th>
                                                            <th>{language === 'eng' ? 'Personal (Consumer)' : language === 'chn' ? '个人（消费者）' : 'Personal (Konsumer)'}</th>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('remitance', isAboutUS)}>
                                                                    <div><img src={remittanceIcon} alt='remitance' /> { language === 'eng' ? 'Remittance for Bussiness' : language === 'chn' ? '商务汇款' : 'Remitansi untuk Bisnis' }</div>
                                                                </td>                        
                                                                <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('remitancePersonal', isAboutUS)}>
                                                                    <div><img src={remittancePersonalIcon} alt='remitancePersonal' /> { language === 'eng' ? 'Remittance for Personal' : language === 'chn' ? '个人汇款' : 'Remitansi untuk Personal' }</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('offshore', isAboutUS)}>
                                                                    <div><img src={offshoreBankIcon} alt='offshoreBankAccount' /> { language === 'eng' ? 'Offshore Bank Account' : language === 'chn' ? '离岸银行账户' : 'Offshore Bank Account' }</div>
                                                                </td>
                                                                <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('uangElektronik', isAboutUS)}>
                                                                    <div><img src={emoneyIcon} alt='uangElektronik' /> { language === 'eng' ? 'Electronic Money' : language === 'chn' ? '电子货币' : 'Uang Elektronik' }</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('whiteLabel', isAboutUS)}>
                                                                    <div><img src={whiteLabelWalletIcon} alt='whiteLabelWallet' /> { language === 'eng' ? 'White-Label Wallet' : language === 'chn' ? '白牌钱包' : 'White-Label Wallet' }</div>
                                                                </td>                        
                                                                <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('wallet', isAboutUS)}>
                                                                    <div><img src={whiteLabelWalletIcon} alt='wallet' /> { language === 'eng' ? 'Wallet' : language === 'chn' ? '钱包' : 'Wallet' }</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('payment', isAboutUS)}>
                                                                    <div><img src={paymentCollectionIcon} alt='paymentCollection' /> { language === 'eng' ? 'Payment Collection' : language === 'chn' ? '收款' : 'Payment Collection' }</div>
                                                                </td>
                                                                <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('tarikTunaiTanpaKartu', isAboutUS)}>
                                                                    <div><img src={cardlessCashIcon} alt='tarikTunaiTanpaKartu' /> { language === 'eng' ? 'Cardless Cash Withdrawal' : language === 'chn' ? '无卡取款' : 'Tarik Tunai tanpa Kartu' }</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('transfer', isAboutUS)}>
                                                                    <div><img src={transferIcon} alt='transferPembayaran' /> { language === 'eng' ? 'Transfer Payment' : language === 'chn' ? '转账付款' : 'Transfer Pembayaran' }</div>
                                                                </td>
                                                                <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('voucherDigital', isAboutUS)}>
                                                                    <div><img src={voucherIcon} alt='voucherDigital' /> { language === 'eng' ? 'Digital Voucher' : language === 'chn' ? '数字优惠券' : 'Voucher Digital' }</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('qris', isAboutUS)}>
                                                                    <div><img src={qrisIcon} alt='qris' /> QRIS</div>
                                                                </td>
                                                                <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('pulsaTagihan', isAboutUS)}>
                                                                    <div><img src={pulsaTagihanIcon} alt='pulsaTagihan' /> { language === 'eng' ? 'Bills & Credits' : language === 'chn' ? '信用和票据' : 'Pulsa & Tagihan' }</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('loyalty', isAboutUS)}>
                                                                    <div><img src={loyaltyIcon} alt='loyalty' /> { language === 'eng' ? 'Loyalty Program' : language === 'chn' ? '忠诚计划' : 'Loyalty' }</div>
                                                                </td>
                                                            </tr>                                                                
                                                        </tbody>
                                                    </table>
                                                </Popover>
                                            }
                                        >
                                            <span onMouseEnter={() => setShowPopover(true)} onMouseLeave={() => setShowPopover(false)} className='menu-navbar-home2 py-3 px-3 mx-3' style={{ borderRadius: 4, color: "#FFFFFF", cursor: "pointer" }}>{ language === 'eng' ? 'Product & Solution' : language === 'chn' ? '产品与解决方案' : 'Produk & Solusi' } <img src={dropDownWhite} alt='arrowDropDownWhiteProduct' className={(showPopover) ? 'arrow-down-white-product-open' : "arrow-down-white-product"} /></span>
                                        </OverlayTrigger>
                                        <span onClick={() => navigateToHome('merchant', isAboutUS)} className='menu-navbar-home2 py-3 px-3 mx-3' style={{ borderRadius: 4, color: "#FFFFFF", cursor: "pointer" }}>{ language === 'eng' ? 'Merchant & Partner' : language === 'chn' ? '商户和合作伙伴' : 'Merchant & Partner' }</span>
                                        <span onClick={() => navbarHandle("tentang-kami")} className='menu-navbar-home2 py-3 px-3 mx-3' style={{ borderRadius: 4, color: "#FFFFFF", cursor: "pointer" }}>{ language === 'eng' ? 'About Us' : language === 'chn' ? '关于我们' : 'Tentang Kami' }</span>
                                        <span onClick={() => navbarHandle("pertanyaan")} className='menu-navbar-home2 py-3 px-3 mx-3' style={{ borderRadius: 4, color: "#FFFFFF", cursor: "pointer", fontWeight: 700 }}>FAQ</span>
                                    </div>
                                </Nav>
                            </Navbar.Collapse>
                            <Navbar.Collapse id="basic-navbar-nav" style={{ width: "15%"}}>
                                <div className="text-end" style={{ width: "100%"}} >
                                    <OverlayTrigger
                                        show={showPopoverLang}
                                        trigger={["hover", "focus"]}
                                        key={1}
                                        placement="bottom"
                                        overlay={
                                            <Popover style={{ maxWidth: 138, maxHeight: 212 }} onMouseEnter={() => setShowPopoverLang(true)} onMouseLeave={() => setShowPopoverLang(false)} id='menu'>
                                                <Popover.Body>
                                                    <div className='mt-2 mb-4' onClick={() => inaLang()}>
                                                        <span className='menu-navbar-lang2' style={{ borderRadius: 4, color: "#383838", cursor: "pointer" }}><img src={flagIdRoundIcon} alt='idFlag' style={{ marginRight: 15 }} /> ID</span>
                                                    </div>
                                                    <div className='mt-4 mb-4' onClick={() => engLang()}>
                                                        <span className='menu-navbar-lang2' style={{ borderRadius: 4, color: "#383838", cursor: "pointer" }}><img src={flagEnRoundIcon} alt='idFlag' style={{ marginRight: 10 }} /> EN</span>
                                                    </div>
                                                    <div className='mt-4 mb-2' onClick={() => chnLang()}>
                                                        <span className='menu-navbar-lang2' style={{ borderRadius: 4, color: "#383838", cursor: "pointer" }}><img src={flagRrtRoundIcon} alt='idFlag' style={{ marginRight: 10 }} /> CN</span>
                                                    </div>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <span onMouseEnter={() => setShowPopoverLang(true)} onMouseLeave={() => setShowPopoverLang(false)} className='menu-navbar-home2 py-3 px-3' style={{ borderRadius: 4, color: "#FFFFFF", cursor: "pointer" }}>{language === 'eng' ? <><img src={flagEnRoundIcon} alt='enFlag' /> &nbsp; EN</> : language === 'chn' ? <><img src={flagRrtRoundIcon} alt='chnFlag' /> &nbsp; CN</> : <><img src={flagIdRoundIcon} alt='idFlag' /> &nbsp; ID</>}<img src={dropDownWhite} alt='arrowDropDownWhiteLanguage' className={(showPopoverLang) ? 'arrow-down-white-language-open' : "arrow-down-white-language"} style={{ marginLeft: 12 }} /></span>
                                    </OverlayTrigger>
                                </div>
                            </Navbar.Collapse>
                        </>
                    }
                {/* </Container> */}
            </Navbar>
            <Row>
                <Col xl={12} style={{ paddingBottom: isMobile ? 30 : "", zIndex: 999, paddingRight: "unset" }} className="d-flex justify-content-end align-items-center bottom-0 position-fixed">
                    <img onClick={() => waEzeelink()} src={chatMessageIcon} alt='chatMessageIcon' style={{ cursor: 'pointer'}} width = {isMobile ? 48 : 85} height = {isMobile ? 48 : 85} className="img-fluid" />
                </Col>
            </Row>
            <div  className={isMobile ? "position-absolute mt-5 pt-5" : "position-absolute mt-5 pt-5"} style={{ padding: isMobile ? "" : "0rem 3rem" }}>
                <div className={isMobile ? "mt-5 pt-5 px-3" : "mt-5 pt-5 px-5"}>
                    {
                        isAboutUS ?
                            <>
                                <span style={{ color: "#FFFFFF", fontSize: isMobile ? 36 : 48, fontWeight: 700 }}>{ language === 'eng' ? 'About Us' : language === 'chn' ? '关于我们' : 'Tentang Kami' }</span><br/>
                            </> :
                            <>
                                <span style={{ color: "#FFFFFF", fontSize: isMobile ? 36 : 48, fontWeight: 700 }}>{ language === 'eng' ? 'Make Financial' : language === 'chn' ? '让金融交易更容易' : 'Jadikan Transaksi' }</span><br />
                                <span style={{ color: "#FFFFFF", fontSize: isMobile ? 36 : 48, fontWeight: 700 }}>{ language === 'eng' ? 'Transactions Easier' : language === 'chn' ? '' : 'Keuangan Lebih Mudah' }</span><br /><br />
                            </>
                    }
                    {
                        isAboutUS ?
                            <>
                                {isMobile ? 
                                    <>
                                        <br/><span style={{ color: "#FFFFFF", fontSize: isMobile ? 16 : 20, fontWeight: 400, fontFamily: 'Open Sans' }}>{ language === 'eng' ? 'Ezeelink is an electronic transaction service provider with various products that provide solutions for various financial needs.' : language === 'chn' ? 'Ezeelink 是一家电子支付服务平台，拥有各种支付产品，为各种金融需求提供解决方案。' : 'Ezeelink adalah penyedia layanan transaksi elektronik dengan berbagai produk yang memberi solusi untuk berbagai kebutuhan finansial.' }</span>
                                    </> :
                                    <>
                                        <br/><span style={{ color: "#FFFFFF", fontSize: isMobile ? 16 : 20, fontWeight: 400, fontFamily: 'Open Sans' }}>{ language === 'eng' ? 'Ezeelink is an electronic transaction service provider with various' : language === 'chn' ? 'Ezeelink 是一家电子支付服务平台，拥有各种支付产品，为各种金融需' : 'Ezeelink adalah penyedia layanan transaksi elektronik dengan' }</span><br/>
                                        <span style={{ color: "#FFFFFF", fontSize: isMobile ? 16 : 20, fontWeight: 400, fontFamily: 'Open Sans' }}>{ language === 'eng' ? 'products that provide solutions for various financial needs.' : language === 'chn' ? '求提供解决方案。' : 'berbagai produk yang memberi solusi untuk berbagai kebutuhan finansial.' }</span>
                                    </>
                                }
                            </> :
                            <>
                                <br/><span style={{ color: "#FFFFFF", fontSize: isMobile ? 16 : 20, fontWeight: 400, fontFamily: 'Open Sans' }}>{ language === 'eng' ? 'Ezeelink application and API facilitate digital transactions and' : language === 'chn' ? 'Ezeelink 应用程序和 API 接口促进了数字交易，并在每笔金融交易中提' : 'Aplikasi dan API Ezeelink memudahkan transaksi digital dan' }</span><br/>
                                <span style={{ color: "#FFFFFF", fontSize: isMobile ? 16 : 20, fontWeight: 400, fontFamily: 'Open Sans' }}>{ language === 'eng' ? 'provide security guarantees in every financial transaction.' : language === 'chn' ? '供安全保证。' : 'memberikan jaminan keamanan dalam setiap transaksi keuangan.' }</span>
                            </>
                    }
                </div>
            </div>
            {isMobile ? <img className='header-navbar-home2 img-fluid' src={(isAboutUS) ? headerMobile : headerHome} alt={(isAboutUS) ? "header_about_us" : "header_home"} /> : <img className='header-navbar-home2 img-fluid' src={(isAboutUS) ? headerAboutUs : headerHome} alt={(isAboutUS) ? "header_about_us" : "header_home"} />}
        </header>
    )
}

export default Navbar3