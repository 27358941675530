import React, { useEffect, useState } from 'react'
import { Container, Image, OverlayTrigger, Popover } from 'react-bootstrap'
import flagIdRoundIcon from '../../assets/icon/flag_id_round.svg'
import flagEnRoundIcon from '../../assets/icon/flag_en_round.svg'
import flagRrtRoundIcon from '../../assets/icon/flag_rrt_round.svg'
import dropdownBlack from '../../assets/icon/drop_down_black.svg'
import { useHistory } from 'react-router-dom'
import chevronLeft from '../../assets/icon/chevron_left.svg'
import success from '../../assets/icon/success.svg'
import loadingEzeelink from "../../assets/image/Double Ring-1s-303px.svg";
import mobile from 'is-mobile'
import { useTitle } from '../../function/helpers'

const Confirmation = () => {
    useTitle("Confirmation")

    const [showPopoverLang, setShowPopoverLang] = useState(false)
    const history = useHistory()
    const language = sessionStorage.getItem('lang')
    const [isLoading, setIsLoading] = useState(true)
    const isMobile = mobile()

    function backToRemitance() {
        history.push('/remitance')
        window.location.reload()
    }

    function goToHome() {
        history.push('/')
        window.location.reload()
    }

    function chnLang() {
        sessionStorage.setItem('lang', 'chn');
        window.location.reload();
    }

    function engLang() {
        sessionStorage.setItem('lang', 'eng');
        window.location.reload();
    }

    function inaLang() {
        sessionStorage.removeItem('lang');
        window.location.reload();
    }

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 1000);
    }, [])


    const CustomLoader = () => (
        <div style={{ padding: "36px" }}>
            <Image
                className="loader-element animate__animated animate__jackInTheBox"
                src={loadingEzeelink}
                height={80}
            />
            <div>Loading...</div>
        </div>
    );

    return (
        <div className='main-content align-items-center'>
            {
                isLoading ?
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <CustomLoader />
                    </div> :
                    <>
                        <div className='base-content-form' style={{ width: "100%" }}>
                            <Container>
                                <div className='d-flex justify-content-between align-items-center py-3'>
                                    <div style={{ cursor: 'pointer' }} onClick={() => backToRemitance()} className='d-flex justify-content-between align-items-center'><img src={chevronLeft} alt="chevron left" /><span style={{ fontSize: 16, fontWeight: 700, fontFamily: 'Exo' }}>{language === 'eng' ? 'Back' : language === 'chn' ? '返回' : 'Kembali'}</span></div>
                                    <div>
                                        <OverlayTrigger
                                            show={showPopoverLang}
                                            trigger={["hover", "focus"]}
                                            key={1}
                                            placement="bottom"
                                            overlay={
                                                <Popover onMouseEnter={() => setShowPopoverLang(true)} onMouseLeave={() => setShowPopoverLang(false)} style={{ maxWidth: 138, maxHeight: 212 }} id='menu'>
                                                    <Popover.Body>
                                                        <div className='mt-2 mb-4' onClick={() => inaLang()}>
                                                            <span className='menu-navbar-lang2' style={{ borderRadius: 4, color: "#383838", cursor: "pointer" }}><img src={flagIdRoundIcon} alt='idFlag' style={{ marginRight: 10 }} /> ID</span>
                                                        </div>
                                                        <div className='mt-4 mb-4' onClick={() => engLang()}>
                                                            <span className='menu-navbar-lang2' style={{ borderRadius: 4, color: "#383838", cursor: "pointer" }}><img src={flagEnRoundIcon} alt='idFlag' style={{ marginRight: 10 }} /> EN</span>
                                                        </div>
                                                        <div className='mt-4 mb-2' onClick={() => chnLang()}>
                                                            <span className='menu-navbar-lang2' style={{ borderRadius: 4, color: "#383838", cursor: "pointer" }}><img src={flagRrtRoundIcon} alt='idFlag' style={{ marginRight: 10 }} /> CN</span>
                                                        </div>
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <span onMouseEnter={() => setShowPopoverLang(true)} onMouseLeave={() => setShowPopoverLang(false)} className='menu-navbar-home2 py-3 px-3' style={{ borderRadius: 4, color: "#383838", cursor: "pointer" }}>{language === 'eng' ? <><img src={flagEnRoundIcon} alt='enFlag' /> &nbsp; EN</> : language === 'chn' ? <><img src={flagRrtRoundIcon} alt='cnFlag' /> &nbsp; CN</> : <><img src={flagIdRoundIcon} alt='idFlag' /> &nbsp; ID</>}<img src={dropdownBlack} alt='arrowDropDownWhiteLanguage' className={(showPopoverLang) ? 'arrow-down-white-language-open' : "arrow-down-white-language"} style={{ marginLeft: 12 }} /></span>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </Container>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div style={{width: isMobile ? '100%' : '75%'}} className={isMobile ? 'text-center align-items-center mt-3 pt-5 px-3' : 'text-center align-items-center mt-3 pt-5'}>
                            <img src={success} alt="success confirm" />
                            <div className='mt-3' style={{ fontFamily: 'Exo', fontWeight: 700, fontSize: 26, color: "#383838" }}>{language === 'eng' ? 'Thank You For Joining Us!' : language === 'chn' ? '我们已成功提交您的数据' : 'Data Anda Berhasil Di Submit'}</div>
                            <div className={isMobile ? 'mt-3 mb-4' : 'mt-3 px-5 mx-5 mb-4'} style={{ fontFamily: 'Open Sans', fontWeight: 400, fontSize: isMobile ? 14 : 16, color: "#888888" }}>{language === 'eng' ? 'Your information has been submitted and we will contact you within 24 hours.' : language === 'chn' ? '感谢您加入我们。 我们已收到您的数据，我们的销售将在 1 x 24 小时内与您联系' : 'Terima kasih sudah bergabung dengan kami. Data anda sudah kami terima, dan sales kami akan menghubungi anda dalam 1 x 24 jam'}</div>
                            <div  className='text-center mt-3'><button onClick={() => goToHome()} style={{width: 240}} className='btn-ez-on'>{language === 'eng' ? 'Go to Homepage' : language === 'chn' ? '前往主页' : 'Menuju Laman Utama'}</button></div>
                        </div>
                        </div>
                    </>
            }
        </div>
    )
}

export default Confirmation