import React, { useEffect, useState } from 'react'
import { Accordion, Col, Container, Nav, Navbar, OverlayTrigger, Popover, Row } from 'react-bootstrap'
import ezIcon from '../../assets/icon/ezeelink_icon.png'
import ezIconCHN from '../../assets/icon/ezeelink_icon_chn.png'
import remittanceIcon from '../../assets/icon/icon_Remittance_Business.svg'
import remittancePersonalIcon from '../../assets/icon/icon_Remittance_personal.svg'
import offshoreBankIcon from '../../assets/icon/icon_offshore_bank.svg'
import emoneyIcon from '../../assets/icon/icon_emoney.svg'
import whiteLabelWalletIcon from '../../assets/icon/icon_white_label_wallet.svg'
import paymentCollectionIcon from '../../assets/icon/icon_payment_collection.svg'
import cardlessCashIcon from '../../assets/icon/icon_cardless_cash.svg'
import transferIcon from '../../assets/icon/icon_Transfer.svg'
import voucherIcon from '../../assets/icon/icon_voucher.svg'
import qrisIcon from '../../assets/icon/icon_qris.svg'
import pulsaTagihanIcon from '../../assets/icon/icon_pulsa_tagihan.svg'
import loyaltyIcon from '../../assets/icon/icon_loyalty.svg'
import flagIdRoundIcon from '../../assets/icon/flag_id_round.svg'
import flagEnRoundIcon from '../../assets/icon/flag_en_round.svg'
import flagRrtRoundIcon from '../../assets/icon/flag_rrt_round.svg'
import lebihHemat from '../../assets/icon/lebih_hemat_icon.svg'
import prosesCepat from '../../assets/icon/proses_cepat_icon.svg'
import transparan from '../../assets/icon/transparan_icon.svg'
import responsif from '../../assets/icon/responsif_icon.svg'
import terpercaya from '../../assets/icon/terpercaya_icon.svg'
import '../../component/navbar.css'
import { useHistory, useLocation } from 'react-router-dom'
import Footer3 from '../../component/Footer3'
import instant from '../../assets/icon/instant.svg'
import fleksibel from '../../assets/icon/fleksibel.svg'
import headerRemitancePersonal from '../../assets/image/Header_Remitance_Personal.jpg'
import headerRemitanceBusiness from '../../assets/image/Header_Remitance_Business.jpg'
import headerRemitancePersonalMobile from '../../assets/image/remitansi_personal_mobile.png'
import headerRemitanceBusinessMobile from '../../assets/image/remitansi_bisnis_mobile.png'
import dropDownWhite from '../../assets/icon/drop_down_white.svg'
import chatMessageIcon from '../../assets/icon/chat_message.svg'
import menuIcon from '../../assets/icon/menu_icon.svg'
import { useTitle, waEzeelink } from '../../function/helpers'
import mobile from 'is-mobile'

const Remitansi = () => {
    useTitle("Remittance")

    const [openedProduct, setOpenedProduct] = useState(false)
    const [openedLanguage, setOpenedLanguage] = useState(false)
    const [push, setPush] = useState(false)
    const location = useLocation()
    const history = useHistory()
    const [isRemitancePersonal, setIsRemitancePersonal] = useState((sessionStorage.getItem('remitance') !== 'personal' || sessionStorage.getItem('remitance') === null) ? false : true)
    const [isRemitanceBisnis, setIsRemitanceBisnis] = useState((sessionStorage.getItem('remitance') === 'bisnis' || sessionStorage.getItem('remitance') === null) ? true : false)
    const language = sessionStorage.getItem('lang')
    const [loading, setLoading] = useState(true)
    const [showPopover, setShowPopover] = useState(false)
    const [showPopoverLang, setShowPopoverLang] = useState(false)
    const isMobile = mobile()

    function onPageLoad() {
        setLoading(false)
    }

    function chnLang() {
        sessionStorage.setItem('lang', 'chn');
        window.location.reload();
    }

    function engLang() {
        sessionStorage.setItem('lang', 'eng');
        window.location.reload();
    }

    function inaLang() {
        sessionStorage.removeItem('lang');
        window.location.reload();
    }

    function toMenu () {
        setPush(true)
        history.push('/Menu', {prevURL: location.pathname})
        window.location.reload();
    }

    function navbarHandle(menuName) {
        if (menuName === "tentang-kami") {
            history.push("/AboutUs")
            window.location.reload()
        } else if (menuName === 'pertanyaan') {
            history.push("/faq")
            window.location.reload()
        }
    }

    function navigateToHome(section) {
        history.push('/', {section: section});
        window.location.reload();
    }

    function toFaq(section) {
        document.getElementById(section).scrollIntoView()
    }

    // function remitasiHandler(locations) {
    //     if (locations.state !== undefined) {
    //         window.scroll({
    //             top: 100,
    //             left: 100,
    //             behavior: 'smooth'
    //         });
    //         if (sessionStorage.getItem('remitance') === 'bisnis' && (locations.state.section === 'bisnis' || locations.state.section === undefined)) {
    //             // location.state.section = 'remitancePersonal'
    //             // window.history.replaceState({}, 'remitancePersonal')
    //             setIsRemitancePersonal(false)
    //             setIsRemitanceBisnis(true)
    //         } else if (sessionStorage.getItem('remitance') === 'personal' && (locations.state.section === 'personal' || locations.state.section === undefined)) {
    //             // location.state.section = 'remitanceBisnis'
    //             // window.history.replaceState({}, 'remitanceBisnis')
    //             setIsRemitancePersonal(true)
    //             setIsRemitanceBisnis(false)
    //         }
    //     }
    // }

    function toForm() {
        history.push('/form')
        window.location.reload()
    }

    function tabRemitansi(tabRemitance) {
        if (tabRemitance === 'personal') {
            sessionStorage.setItem('remitance', 'personal');
            setIsRemitancePersonal(true)
            setIsRemitanceBisnis(false)
        } else {
            sessionStorage.setItem('remitance', 'bisnis');
            setIsRemitanceBisnis(true)
            setIsRemitancePersonal(false)
        }
    }

    useEffect(() => {

        window.scroll({
            top: 100,
            left: 100,
            behavior: 'smooth'
        });

        // remitasiHandler(location)

        if (document.readyState === 'complete') {
            onPageLoad()
        } else {
            window.addEventListener('load', onPageLoad)
            return () => window.removeEventListener('load', onPageLoad)
        }

        return history.listen(action => {
            if (action !== "PUSH") {
              // console.log("mskk");
                window.location.reload();
            }
        });

    }, [location])

    return (
        <div className='main-content align-items-center'>
            <div className='base-content-custom' style={{ width: "100%" }}>
                <header className="header-home ">
                    <Navbar bg="transparent" expand="lg" className={isMobile ? "d-flex position-absolute justify-content-between align-items-center mt-4 px-3" : "d-flex position-absolute justify-content-between align-items-center mt-4"} style={{ width: "100%", fontFamily: 'Exo', padding: isMobile ? "" : "0rem 6rem" }}>
                        {/* <Container> */}
                            <Navbar.Brand href="/" style={{ zIndex: 1 }}><img src={language === 'chn' ? ezIconCHN : ezIcon} alt='icon-ez' width = {isMobile ? 85.96 : 90} height = {isMobile ? 45.58 : 48} className="img-fluid" /></Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toMenu} style={{ color: "#FFFFFF", border: "unset", zIndex: 1 }} ><img src={menuIcon} alt="menu"/></Navbar.Toggle>
                            {push === true ?
                                "" :
                                <>
                                    <Navbar.Collapse id="basic-navbar-nav" style={{ width: "100%"}}>
                                        <Nav className='text-center' style={{ width: "100%"}}>
                                            <div className='text-center' style={{ width: "100%", zIndex: 1 }}>
                                                <OverlayTrigger
                                                    show={showPopover}
                                                    trigger={["hover", "focus"]}
                                                    key={1}
                                                    placement="bottom"
                                                    overlay={
                                                        <Popover style={{ maxWidth: 600, maxHeight: 550 }} onMouseEnter={() => setShowPopover(true)} onMouseLeave={() => setShowPopover(false)} id='menu'>
                                                            <table className="table-products-item2 mt-4 mb-3">
                                                                <thead>
                                                                    <th>{language === 'eng' ? 'Corporate & SME' : language === 'chn' ? '企业和出海企业' : 'Korporat & UMKM'}</th>
                                                                    <th>{language === 'eng' ? 'Personal (Consumer)' : language === 'chn' ? '个人（消费者）' : 'Personal (Konsumer)'}</th>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('remitance')}>
                                                                            <div><img src={remittanceIcon} alt='remitance' /> { language === 'eng' ? 'Remittance for Business' : language === 'chn' ? '商务汇款' : 'Remitansi untuk Bisnis' }</div>
                                                                        </td>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('remitancePersonal')}>
                                                                            <div><img src={remittancePersonalIcon} alt='remitancePersonal' /> { language === 'eng' ? 'Remittance for Personal' : language === 'chn' ? '个人汇款' : 'Remitansi untuk Personal' }</div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('offshore')}>
                                                                            <div><img src={offshoreBankIcon} alt='offshoreBankAccount' /> { language === 'eng' ? 'Offshore Bank Account' : language === 'chn' ? '离岸银行账户' : 'Offshore Bank Account' }</div>
                                                                        </td>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('uangElektronik')}>
                                                                            <div><img src={emoneyIcon} alt='uangElektronik' /> { language === 'eng' ? 'Electronic Money' : language === 'chn' ? '电子货币' : 'Uang Elektronik' }</div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('whiteLabel')}>
                                                                            <div><img src={whiteLabelWalletIcon} alt='whiteLabelWallet' /> { language === 'eng' ? 'White-Label Wallet' : language === 'chn' ? '白牌钱包' : 'White-Label Wallet' }</div>
                                                                        </td>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('wallet')}>
                                                                            <div><img src={whiteLabelWalletIcon} alt='wallet' /> { language === 'eng' ? 'Wallet' : language === 'chn' ? '钱包' : 'Wallet' }</div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('payment')}>
                                                                            <div><img src={paymentCollectionIcon} alt='paymentCollection' /> { language === 'eng' ? 'Payment Collection' : language === 'chn' ? '收款' : 'Payment Collection' }</div>
                                                                        </td>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('tarikTunaiTanpaKartu')}>
                                                                            <div><img src={cardlessCashIcon} alt='tarikTunaiTanpaKartu' /> { language === 'eng' ? 'Cardless Cash Withdrawal' : language === 'chn' ? '无卡取款' : 'Tarik Tunai tanpa Kartu' }</div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('transfer')}>
                                                                            <div><img src={transferIcon} alt='transferPembayaran' /> { language === 'eng' ? 'Transfer Payment' : language === 'chn' ? '转账付款' : 'Transfer Pembayaran' }</div>
                                                                        </td>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('voucherDigital')}>
                                                                            <div><img src={voucherIcon} alt='voucherDigital' /> { language === 'eng' ? 'Digital Voucher' : language === 'chn' ? '数字优惠券' : 'Voucher Digital' }</div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('qris')}>
                                                                            <div><img src={qrisIcon} alt='qris' /> QRIS</div>
                                                                        </td>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('pulsaTagihan')}>
                                                                            <div><img src={pulsaTagihanIcon} alt='pulsaTagihan' /> { language === 'eng' ? 'Bills & Credits' : language === 'chn' ? '信用和票据' : 'Pulsa & Tagihan' }</div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('loyalty')}>
                                                                            <div><img src={loyaltyIcon} alt='loyalty' /> { language === 'eng' ? 'Loyalty Program' : language === 'chn' ? '忠诚计划' : 'Loyalty' }</div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Popover>
                                                    }
                                                >
                                                    <span onMouseEnter={() => setShowPopover(true)} onMouseLeave={() => setShowPopover(false)} className='menu-navbar-home2 py-3 px-3 mx-3' style={{ borderRadius: 4, color: "#FFFFFF", cursor: "pointer" }}>{ language === 'eng' ? 'Product & Solution' : language === 'chn' ? '产品与解决方案' : 'Produk & Solusi' } <img src={dropDownWhite} alt='arrowDropDownWhiteProduct' className={(showPopover) ? 'arrow-down-white-product-open' : "arrow-down-white-product"} /></span>
                                                </OverlayTrigger>
                                                <span onClick={() => navigateToHome('merchant')} className='menu-navbar-home2 py-3 px-3 mx-3' style={{ borderRadius: 4, color: "#FFFFFF", cursor: "pointer" }}>{ language === 'eng' ? 'Merchant & Partner' : language === 'chn' ? '商户和合作伙伴' : 'Merchant & Partner' }</span>
                                                <span onClick={() => navbarHandle("tentang-kami")} className='menu-navbar-home2 py-3 px-3 mx-3' style={{ borderRadius: 4, color: "#FFFFFF", cursor: "pointer" }}>{ language === 'eng' ? 'About Us' : language === 'chn' ? '关于我们' : 'Tentang Kami' }</span>
                                                <span onClick={() => navbarHandle("pertanyaan")} className='menu-navbar-home2 py-3 px-3 mx-3' style={{ borderRadius: 4, color: "#FFFFFF", cursor: "pointer", fontWeight: 700 }}>FAQ</span>
                                            </div>
                                        </Nav>
                                    </Navbar.Collapse>
                                    <Navbar.Collapse id="basic-navbar-nav" style={{ width: "15%"}}>
                                        <div className="text-end" style={{ width: "100%"}} >
                                            <OverlayTrigger
                                                show={showPopoverLang}
                                                trigger={["hover", "focus"]}
                                                key={1}
                                                placement="bottom"
                                                overlay={
                                                    <Popover style={{ maxWidth: 138, maxHeight: 212 }} onMouseEnter={() => setShowPopoverLang(true)} onMouseLeave={() => setShowPopoverLang(false)} id='menu'>
                                                        <Popover.Body>
                                                            <div className='mt-2 mb-4' onClick={() => inaLang()}>
                                                                <span className='menu-navbar-lang2' style={{ borderRadius: 4, color: "#383838", cursor: "pointer" }}><img src={flagIdRoundIcon} alt='idFlag' style={{ marginRight: 15 }} /> ID</span>
                                                            </div>
                                                            <div className='mt-4 mb-4' onClick={() => engLang()}>
                                                                <span className='menu-navbar-lang2' style={{ borderRadius: 4, color: "#383838", cursor: "pointer" }}><img src={flagEnRoundIcon} alt='idFlag' style={{ marginRight: 10 }} /> EN</span>
                                                            </div>
                                                            {/* {
                                                                (isRemitancePersonal && isRemitanceBisnis) &&
                                                                <div className='mt-4 mb-2' onClick={() => chnLang()}>
                                                                    <span className='menu-navbar-lang2' style={{ borderRadius: 4, color: "#383838", cursor: "pointer" }}><img src={flagRrtRoundIcon} alt='idFlag' style={{ marginRight: 10 }} /> CN</span>
                                                                </div>
                                                            } */}
                                                            <div className='mt-4 mb-2' onClick={() => chnLang()}>
                                                                <span className='menu-navbar-lang2' style={{ borderRadius: 4, color: "#383838", cursor: "pointer" }}><img src={flagRrtRoundIcon} alt='idFlag' style={{ marginRight: 10 }} /> CN</span>
                                                            </div>
                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                            >
                                                <span onMouseEnter={() => setShowPopoverLang(true)} onMouseLeave={() => setShowPopoverLang(false)} className='menu-navbar-home2 py-3 px-3' style={{ borderRadius: 4, color: "#FFFFFF", cursor: "pointer" }}>{language === 'eng' ? <><img src={flagEnRoundIcon} alt='enFlag' /> &nbsp; EN</> : language === 'chn' ? <><img src={flagRrtRoundIcon} alt='cnFlag' /> &nbsp; CN</> : <><img src={flagIdRoundIcon} alt='idFlag' /> &nbsp; ID</>}<img src={dropDownWhite} alt='arrowDropDownWhiteLanguage' className={(showPopoverLang) ? 'arrow-down-white-language-open' : "arrow-down-white-language"} style={{ marginLeft: 12 }} /></span>
                                            </OverlayTrigger>
                                        </div>
                                    </Navbar.Collapse>
                                </>
                            }
                        {/* </Container> */}
                    </Navbar>
                    <Row>
                        <Col xl={12} style={{ paddingBottom: isMobile ? 30 : "", zIndex: 999, paddingRight: "unset" }} className="d-flex justify-content-end align-items-center bottom-0 position-fixed">
                            <img onClick={() => waEzeelink()} src={chatMessageIcon} alt='chatMessageIcon' style={{ cursor: 'pointer'}} width = {isMobile ? 48 : 85} height = {isMobile ? 48 : 85} className="img-fluid" />
                        </Col>
                    </Row>
                    <div  className={isMobile ? "position-absolute mt-5 pt-5" : "position-absolute mt-5 pt-5"} style={{ padding: isMobile ? "" : "0rem 3rem" }}>
                        <div className={isMobile ? "mt-5 pt-5 px-3" : "mt-5 pt-5 px-5"}>
                            <div style={{ color: '#888888', fontWeight: 700, fontSize: 16, fontFamily: 'Exo' }} className={isMobile ? (isRemitancePersonal ? 'd-flex justify-content-start align-items-center mb-4 mt-5 pt-5' : 'd-flex justify-content-start align-items-center mb-4') : 'd-flex justify-content-start align-items-center mb-4'}>
                                <div className='pe-3' style={{ cursor: 'pointer', color: isRemitancePersonal ? "#D18624" : "#FFFFFF" }} onClick={() => tabRemitansi('personal')} >{ language === 'eng' ? 'Personal' : language === 'chn' ? '个人' : 'Remitansi Personal'}</div>
                                <div className="" style={{ border : '0.5px solid #C4C4C4', height: 24}} />
                                <div className='ps-3' id="remitanceBisnis" style={{ cursor: 'pointer', color: isRemitanceBisnis ? "#D18624" : "#FFFFFF" }} onClick={() => tabRemitansi('bisnis')}>{ language === 'eng' ? 'Business' : language === 'chn' ? '商业' : 'Remitansi Bisnis'}</div>
                            </div>
                            {
                                isRemitanceBisnis  ?
                                    isMobile ?
                                        <>
                                            <span style={{ color: "#FFFFFF", fontSize: isMobile ? 36 : 48, fontWeight: 700, fontFamily: 'Exo' }}>{ language === 'eng' ? 'Easier Cross-Border Transaction To Support Your Business Growth and Expansion' : language === 'chn' ? '更轻松的跨境交易支持您的业务增长' : 'Transaksi Luar Negeri Lebih Mudah untuk Mendukung Perkembangan Bisnis Anda' }</span><br />
                                        </> :
                                        <>
                                            <span style={{ color: "#FFFFFF", fontSize: isMobile ? 36 : 48, fontWeight: 700, fontFamily: 'Exo' }}>{ language === 'eng' ? 'Easier Cross-Border' : language === 'chn' ? '更轻松的跨境交易' : 'Transaksi Luar Negeri Lebih' }</span><br />
                                            <span style={{ color: "#FFFFFF", fontSize: isMobile ? 36 : 48, fontWeight: 700, fontFamily: 'Exo' }}>{ language === 'eng' ? 'Transaction To Support' : language === 'chn' ? '支持您的业务增长' : 'Mudah untuk Mendukung' }</span><br/>
                                            <span style={{ color: "#FFFFFF", fontSize: isMobile ? 36 : 48, fontWeight: 700, fontFamily: 'Exo' }}>{ language === 'eng' ? 'Your Business Growth and' : language === 'chn' ? '' : 'Perkembangan Bisnis Anda' }</span>{language !== 'chn' ? <br/> : ""}
                                            <span style={{ color: "#FFFFFF", fontSize: isMobile ? 36 : 48, fontWeight: 700, fontFamily: 'Exo' }}>{ language === 'eng' ? 'Expansion' : language === 'chn' ? '' : '' }</span>
                                        </>
                                    :
                                isRemitancePersonal ?
                                    isMobile ?
                                        <>
                                            <span style={{ color: "#FFFFFF", fontSize: isMobile ? 36 : 48, fontWeight: 700, fontFamily: 'Exo' }}>{ language === 'eng' ? 'Send Money Abroad For Various Transactions' : language === 'chn' ? '向国外汇款以进行各种交易' : 'Kirim Uang ke Luar Negeri untuk Berbagai Macam Transaksi' }</span><br/>
                                        </> :
                                        <>
                                            <span style={{ color: "#FFFFFF", fontSize: isMobile ? 36 : 48, fontWeight: 700, fontFamily: 'Exo' }}>{ language === 'eng' ? 'Send Money Abroad For' : language === 'chn' ? '向国外汇款以进行各种交易' : 'Kirim Uang ke Luar Negeri' }</span>{language !== 'chn' ? <br /> : ''}
                                            <span style={{ color: "#FFFFFF", fontSize: isMobile ? 36 : 48, fontWeight: 700, fontFamily: 'Exo' }}>{ language === 'eng' ? 'Various Transactions' : language === 'chn' ? null : 'untuk Berbagai Macam' }</span><br />
                                            {
                                                language === 'eng' ? null :
                                                <span style={{ color: "#FFFFFF", fontSize: isMobile ? 36 : 48, fontWeight: 700, fontFamily: 'Exo' }}>{ language === 'chn' ? null : 'Transaksi' }</span>}
                                        </>
                                : null
                            }
                            {
                                isRemitanceBisnis  ?
                                    <div className='mt-3' style={{ color: "#FFFFFF", fontSize: 16, fontFamily: "Open Sans" }}>{ language === 'eng' ? 'Join now to experience the benefits of our products!' : language === 'chn' ? '立即加入，体验我们产品的好处' : 'Gabung sekarang untuk dapat merasakan manfaat dari produk kami.'}</div> :
                                isRemitancePersonal ?
                                    <div className='mt-3' style={{ color: "#FFFFFF", fontSize: 16, fontFamily: "Open Sans" }}>{ language === 'eng' ? <>Very <b>Safe</b> and <b>Easy</b>, With No Hidden Fees <br/> <b>Try Now!</b></> : language === 'chn' ? <><b>更安全、</b><b>更</b>轻<b>松</b> <b>无</b>隐<b>藏</b>费<b>用</b> <br/> 现<b>在</b>试试！</> : <>Lebih <b>Aman</b> dan <b>Mudah</b>, Tanpa Biaya Tersembunyi. {isMobile ? "" : <br/>} <b>Coba Sekarang!</b></>}</div>
                                    : null
                            }
                            {
                                isRemitanceBisnis &&
                                <div className={isMobile ? 'py-4' : 'd-flex justify-content-start align-items-center py-4'}>
                                    <button onClick={toForm} className={isMobile ? 'btn-ez-join mb-2' : 'btn-ez-join me-2'} style={{ fontSize: isMobile ? 14 : 16, width: isMobile ? '100%' : '30%' }}>{ language === 'eng' ? 'Join Now' : language === 'chn' ? '立即加入' : 'Gabung Sekarang'}</button>
                                    <button onClick={waEzeelink} className={isMobile ? 'btn-reset mt-2' : 'btn-reset ms-2'} style={{ fontSize: isMobile ? 14 : 16, width: isMobile ? '100%' : '30%' }}>{ language === 'eng' ? 'Contact Us' : language === 'chn' ? '联系我们' : 'Hubungi Kami'}</button>
                                </div>
                            }
                        </div>
                    </div>
                    <img className='header-navbar-home2 img-fluid' src={(isRemitancePersonal) ? (isMobile ? headerRemitancePersonalMobile : headerRemitancePersonal) : (isMobile ? headerRemitanceBusinessMobile : headerRemitanceBusiness)} alt={(isRemitancePersonal) ? "header_remitance_personal" : (isRemitanceBisnis ) ? "header_remitance_business" : ""} />
                </header>
            </div>
            <div className={isMobile ? 'text-center' : 'px-5 text-center'} style={{ width: "100%", background: "#F9EDDC", padding: "48px 0px" }}>
                <div style={{ fontWeight: 700, fontSize: 36, lineHeight: '50.4px', fontFamily: 'Exo' }}>
                    {
                        language === 'eng' ?
                            (isRemitancePersonal ? 'Why Ezeelink Remittance' : (isMobile ? 'Ezeelink Business Remittance Specially Designed Just For You.' : <>Ezeelink Business Remittance Specially <br/> Designed Just For You.</>)) :
                        language === 'chn' ? (isRemitancePersonal ? '为什么选择 Ezeelink 汇款' : '为什么选择我们的商业汇款') :
                            (isRemitancePersonal ? 'Kenapa Remitansi Ezeelink' : 'Remitansi Bisnis Ezeelink Dirancang Khusus Bagi Anda')
                    }
                </div>
                <Row className={isMobile ? "d-flex justify-content-center align-items-center mt-5 gx-0 px-3" : "d-flex justify-content-center align-items-center mt-5 gx-0"}>
                    <Col xl={2} xs={12} className='px-3 mx-2 my-1' style={{ height: 286,  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)", borderRadius: 8, background: "#FFFFFF", gap: 16 }}>
                        <div><img src={isRemitancePersonal ? instant : prosesCepat} alt="instant" /></div>
                        <div style={{ fontWeight: 700, fontSize: 24, lineHeight: "140%", fontFamily: 'Exo', color: '#383838' }} className="my-2">
                            {
                                language === 'eng' ?
                                    (isRemitancePersonal ? 'Instant' : 'Fast Process') :
                                language === 'chn' ?
                                    (isRemitancePersonal ? '即时' : '快速流程') :
                                    (isRemitancePersonal ? 'Instan' : 'Proses Cepat')
                            }
                        </div>
                        <div style={{ fontWeight: 400, fontSize: 16, lineHeight: "140%", fontFamily: 'Open Sans', color: '#888888' }}>
                            {
                                language === 'eng' ?
                                    (isRemitancePersonal ? 'Send and receive money directly from Mobile Apps at your fingertips!' : 'Efficient and effective plus very fast process for international transactions') :
                                language === 'chn' ?
                                    (isRemitancePersonal ? '直接从移动应用程序操作发送和收款' : '处理国际交易的速度如此之快') :
                                    (isRemitancePersonal ? 'Kirim dan terima uang bisa langsung dari aplikasi dalam genggaman Anda!' : 'Proses transaksi internasional efektif, efisien dan lebih cepat.')
                            }
                        </div>
                    </Col>
                    <Col xl={2} xs={12} className='px-3 mx-2 my-1' style={{ height: 286,  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)", borderRadius: 8, background: "#FFFFFF", gap: 16 }}>
                        <div><img src={isRemitancePersonal ? terpercaya : lebihHemat} alt="terpercaya" /></div>
                        <div style={{ fontWeight: 700, fontSize: 24, lineHeight: "140%", fontFamily: 'Exo', color: '#383838' }} className="my-2">
                            {
                                language === 'eng' ?
                                    (isRemitancePersonal ? 'Trusted' : 'More Savings') :
                                language === 'chn' ?
                                    (isRemitancePersonal ? '值得信赖' : '节省更多') :
                                    (isRemitancePersonal ? 'Terpercaya' : 'Lebih Hemat')
                            }
                        </div>
                        <div style={{ fontWeight: 400, fontSize: 16, lineHeight: "140%", fontFamily: 'Open Sans', color: '#888888' }}>
                            {
                                language === 'eng' ?
                                    (isRemitancePersonal ? 'Bank Indonesia Licensed transactions are safe and protected' : 'Competitive exchange rates compared to any other Financial institutions.') :
                                language === 'chn' ?
                                    (isRemitancePersonal ? '印度尼西亚银行许可交易是安全和受保护的' : '具有竞争力的汇率以节省更多') :
                                    (isRemitancePersonal ? 'Berlisensi Bank Indonesia transaksi aman terlindungi.' : 'Nilai kurs bersaing dibandingkan layanan finansial lainnya.')
                            }
                        </div>
                    </Col>
                    <Col xl={2} xs={12} className='px-3 mx-2 my-1' style={{ height: 286,  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)", borderRadius: 8, background: "#FFFFFF", gap: 16 }}>
                        <div><img src={transparan} alt="transparan" /></div>
                        <div style={{ fontWeight: 700, fontSize: 24, lineHeight: "140%", fontFamily: 'Exo', color: '#383838' }} className="my-2">{language === 'eng' ? 'Transparency' : language === 'chn' ? '透明' : 'Transparansi'}</div>
                        <div style={{ fontWeight: 400, fontSize: 16, lineHeight: "140%", fontFamily: 'Open Sans', color: '#888888' }}>{language === 'eng' ? (isRemitancePersonal ? 'No hidden fees, crystal clear and transparency!' : 'Crystal clear and transparency - No Hidden Fees!') : language === 'chn' ? '无隐藏费用，清晰透明' : (isRemitancePersonal ? 'Tanpa biaya tersembunyi, jelas dan transparansi.' : 'Jelas dan transparansi – Tanpa biaya tersembunyi!')}</div>
                    </Col>
                    <Col xl={2} xs={12} className='px-2 mx-2 my-1' style={{ height: 286,  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)", borderRadius: 8, background: "#FFFFFF", gap: 16 }}>
                        <div><img src={isRemitancePersonal ? lebihHemat : responsif} alt="lebihHemat" /></div>
                        <div style={{ fontWeight: 700, fontSize: 24, lineHeight: "140%", fontFamily: 'Exo', color: '#383838' }} className="my-2">
                            {
                                language === 'eng' ?
                                    (isRemitancePersonal ? 'More Savings' : 'Responsiveness') :
                                language === 'chn' ?
                                    (isRemitancePersonal ? '更多节省' : '响应式') :
                                    (isRemitancePersonal ? 'Lebih Hemat' : 'Responsif')
                            }
                        </div>
                        <div style={{ fontWeight: 400, fontSize: 16, lineHeight: "140%", fontFamily: 'Open Sans', color: '#888888' }}>
                            {
                                language === 'eng' ?
                                    (isRemitancePersonal ? 'Very competitive exchange rates, saves up even more to send your money!' : 'Full support from us at Ezeelink in regards to remittance services.') :
                                language === 'chn' ?
                                    (isRemitancePersonal ? '有竞争力的汇率，汇款更省钱' : '我们全力支持正确的服务') :
                                    (isRemitancePersonal ? 'Nilai kurs sangat bersaing, kirim uang lebih hemat lagi' : 'Dukungan penuh dari kami di Ezeelink terkait layanan remitansi.')
                            }
                        </div>
                    </Col>
                    <Col xl={2} xs={12} className='px-3 mx-2 my-1' style={{ height: 286,  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)", borderRadius: 8, background: "#FFFFFF", gap: 16 }}>
                        <div><img src={isRemitancePersonal ? fleksibel : terpercaya} alt="fleksibel" /></div>
                        <div style={{ fontWeight: 700, fontSize: 24, lineHeight: "140%", fontFamily: 'Exo', color: '#383838' }} className="my-2">
                            {
                                language === 'eng' ?
                                    (isRemitancePersonal ? 'Flexible' : 'Trusted') :
                                language === 'chn' ?
                                    (isRemitancePersonal ? '灵活' : '值得信赖') :
                                    (isRemitancePersonal ? 'Fleksibel' : 'Terpercaya')
                            }
                        </div>
                        <div style={{ fontWeight: 400, fontSize: 16, lineHeight: "140%", fontFamily: 'Open Sans', color: '#888888' }}>
                            {
                                language === 'eng' ?
                                    (isRemitancePersonal ? 'Send or receive money for various purposes' : 'Bank Indonesia Licensed transactions are very safe and well protected') :
                                language === 'chn' ?
                                    (isRemitancePersonal ? '为各种目的汇款或收款' : '印度尼西亚银行许可交易是安全和受保护的') :
                                    (isRemitancePersonal ? 'Kirim atau terima uang untuk berbagai keperluan.' : 'Berlisensi Bank Indonesia transaksi aman terlindungi.')
                            }
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={isMobile ? 'mt-5' : 'mt-5 px-5'} id='faq'>
                <div className='text-center' style={{ fontWeight: 700, fontSize: 36, lineHeight: "140%", fontFamily: 'Exo', color: '#383838' }}>{language === 'eng' ? 'Frequently Asked Questions' : language === 'chn' ? '常见问题' : 'Pertanyaan Umum Remitansi Ezeelink'}</div>
                <div className={isMobile ? ' mb-5' : 'px-5 mx-5 mb-5'}>
                    <Accordion defaultActiveKey="0" flush className='mt-3' style={{ borderBottom: "inset" }}>
                        <Accordion.Item id='remitance' eventKey='0'>
                            <Accordion.Header>
                                <div style={{ fontSize: isMobile ? 16 : 20, fontWeight: 700, color: "#383838" }}>
                                    {
                                        language === 'eng' ?
                                            <>What is Ezeelink Remittance for {isRemitancePersonal ? 'Personal' : 'Business'}?</> :
                                        language === 'chn' ?
                                            <>什么是 Ezeelink  {isRemitancePersonal ? '个人汇款?' : '商业汇款?'}</> :
                                            <>Apa itu Remitansi {isRemitancePersonal ? 'Personal' : 'Bisnis'} Ezeelink?</>
                                    }
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400, color: "#888888" }}>
                                    {
                                        language === 'eng' ?
                                            <>{isRemitancePersonal ? 'Ezeelink Personal Remittance is a cross-border money transfer service for individual or personal account purposes.' : 'Ezeelink Remittance for Business is a cross-border money transfer service for the purpose of company or business entity accounts through Ezeelink.'}</> :
                                        language === 'chn' ?
                                            <>{isRemitancePersonal ? 'Ezeelink 个人汇款是通过 Ezeelink应用程序平台 为个人所提供的跨境汇款服务.' : 'Ezeelink 商业汇款是通过 Ezeelink 为公司或企业实体账户提供的跨境汇款服务。'}</> :
                                            <>Remitansi {isRemitancePersonal ? 'Personal' : 'Bisnis'} Ezeelink adalah layanan pengiriman uang lintas negara dengan tujuan rekening {isRemitancePersonal ? 'perseorangan' : 'perusahaan'} atau {isRemitancePersonal ? 'personal' : 'badan usaha'} melalui Ezeelink.</>
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item id='offshore' eventKey='1'>
                            <Accordion.Header>
                                <div style={{ fontSize: isMobile ? 16 : 20, fontWeight: 700, color: "#383838" }}>
                                    {
                                        language === 'eng' ?
                                            <>How to {isRemitancePersonal ? 'use Personal Remittance' : 'do Remittance for Business'} in Ezeelink?</> :
                                        language === 'chn' ?
                                            <>{isRemitancePersonal ? '如何在Ezeelink使用个人汇款?' : '如何在Ezeelink进行业务汇款?'} </> :
                                            <>Bagaimana cara melakukan Remitansi {isRemitancePersonal ? 'Personal' : 'Bisnis'} di Ezeelink?</>
                                    }
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400, color: "#888888" }}>
                                    {
                                        language === 'eng' ?
                                            (isRemitancePersonal ? 'Download Ezeelink Apps and register. After verified through email or OTP, you can directly select the Remittance Menu to start sending and receiving money to/from abroad through the Apps.' : 'Simply by contacting our Customer Support team. They will assist on your registration via WhatsApp or email, and then you can directly process the business remittance through our Customer Support. We will immediately process your transaction(s) directly to your designated country.') :
                                        language === 'chn' ?
                                            (isRemitancePersonal ? '下载并在Ezeelink应用程序中注册，验证后您可以直接选择汇款菜单通过Ezeelink应用程序开始从国外汇款和收款。' : '只需联系我们的客户支持团队，他们将通过 WhatsApp 或电子邮件协助您进行注册。然后您可以直接通过客户支持处理业务汇款，我们将直接将您的交易处理到目的地国家。') :
                                            (isRemitancePersonal ? 'Download dan daftarkan diri Anda di aplikasi Ezeelink, setelah terverifikasi melalui email atau OTP Anda bisa langsung memilih menu Remitansi untuk pengiriman dan penerimaan uang dari luar negeri melalui aplikasi Ezeelink.' : 'Cukup dengan menghubungi tim Customer Support kami yang akan membantu Anda untuk pendaftaran melalui WhatsApp atau email. Selanjutnya Anda bisa langsung melakukan proses remitansi melalui customer support dan transaksi Anda akan langsung kami proses ke negara tujuan.')
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item id='whiteLabel' eventKey='2'>
                            <Accordion.Header>
                                <div style={{ fontSize: isMobile ? 16 : 20, fontWeight: 700, color: "#383838" }}>
                                    {
                                        language === 'eng' ?
                                            'Is Ezeelink Remittance licensed?' :
                                        language === 'chn' ?
                                            'Ezeelink 汇款是否获得许可?' :
                                            'Apakah Remitansi Ezeelink memiliki izin?'
                                    }
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400, color: "#888888" }}>
                                    {
                                        language === 'eng' ?
                                            'Ezeelink is a licensed Payment Service Provider (Penyedia Jasa Pembayaran) – Kategori Izin 1 NK 23/572/DKSP/Srt/B from Bank Indonesia which includes a remittance service license according to PBI regulation No. 22/23/PBI/2020. Ezeelink is also recognized for its credibility and is a member of several financial associations and payment services.' :
                                        language === 'chn' ?
                                            'Ezeelink 拥有印度尼西亚银行的支付服务提供商一级许可证 (Penyedia Jasa Pembayaran) – Kategori Izin 1 NK 23/572/DKSP/Srt/B，其中包括根据 PBI 条例第 22/23/PBI/2020 号的汇款服务许可证. Ezeelink 也因其信誉而受到认可，并且是多个金融协会和支付服务的核心成员。' :
                                            'Ezeelink memiliki izin Penyedia Jasa Pembayaran (PJP) – Kategori Izin 1 NK 23/572/DKSP/Srt/B dari Bank Indonesia yang termasuk di dalamnya perizinan layanan remitansi sesuai regulasi PBI No. 22/23/PBI/2020. Ezeelink juga diakui kredibilitasnya dan tergabung dalam beberapa asosiasi finansial dan jasa pembayaran.'
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item id='payment' eventKey='3'>
                            <Accordion.Header>
                                <div style={{ fontSize: isMobile ? 16 : 20, fontWeight: 700, color: "#383838" }}>
                                    {
                                        language === 'eng' ?
                                            <>Can I do remittance transaction to a {isRemitancePersonal ? 'business/company' : 'personal/individual'} account?</> :
                                        language === 'chn' ?
                                            <>{isRemitancePersonal ? '我可以向企业/公司账户进行汇款交易吗?' : '我可以向个人/个人账户进行汇款交易吗?'}</> :
                                            <>Bisakah saya melakukan transaksi remitansi ke rekening {isRemitancePersonal ? 'bisnis/perusahaan' : 'personal/perorangan'}?</>
                                    }
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400, color: "#888888" }}>
                                    {
                                        language === 'eng' ?
                                            (isRemitancePersonal ? <>Personal Remittances are specifically designed for personal or individual account purposes. <br/> For Company/ Business Entities remittance services, please refer to the Remittance for Business Services in Ezeelink.</> : <>Business Remittance are designed specifically for company or business entity accounts. <br/> For Personal Remittance services, please download Ezeelink Mobile Apps and you can access easily through Personal Remittances at your fingertips!</>) :
                                        language === 'chn' ?
                                            (isRemitancePersonal ? '个人汇款是专门针对个人账户的，对于公司/企业实体账户的汇款服务，您可以使用Ezeelink的商业汇款服务。' : '企业汇款专门针对公司或企业实体账户。至于个人账户的汇款服务，您可以通过 Ezeelink 移动应用程序中的个人汇款服务轻松访问。') :
                                            <>Remitansi {isRemitancePersonal ? 'Personal' : 'Bisnis'} dikhususkan untuk tujuan rekening {isRemitancePersonal ? 'pribadi atau perorangan, untuk layanan remitansi ke rekening perusahaan/badan usaha bisa melalui layanan Remitansi Bisnis di Ezeelink.' : <>perusahaan atau badan usaha. <br/> Untuk layanan remitansi personal/perorangan silakan download aplikasi Ezeelink dan Anda bisa dengan mudah mengakses menu Remitansi Personal melalui genggaman Anda.</>}  </>
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item id='transfer' eventKey='4'>
                            <Accordion.Header>
                                <div style={{ fontSize: isMobile ? 16 : 20, fontWeight: 700, color: "#383838" }}>
                                    {
                                        language === 'eng' ?
                                            <>How can I check my {isRemitancePersonal ? 'Remittance transaction' : 'Business Remittance'} status on Ezeelink?</> :
                                        language === 'chn' ?
                                            <>{isRemitancePersonal ? '如何在Ezeelink查看我的汇款交易状态?' : '我如何在 Ezeelink 上查看我的商业汇款状态?'} </> :
                                            <>Bagaimana cara mengecek status {isRemitancePersonal ? 'transaksi Remitansi saya' : 'Remitansi Bisnis'} di Ezeelink?</>
                                    }
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400, color: "#888888" }}>
                                    {
                                        language === 'eng' ?
                                            (isRemitancePersonal ? 'Your remittance status updates instantly in your Ezeelink Apps.' : 'You can check it through a web portal that can be accessed after your remittance is processed') :
                                        language === 'chn' ?
                                            (isRemitancePersonal ? 'Ezeelink APP会实时更新汇款状态，您也可以联系我们的客服查询您的汇款状态。' : '您可以联系我们的客服查询正在处理的汇款状态。在您的汇款交易流程完成后，我们还将向您发送交易证明。') :
                                            (isRemitancePersonal ? 'Status remitansi akan di update secara langsung di aplikasi Ezeelink.' : 'Anda bisa mengeceknya melalui portal web yang bisa diakses setelah remitansi Anda diproses.')
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item id='qris' eventKey='5'>
                            <Accordion.Header>
                                <div style={{ fontSize: isMobile ? 16 : 20, fontWeight: 700, color: "#383838" }}>
                                    {
                                        language === 'eng' ?
                                            'What is the minimum and maximum amount of Ezeelink Remittance?' :
                                        language === 'chn' ?
                                            <>{isRemitancePersonal ? 'Ezeelink 个人汇款 的最小和最大金额是多少?' : 'Ezeelink 汇款的最小和最大金额是多少?'}</> :
                                            'Berapa nilai minimal dan maksimal Remitansi Ezeelink?'
                                    }
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400, color: "#888888" }}>
                                    {
                                        language === 'eng' ?
                                            <>{isRemitancePersonal ? 'The minimum amount of remittance is the smallest nominal currency of sender country. There is no maximum* amount for remittances but for transactions more than USD100,000*, there will be underlying documents required, according to the applicable regulations.' : 'The minimum remittance is the smallest nominal currency to be sent. There is no maximum* amount for remittances, but for transactions more than USD100,000*, there are underlying documents that will be required to fulfil prior, and according to applicable regulations.'}</> :
                                        language === 'chn' ?
                                            '最低汇款是为该国最小货币单位(如1印尼盾)，汇款没有最高限额，特别是对于超过 100,000 美元的交易，将根据适用法规要求提供基础文件。' :
                                            <>{isRemitancePersonal ? 'Minimal remitansi sebesar nominal terkecil mata uang negara pengirim. Tidak ada jumlah maksimal untuk remitansi tapi khusus transaksi lebih besar dari USD100.000 akan dibutuhkan dokumen underlying sesuai ketentuan yang berlaku.' : 'Minimal remitansi sebesar nominal terkecil mata uang yang akan dikirimkan dan tidak ada maksimal untuk remitansi, khusus transaksi lebih besar dari USD100.000 akan dibutuhkan dokumen underlying sesuai ketentuan yang berlaku.'}</>
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item id='loyalty' eventKey='6'>
                            <Accordion.Header>
                                <div style={{ fontSize: isMobile ? 16 : 20, fontWeight: 700, color: "#383838" }}>
                                    {
                                        language === 'eng' ?
                                            <>{isRemitancePersonal ? 'Why my designated country to transfer is not available?' : 'Why my transfer destination country is not available?'}</> :
                                        language === 'chn' ?
                                            '为什么我的转帐目的地国家不可用?' :
                                            'Kenapa negara tujuan transfer saya tidak ada?'
                                    }
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400, color: "#888888" }}>
                                    {
                                        language === 'eng' ?
                                            (isRemitancePersonal ? 'The current available countries for Ezeelink Remittance are China (CNY), Hong Kong (HKD), Malaysia (MYR), and Singapore (SGD). You can request us to provide remittance service to your desired country.' : 'The current countries available for remittance are USA (USD), China (CNY), Hong Kong (HKD), Malaysia (MYR), and Singapore (SGD). You can, of course, request us to provide a business remittance service to your desired destination country.') :
                                            language === 'chn' ?
                                            (isRemitancePersonal ? '目前可以成为 Ezeelink 汇款目的地的国家是新加坡 (SGD)、马来西亚 (MYR)、中国 (CNY) 和香港 (HKD)。您可以要求我们为您希望的目的地国家提供商业汇款服务.' : '目前可以成为 Ezeelink 汇款目的地的国家是新加坡 (SGD)、马来西亚 (MYR)、中国 (CNY) 和香港 (HKD)。您可以要求我们为您希望的目的地国家提供商业汇款服务.') :
                                            (isRemitancePersonal ? 'Saat ini negara yang bisa menjadi tujuan Remitansi Ezeelink adalah China (CNY), Hongkong (HKD), Malaysia (MYR) dan Singapura (SGD). Anda bisa meminta ke kami untuk menyiapkan layanan remitansi ke negara tujuan yang Anda inginkan.' : 'Saat ini negara yang bisa menjadi tujuan Remitansi Bisnis Ezeelink adalah Amerika Serikat (USD), Singapura (SGD), Malaysia (MYR), China (CNY), dan Hongkong (HKD). Tentu, Anda bisa meminta ke kami untuk menyiapkan layanan remitansi bisnis ke negara tujuan yang Anda inginkan.')
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item id='loyalty' eventKey='7'>
                            <Accordion.Header>
                                <div style={{ fontSize: isMobile ? 16 : 20, fontWeight: 700, color: "#383838" }}>
                                    {
                                        language === 'eng' ?
                                            'How long does it take for the funds to reach the destination account?' :
                                        language === 'chn' ?
                                            '资金到目的账户需要多长时间?' :
                                            'Berapa lama waktu yang dibutuhkan untuk dana bisa sampai ke rekening tujuan?'
                                    }
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400, color: "#888888" }}>
                                    {
                                        language === 'eng' ?
                                            'The process requires a maximum of 30 minutes after you transfer funds to Ezeelink. Unless there is an issue(s) that requires handling, we may need more time to process.' :
                                        language === 'chn' ?
                                            '在您将资金转入 Ezeelink 后，直到资金到达目的地账户一般情况下处理时间最长为 30 分钟.' :
                                            <>{isRemitancePersonal ? 'Proses hingga dana sampai ke rekening tujuan maksimal 30 menit setelah Anda mentransfer dana ke Ezeelink. Kecuali ada kendala yang memerlukan waktu lebih untuk penanganannya.' : 'Proses hingga dana sampai ke rekening tujuan maksimal 30 menit (kecuali ada kendala yang memerlukan penanganan) setelah Anda mentransfer dana ke Ezeelink.'}</>
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item id='loyalty' eventKey='8'>
                            <Accordion.Header>
                                <div style={{ fontSize: isMobile ? 16 : 20, fontWeight: 700, color: "#383838" }}>
                                    {
                                        language === 'eng' ?
                                            (isRemitancePersonal ? 'Can Ezeelink Remittances for Personal send money to overseas?' : 'Can I receive transfers from abroad to my Ezeelink account?') :
                                        language === 'chn' ?
                                            (isRemitancePersonal ? 'Ezeelink 个人汇款可以向海外汇款吗?' : '我可以从国外收到转账到我的账户吗?') :
                                            (isRemitancePersonal ? 'Apakah Remitansi Ezeelink bisa mengirim uang ke luar negeri?' : 'Apakah bisa menerima transfer dari luar negeri ke rekening saya?')
                                    }
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400, color: "#888888" }}>
                                    {
                                        language === 'eng' ?
                                            (isRemitancePersonal ? 'Ezeelink Personal Remittance currently are inward remittance. In the nearest future, we will be equipped with the outward remittance service you need for your Personal Remittance.' : 'Ezeelink Remittance for Business currently are outward remittance, in the near future we will prepare the inward remittance service you need for Business Remittance.') :
                                        language === 'chn' ?
                                            (isRemitancePersonal ? 'Ezeelink 个人汇款目前为汇入汇款，在不久的将来我们将为您准备个人汇款所需的汇出服务' : 'Ezeelink 商业汇款目前为印尼汇出汇款服务，在不久的将来我们将为您准备汇款所需的汇入服务.') :
                                            (isRemitancePersonal ? 'Remitansi Personal Ezeelink saat ini adalah inward remittance (remitansi kedalam), dalam waktu dekat kami akan menyiapkan layanan outward remittance (remitansi keluar) yang Anda butuhkan untuk Remitansi Personal.' : 'Remitansi bisnis Ezeelink saat ini adalah outward remittance (remitansi keluar), dalam waktu dekat kami akan menyiapkan layanan inward remittance (remitansi kedalam) yang Anda butuhkan.')
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item id='loyalty' eventKey='9'>
                            <Accordion.Header>
                                <div style={{ fontSize: isMobile ? 16 : 20, fontWeight: 700, color: "#383838" }}>
                                    {
                                        language === 'eng' ?
                                            <>{isRemitancePersonal ? 'Is the exchange rate listed on the Ezeelink website/platform fixed?' : 'Is the exchange rate listed on the Ezeelink website fixed?'}</> :
                                        language === 'chn' ?
                                            <>{isRemitancePersonal ? 'Ezeelink平台上的汇率是固定的吗?' : 'Ezeelink网站平台上的汇率是固定的吗?'}</> :
                                            'Apakah kurs yang tercantum di website Ezeelink nilainya tetap?'
                                    }
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400, color: "#888888" }}>
                                    {
                                        language === 'eng' ?
                                            'The exchange rates for remittances can change at any time, therefore do please transfer fund after receiving transfer instruction for remittance process via Ezeelink Apps, no later than in 30 minutes.' :
                                        language === 'chn' ?
                                            '汇款的汇率是波动的，随时可能变动，因此在收到Ezeelink汇款流程的转账指令后，请立即在不迟于30分钟内进行转账.' :
                                            <>{isRemitancePersonal ? 'Kurs untuk remitansi bersifat fluktuatif dan dapat berubah sewaktu-waktu, karena itu Anda diminta untuk segera mentransfer dana untuk proses remitansi melalui aplikasi Ezeelink tidak lebih dari 30 menit setelah menerima instruksi transfer.' : 'Kurs untuk remitansi bersifat fluktuatif dan dapat berubah sewaktu-waktu, karena itu Anda diminta untuk segera mentransfer dana untuk proses remitansi melalui Ezeelink tidak lebih dari 30 menit setelah menerima instruksi transfer.'}</>
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
            <Footer3 />
        </div>
    )
}

export default Remitansi