import logo from './logo.svg';
import React, {useEffect, useState}from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Link,
  useHistory
} from "react-router-dom";
// import HomePage from './pages/HomePage';
import ProductServicePage from './pages/ProductsAndServices/ProductServicePage';
import 'bootstrap/dist/css/bootstrap.min.css';
// import AboutUs from './pages/AboutUs';
import LamanPayment from './pages/WebPayment/LamanPayment';
import MetodePembayaran from './pages/WebPayment/MetodePembayaran';
import KonfirmasiPembayaran from './pages/WebPayment/KonfirmasiPembayaran';
import StatusKonfirmasi from './pages/WebPayment/StatusKonfirmasi';
import GagalKonfirmasi from './pages/WebPayment/GagalKonfirmasi';
import "antd/dist/antd.min.css";
import HomePage3 from './pages/HomePage3';
import Menu from './pages/Menu';
import AboutUs3 from './pages/AboutUs3';
import Remitansi from './pages/Remitansi/Remitansi';
import Form from './pages/Remitansi/Form';
import Confirmation from './pages/Remitansi/Confirmation';
import Offshore from './pages/Offshore/Offshore';
import FAQ from './pages/FAQ/FAQ';
import withPageView from './googleAnalytics/WithPageView';
import RedirectBlog from './pages/RedirectBlog';

function App() {
  return (
    <div className="App" >
      <Router>
        <Switch>
          <Route exact path="/" component={withPageView(HomePage3)}/>
          {/* <Route exact path="/productsandservices" component={ProductServicePage}/> */}
          <Route exact path="/AboutUs" component={withPageView(AboutUs3)}/>
          <Route exact path="/Menu" component={withPageView(Menu)}/>
          <Route exact path="/remitance" component={withPageView(Remitansi)} />
          <Route exact path="/form" component={withPageView(Form)} />
          <Route exact path="/confirmation" component={withPageView(Confirmation)} />
          <Route exact path="/offshore" component={withPageView(Offshore)} />
          <Route exact path="/faq" component={withPageView(FAQ)} />

          {/* WEB PAYMENT */}
          <Route exact path="/paymentlink/:paymentId" component={withPageView(LamanPayment)} />
          <Route exact path="/payment-method" component={withPageView(MetodePembayaran)} />
          <Route exact path="/payment-confirm" component={withPageView(KonfirmasiPembayaran)} />
          <Route exact path="/status-confirm" component={withPageView(StatusKonfirmasi)} />
          <Route exact path="/error-page" component={withPageView(GagalKonfirmasi)} />

          {/* REDIRECT BLOG */}
          <Route exact path="/blog" component={withPageView(RedirectBlog)} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
