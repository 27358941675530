import React, { useEffect } from "react";
import expired from "../../assets/icon/expired_icon.svg";
import { useHistory, useLocation } from "react-router-dom";

function GagalKonfirmasi() {
  const location = useLocation();
  const history = useHistory();
  const data = location.state;

  // console.log(data, "ini data");

  useEffect(() => {
    localStorage.clear();
    return history.listen((action) => {
      if (action !== "PUSH") {
        history.go(1);
      }
    });
  }, [history]);

  return (
    <div className="main-content align-items-center">
      <div className="base-content-custom" style={{ width: "100%" }}>
        <div className={data?.imageCorp === null || data?.nameCorp === null ? "d-flex justify-content-center align-items-center py-4" : "d-flex justify-content-center align-items-center"}>
          <img
            src={data?.imageCorp}
            alt=""
            width="auto" 
            height="44px"
            className="my-2"
          />
          <div className="mx-2">{data?.nameCorp !== null ? data?.nameCorp : ""}</div>
        </div>
      </div>
      <div style={{ borderRadius: 8 }}>
        <div className=" mt-5 d-flex justify-content-center align-items-center">
          <div className="px-4 py-4 mt-5 text-center" style={{ width: 480 }}>
            <img src={expired} alt="expired status" />
            <div
              style={{ fontFamily: "Exo", fontSize: 20, fontWeight: 700 }}
              className="my-2"
            >
              Halaman Ini Tidak Tersedia
            </div>
            <div
              style={{ fontFamily: "Nunito", fontSize: 14, color: "#888888" }}
              className="my-2"
            >
              Jika anda <b>belum melakukan pembayaran</b>, silahkan konfirmasikan
              kepada pihak pembuat link. Jika anda <b>sudah melakukan pembayaran</b>,
              silahkan abaikan halaman ini.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GagalKonfirmasi;
