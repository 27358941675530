import React, { useEffect, useState } from "react";
import success from "../../assets/icon/success_icon.svg";
import { useHistory, useLocation } from "react-router-dom";
import { convertToRupiah } from "../../function/helpers";

function StatusKonfirmasi() {
  const location = useLocation();
  const data = location.state;
  const history = useHistory();
  const [count, setCount] = useState(0);

  function toError(imageCorp, nameCorp) {
    history.push("/error-page", { imageCorp, nameCorp });
  }

  function toErrorWithReload(imageCorp, nameCorp) {
    history.push("/error-page", { imageCorp, nameCorp });
    window.location.reload();
  }

  useEffect(() => {
    const a = JSON.parse(localStorage.getItem("countAccessPage"));
    localStorage.setItem("countAccessPage", JSON.stringify(a + 1));
    if (a > 1) {
      toError(data.data.corporate_logo, data.data.corporate_name);
    }
  }, []);

  // useEffect(() => {
  //   return history.listen((action) => {
  //     if (action.pathname === "/payment-confirm") {
  //       toErrorWithReload(data.data.corporate_logo, data.data.corporate_name);
  //     }
  //   });
  // }, [history]);

  return (
    <div className="main-content align-items-center">
      <div className="base-content-custom" style={{ width: "100%" }}>
        <div className={data.data.corporate_logo === null || data.data.corporate_name === null ? "d-flex justify-content-center align-items-center py-4" : "d-flex justify-content-center align-items-center"}>
          <img
            src={data.data.corporate_logo}
            alt=""
            width="auto" 
            height="44px"
            className="my-2"
          />
          <div className="mx-2">{data.data.corporate_name !== null ? data.data.corporate_name : ""}</div>
        </div>
      </div>
      <div style={{ borderRadius: 8 }}>
        <div className=" mt-5 d-flex justify-content-center align-items-center">
          <div className="px-4 py-4 text-center" style={{ width: 480 }}>
            <img src={success} alt="success status" />
            <div
              style={{ fontFamily: "Exo", fontSize: 20, fontWeight: 700 }}
              className="my-2"
            >
              Selamat, Pembayaran Anda Berhasil
            </div>
            <div
              style={{ fontFamily: "Nunito", fontSize: 14, color: "#888888" }}
              className="my-2"
            >
              {data.data.date_pay}
            </div>
            <div
              style={{ border: "1px solid #EBEBEB", borderRadius: 8, gap: 24 }}
              className="px-3 py-3 mt-2"
            >
              <div
                className="d-flex justify-content-between align-items-center mt-2 my-2"
                style={{ fontFamily: "Nunito", fontSize: 16, color: "#393939" }}
              >
                <div>Total Pembayaran</div>
                <div style={{ fontWeight: 700, fontFamily: "Exo" }}>
                  {convertToRupiah(data.data.amount)}
                </div>
              </div>
              <div
                className="d-flex justify-content-between align-items-center mt-2"
                style={{ fontFamily: "Nunito", fontSize: 16, color: "#393939" }}
              >
                <div className="text-start">Metode Pembayaran</div>
                <div className="d-flex justify-content-end align-items-center text-end">
                  <img
                    src={data.data.bank_icon}
                    alt={data.data.bank_icon}
                    width={48}
                  />
                  <div
                    className="ms-2"
                    style={{ fontWeight: 700, fontFamily: "Exo" }}
                  >
                    {data.data.bank}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatusKonfirmasi;
