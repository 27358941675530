import React from 'react'
import integrationCompanyImg from '../assets/image/integration_company.png'
import { Timeline } from 'antd'
import './css/AboutUs2.css'
import Navbar3 from '../component/Navbar3'
import Footer3 from '../component/Footer3'
import { language, useTitle } from '../function/helpers'
import mobile from 'is-mobile'

function AboutUs3() {
    useTitle("About Us")

    const isMobile = mobile()

    return (
        <div style={{ backgroundColor: "currentColor", width: "100%", height: "100%", fontFamily: "Exo" }}>
            <Navbar3 />
            <div className={isMobile ? 'py-5' : 'px-4 py-5'} style={{ width: "100%", background: "#FFFFFF" }}>
                <div className={isMobile ? '' : 'px-5'}>
                    <div className={isMobile ? 'text-center ' : 'px-5 '}>
                        {
                            language === 'eng' ?
                                <span style={{ fontSize: 36, fontWeight: 700 }}>Company Profile</span> :
                            language === 'chn' ?
                                <span style={{ fontSize: 36, fontWeight: 700 }}>公司简介 </span> :
                                <span style={{ fontSize: 36, fontWeight: 700 }}>Profil Perusahaan</span>
                        }
                    </div>
                </div>
                <div className={isMobile ? ' mt-3' : 'px-5 mt-3'}>
                    <div className={isMobile ? 'px-3' : 'px-5'}>
                        {
                            language === 'eng' ?
                                <div style={{ fontSize: isMobile ? 14 : 16, fontWeight: 400, color: '#888888', fontFamily: "Open Sans" }}>PT Ezeelink Indonesia has a Payment Service Provider (PJP) license – Kategori Izin 1 No. 23/572/DKSP/Srt/B from Bank Indonesia, a QRIS license and various official licenses for other financial services</div> :
                            language === 'chn' ?
                                <div style={{ fontSize: isMobile ? 14 : 16, fontWeight: 400, color: '#888888', fontFamily: "Open Sans" }}>PT Ezeelink Indonesia 拥有支付服务提供商 (PJP) 许可证 – 印度尼西亚银行的 Categori Izin 1 No. 23/572/DKSP/Srt/B、QRIS 许可证和其他金融服务的各种官方许可证</div> :
                                <>
                                    <div style={{ fontSize: isMobile ? 14 : 16, fontWeight: 400, color: '#888888', fontFamily: "Open Sans" }}>PT Ezeelink Indonesia memiliki lisensi E-Money dari Bank Indonesia (No. 20/210/DKSP) sejak tahun 2018 dan (No. 23/572/DKSP/Srt/B) – Kategori 1 di tahun 2021.</div>
                                    <div style={{ fontSize: isMobile ? 14 : 16, fontWeight: 400, color: '#888888', fontFamily: "Open Sans" }} className={isMobile ? "mt-3" : ""}>PT Ezeelink Indonesia juga tergabung dalam Asosiasi Sistem Pembayaran Indonesia dan Asosiasi Penyelenggara Pengiriman Uang Indonesia</div>
                                </>
                        }
                    </div>
                </div>
                <div className='mt-5' style={{ textAlign: 'center' }}><img src={integrationCompanyImg} alt='integrationCompany' className='img-fluid' /></div>
                <div className={isMobile ? 'mt-5 ' : 'mt-5 px-5'}>
                    <div className={isMobile ? 'px-3' : 'px-5'}>
                        {
                            language === 'eng' ?
                            <>
                                <div style={{ fontSize: isMobile ? 14 : 16, fontWeight: 400, color: '#888888', fontFamily: "Open Sans" }}>Through Ezeetunai, Ezeelink's e-money product will be integrated with banking accounts and digital wallets as well as other digital transaction systems in Indonesia, such as PPOB transactions and public transportation. </div>
                                <div style={{ fontSize: isMobile ? 14 : 16, fontWeight: 400, color: '#888888', fontFamily: "Open Sans" }} className={isMobile ? "mt-3" : ""}>Ezeelink through its financial service platform and application system is also ready to provide fintech services to the public, both individual users and organizations such as SMEs & Cooperatives Institution.</div>
                            </> : language === 'chn' ?
                            <>
                                <div style={{ fontSize: isMobile ? 14 : 16, fontWeight: 400, color: '#888888', fontFamily: "Open Sans" }}>通过 Ezeetunai，Ezeelink聚合了各种银行账户和数字钱包与线上和线下的支付场景（如 生活账单和公共交通支付）的。Ezeelink 通过其金融服务平台和应用系统也准备好向公众提供金融科技服务，包括个人用户和 中小企业& 企业等组织。 </div>
                            </> :
                            <>
                                <div style={{ fontSize: isMobile ? 14 : 16, fontWeight: 400, color: '#888888', fontFamily: "Open Sans" }}>Melalui Ezeetunai produk e-Money milik Ezeelink akan terintegrasi dengan akun perbankan dan dompet digital maupun sistem transaksi digital di Indonesia seperti transaksi PPOB dan transportasi publik.</div>
                                <div style={{ fontSize: isMobile ? 14 : 16, fontWeight: 400, color: '#888888', fontFamily: "Open Sans" }} className={isMobile ? "mt-3" : ""}>Ezeelink melalui platform layanan keuangan dan sistem aplikasinya juga siap memberikan layanan fintech kepada masyarakat baik pengguna perorangan maupun organisasi seperti UMKM & Koperasi.</div>
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className={isMobile ? 'py-5' : 'px-4 py-5'} style={{ width: "100%", background: "#F9EDDC" }}>
                <div className={isMobile ? '' : 'px-5'}>
                    <div className={isMobile ? 'px-3' : 'px-5'}>
                        {
                            language === 'eng' ?
                            <>
                                <div style={{ fontSize: 36, fontWeight: 700 }} className={isMobile ? 'text-center' : ''}>Ezeelink Vision</div>
                                <div style={{ fontSize: isMobile ? 14 : 16, fontWeight: 400, marginTop: 24, color: '#888888', fontFamily: "Open Sans" }}>
                                    <span>Ezeelink's vision is to become a credible digital financial service provider at the global level. In line with this vision, Ezeelink wants to help bridge partners and associates with their stakeholders through an effective electronic transaction management platform technology to be able to achieve the company's business goals.</span>
                                </div>
                            </> : language === 'chn' ?
                            <>
                                <div style={{ fontSize: 36, fontWeight: 700 }} className={isMobile ? 'text-center' : ''}>Ezeelink 的愿景</div>
                                <div style={{ fontSize: isMobile ? 14 : 16, fontWeight: 400, marginTop: 24, color: '#888888', fontFamily: "Open Sans" }}>
                                    <span>Ezeelink Indonesia 的愿景是成为全球范围内值得信赖的数字金融服务提供商。 根据这一愿景，Ezeelink 希望通过有效的电子交易管理平台技术帮助合作伙伴和合作伙伴与其利益相关者建立联系，为社会发展创造最大价值。</span>
                                </div>
                            </> :
                            <>
                                <div style={{ fontSize: 36, fontWeight: 700 }} className={isMobile ? 'text-center' : ''}>Visi Ezeelink</div>
                                <div style={{ fontSize: isMobile ? 14 : 16, fontWeight: 400, marginTop: 24, color: '#888888', fontFamily: "Open Sans" }}>
                                    <span>Visi Ezeelink Indonesia adalah menjadi penyedia layanan financial digital yang kredibel di tingkat global. Sejalan dengan visi ini, Ezeelink ingin membantu menjembatani partner dan mitra dengan para pemangku kepentingan mereka melalui sebuah teknologi platform pengelolaan transaksi elektronik yang efektif untuk dapat meraih tujuan bisnis perusahaan.</span>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className={isMobile ? ' py-4' : 'px-5 py-4'} style={{ width: "100%", background: "#FFFFFF" }}>
                <div className='px-5'>
                    <div className='px-4'>
                        {
                            language === 'eng' ?
                                <div style={{ fontSize: 36, fontWeight: 700 }} className={isMobile ? 'text-center' : ''}>Ezeelink Journey</div> :
                            language === 'chn' ?
                                <div style={{ fontSize: 36, fontWeight: 700 }} className={isMobile ? 'text-center' : ''}>Ezeelink 里程碑</div> :
                                <div style={{ fontSize: 36, fontWeight: 700 }} className={isMobile ? 'text-center' : ''}>Perjalanan Ezeelink</div>
                        }
                    </div>
                </div>
                <div className={isMobile ? '' : 'px-3'}>
                    <Timeline className={isMobile ? 'px-3 ms-2' : 'px-5 ms-2'} style={{ marginTop: 36}}>
                        <Timeline.Item color='#DF9C43'>
                            <div style={{ marginLeft: 24 }}>
                                <span style={{ fontSize: isMobile ? 20 : 26, fontWeight: 700 }}>2012</span>
                                {
                                    language === 'eng' ?
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>Officially operating and serving business partners.</p> :
                                    language === 'chn' ?
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>正式运营和服务合作伙伴。</p> :
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>Resmi beroperasi dan melayani partner bisnis.</p>
                                }
                            </div>
                        </Timeline.Item>
                        <Timeline.Item color='#DF9C43'>
                            <div style={{ marginLeft: 24 }}>
                                <span style={{ fontSize: isMobile ? 20 : 26, fontWeight: 700 }}>2013</span>
                                {
                                    language === 'eng' ?
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>Develop and expand service into the food and beverage, fashion, lifestyle, mini market, convenience store, electronics and other sectors.</p> :
                                    language === 'chn' ?
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>开发和拓展服务于餐饮、时尚、生活方式、迷你市场、便利店、电子等领域。</p> :
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>Mengembangkan dan melebarkan layanan ke sektor makanan dan minuman, fashion, gaya hidup, mini market, convenience store, elektronik dan lainnya.</p>
                                }
                            </div>
                        </Timeline.Item>
                        <Timeline.Item color='#DF9C43'>
                            <div style={{ marginLeft: 24 }}>
                                <span style={{ fontSize: isMobile ? 20 : 26, fontWeight: 700 }}>2014</span>
                                {
                                    language === 'eng' ?
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>Gained the trust of more business partners, institutions, and communities at the national level and succeeded in expanding its reach to 30 cities in Indonesia.</p> :
                                    language === 'chn' ?
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>在国家层面获得了更多商业伙伴、机构和社区的信任，并成功将业务范围扩大到印尼30个城市。</p> :
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>Mendapat kepercayaan dari lebih banyak partner bisnis, institusi, dan komunitas masyarakat di tingkat nasional serta berhasil memperluas jangkauan hingga 30 kota di Indonesia.</p>
                                }
                            </div>
                        </Timeline.Item>
                        <Timeline.Item color='#DF9C43'>
                            <div style={{ marginLeft: 24 }}>
                                <span style={{ fontSize: isMobile ? 20 : 26, fontWeight: 700 }}>2015</span>
                                {
                                    language === 'eng' ?
                                    <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>Trusted to be a partner of the official association in the retail sector, the Indonesian Retail Entrepreneurs Association (APRINDO) and the Indonesian Hotel and Restaurant Association (PHRI). Ezeelink began collaborating with merchant members of the association and was appointed as a partner in the implementation of the PHRI Reward program.</p> :
                                    language === 'chn' ?
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>值得信赖的零售行业官方协会、印度尼西亚零售企业家协会 (APRINDO) 和印度尼西亚酒店和餐厅协会 (PHRI) 的合作伙伴。 Ezeelink 开始与该协会的商家成员合作，并被任命为实施 PHRI 奖励计划的合作伙伴。</p> :
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>Dipercaya menjadi mitra dari asosiasi resmi di sektor retail Asosiasi Pengusaha Ritel Indonesia (APRINDO) dan Perhimpunan Hotel dan Restoran Indonesia (PHRI). Ezeelink mulai menjalin kerja sama dengan para merchant anggota asosiasi tersebut dan ditunjuk sebagai partner dalam pelaksanaan program PHRI Reward.</p>
                                }
                            </div>
                        </Timeline.Item>
                        <Timeline.Item color='#DF9C43'>
                            <div style={{ marginLeft: 24 }}>
                                <span style={{ fontSize: isMobile ? 20 : 26, fontWeight: 700 }}>2016</span>
                                {
                                    language === 'eng' ?
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>Serving more than 2,400 merchant outlets throughout Indonesia and launching the Loyalty Program.</p> :
                                    language  === 'chn' ?
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>为整个印度尼西亚的 2,400 多家商户提供数字化服务，并推出忠诚度计划。</p> :
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>Melayani lebih dari 2.400 outlet merchant se-Indonesia dan meluncurkan Loyalty Program.</p>
                                }
                            </div>
                        </Timeline.Item>
                        <Timeline.Item color='#DF9C43'>
                            <div style={{ marginLeft: 24 }}>
                                <span style={{ fontSize: isMobile ? 20 : 26, fontWeight: 700 }}>2017</span>
                                {
                                    language === 'eng' ?
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>Providing online & offline content regarding merchant info in the form of daily shopping, phone credit, Payment Point Online Banking (PPOB), and electronic vouchers for all communities, companies and employees.</p> :
                                    language === 'chn' ?
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>以日常购物、信用、支付点网上银行（PPOB）和优惠券的形式为所有社区、公司和员工提供有关商家信息的线上和线下支付场景。 </p> :
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>Menyediakan konten online & offline mengenai info merchant berupa belanja harian, pulsa, Payment Point Online Banking (PPOB), dan evoucher untuk semua komunitas, perusahaan dan karyawan.</p>
                                }
                            </div>
                        </Timeline.Item>
                        <Timeline.Item color='#DF9C43'>
                            <div style={{ marginLeft: 24 }}>
                                <span style={{ fontSize: isMobile ? 20 : 26, fontWeight: 700 }}>2018</span>
                                {
                                    language === 'eng' ?
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>Acquired e-money license from Bank Indonesia (No. 20/210/DKSP) updated in 2021 No. 23/572/DKSP/Srt/B - Kategori Izin 1 Pengelola Jasa Pembayaran (PJP)</p> :
                                    language === 'chn' ?
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>从印度尼西亚银行获得电子货币许可证（编号 20/210/DKSP），于 2021 年更新编号 23/572/DKSP/Srt/B - Kategori Izin 1 Pengelola Jasa Pembayaran (PJP)</p> :
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>Memperoleh lisensi uang elektronik dari Bank Indonesia (No. 20/210/DKSP) diperbarui pada tahun 2021 No. 23/572/DKSP/Srt/B - Izin Pengelola Layanan Pembayaran (PJP) Kategori 1</p>
                                }
                            </div>
                        </Timeline.Item>
                        <Timeline.Item color='#DF9C43'>
                            <div style={{ marginLeft: 24 }}>
                                <span style={{ fontSize: isMobile ? 20 : 26, fontWeight: 700 }}>2020</span>
                                {
                                    language === 'eng' ?
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>Acquired (BPR) Bank Koperindo aims to help millions of people in solving financial needs and achieving financial freedom for their future.</p> :
                                    language === 'chn' ?
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>获得(BPR) Bank Koperindo 旨在帮助数百万人解决财务需求并为他们的未来实现财务自由。</p> :
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>Mengakuisisi (BPR) Bank Koperindo dengan tujuan untuk membantu jutaan orang dalam memecahkan kebutuhan finansial dan mencapai kebebasan finansial untuk masa depan mereka.</p>
                                }
                            </div>
                        </Timeline.Item>
                        <Timeline.Item color='#DF9C43'>
                            <div style={{ marginLeft: 24 }}>
                                <span style={{ fontSize: isMobile ? 20 : 26, fontWeight: 700 }}>2022</span>
                                {
                                    language === 'eng' ?
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>International Payment Solution, Ezeelink expanding from existing local payment, collecting, disbursement solution to International level .</p> :
                                    language === 'chn' ?
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>国际支付解决方案，Ezeelink 从现有的本地支付，收款，支付解决方案扩展到国际水平</p> :
                                        <p style={{ fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, color: '#888888' }}>Solusi Pembayaran Internasional, Ezeelink berkembang dari solusi pembayaran, penagihan, pencairan lokal yang sudah ada sebelumnya ke tingkat Internasional.</p>
                                }
                            </div>
                        </Timeline.Item>
                    </Timeline>
                </div>
            </div>
            <Footer3 />
        </div>
    )
}

export default AboutUs3