import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-bootstrap";
import "../../pages/css/LamanPayment.css";
import { useHistory, useParams } from "react-router-dom";
import { auth, BaseURL, convertToRupiah } from "../../function/helpers";
import encryptData from "../../function/encryptData";
import axios from "axios";
import { signValidateUrl } from "../../function/sign";

const LamanPayment = () => {
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [detailPayment, setDetailPayment] = useState({});
  const { paymentId } = useParams();
  const [inputHandle, setInputHandle] = useState({
    name: "",
    email: "",
  });

  function handleChange(e) {
    e.preventDefault();
    setInputHandle({
      ...inputHandle,
      [e.target.name]: e.target.value,
    });
  }

  const showCheckboxes = () => {
    if (!expanded) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  };

  const toFeeMethod = (email, name, paymentId, detailPayment) => {
    history.push("/payment-method", { email, name, paymentId, detailPayment });
    window.location.reload();
  };

  async function getDetailPaymentUser(paymentId) {
    try {
      const dataParams = encryptData(`{"payment_id":"${paymentId}"}`);
      const headers = {
        "Content-Type": "application/json",
        Authorization: auth,
        sign: signValidateUrl,
      };
      const detailPaymentUser = await axios.post(BaseURL +
        "/PaymentLinkWeb/ValidateUrl",
        { data: dataParams },
        { headers: headers }
      );
      if (
        detailPaymentUser.status === 200 &&
        detailPaymentUser.data.response_code === 200
      ) {
        setDetailPayment(detailPaymentUser.data.response_data);
        if (detailPaymentUser.data.response_data.status_id === 0) {
          toConfirm(paymentId);
        } else if (detailPaymentUser.data.response_data.status_id === 1) {
          history.push(`/paymentlink/${paymentId}`);
        } else {
          toExpiredPage(detailPaymentUser.data.response_data.logo, detailPaymentUser.data.response_data.corporate_name);
        }
      }
    } catch (error) {
      // console.log(error);
      toExpiredPage(detailPayment.logo, detailPayment.corporate_name);
    }
  }

  const toConfirm = (paymentId) => {
    history.push("/payment-confirm", { paymentId });
    window.location.reload();
  };

  useEffect(() => {
    getDetailPaymentUser(paymentId);
  }, []);

  if (!loading) {
    document.getElementById("Payment").scrollIntoView();
  }

  function toExpiredPage(imageCorp, nameCorp) {
    history.push("/error-page", { imageCorp, nameCorp });
    window.location.reload();
  }

  return (
    <div className="main-content align-items-center" id="Payment">
      <div className="base-content-custom" style={{ width: "100%" }}>
        <div className="d-flex justify-content-center align-items-center py-2">
          <img src={detailPayment.logo} alt="" width="auto" height="44px" />
          <div className="mx-2">{detailPayment.corporate_name !== null ? detailPayment.corporate_name : ""}</div>
        </div>
      </div>
      <div style={{ borderRadius: 8 }}>
        <div className="d-flex justify-content-center align-items-center">
          <div className="px-4 py-4" style={{ width: 480 }}>
            <div className="mb-2" style={{ fontSize: 14 }}>
              Total Pembayaran
            </div>
            <h3
              style={{
                margin: "unset",
                fontFamily: "Exo",
                fontWeight: 700,
                fontSize: 24,
              }}
            >
              {detailPayment.amount === undefined
                ? ""
                : convertToRupiah(detailPayment.amount)}
            </h3>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <div style={{ color: "#888888", fontSize: 14 }} >
                Payment ID: <span>{paymentId}</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                  padding: "unset",
                }}
              >
                {!expanded ? (
                  <button
                    style={{
                      fontFamily: "Exo",
                      fontSize: 16,
                      fontWeight: 700,
                      alignItems: "center",
                      gap: 8,
                      height: 48,
                      color: "#077E86",
                      background: "unset",
                      border: "unset",
                    }}
                    onClick={showCheckboxes}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <div>Detail</div>
                    <span><FontAwesomeIcon icon={faChevronDown} className="mx-2" /></span>
                  </button>
                ) : (
                  <button
                    style={{
                      fontFamily: "Exo",
                      fontSize: 16,
                      fontWeight: 700,
                      alignItems: "center",
                      gap: 8,
                      height: 48,
                      color: "#077E86",
                      background: "unset",
                      border: "unset",
                    }}
                    onClick={showCheckboxes}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <div>Detail</div>
                    <span><FontAwesomeIcon icon={faChevronUp} className="mx-2" /></span>
                  </button>
                )}
              </div>
            </div>
            <div style={{ border: "3px solid #F0F0F0", backgroundColor: "#F0F0F0" }}></div>
            {!expanded ? (
              <Row className="mt-2">
                <Col xs={12}>
                  <div className="my-1">Nama <span style={{ color: "red" }}>*</span></div>
                  <input
                    className="input-text-user"
                    placeholder="Masukkan nama Anda"
                    value={inputHandle.name}
                    onChange={handleChange}
                    name="name"
                  />
                </Col>
                <Col xs={12} className="mt-1">
                  <div className="my-1">Email <span style={{ color: "red" }}>*</span></div>
                  <input
                    className="input-text-user"
                    placeholder="Masukkan email Anda"
                    value={inputHandle.email}
                    onChange={handleChange}
                    name="email"
                  />
                </Col>
                <Col xs={12} className="my-3">
                  <div
                    style={{
                      fontSize: "14px",
                      background: "rgba(255, 214, 0, 0.16)",
                      borderRadius: "4px",
                      fontStyle: "italic",
                      padding: "12px",
                      gap: 10,
                    }}
                  >
                    Data ini akan kami gunakan untuk mengirim bukti transaksi
                    pembayaran melalui email Anda.
                  </div>
                </Col>
                <Col xs={12} className="mt-5">
                  <div className="mt-5">
                    <div
                      className="mb-5 mt-5"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <button
                        onClick={() =>
                          toFeeMethod(
                            inputHandle.email,
                            inputHandle.name,
                            paymentId,
                            detailPayment
                          )
                        }
                        className={(inputHandle.name.length !== 0 && inputHandle.email.length !== 0) ? "btn-ez-on" : "btn-ez"}
                        disabled={(inputHandle.name.length === 0 && inputHandle.email.length === 0)}
                      >
                        Lanjutkan
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
              <>
                <div
                  style={{ fontFamily: "Exo", fontWeight: 700, fontSize: 16 }}
                  className="my-3"
                >
                  Deskripsi
                </div>
                <div style={{ fontFamily: "Nunito", fontSize: 14 }}>
                  {detailPayment.desc}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LamanPayment;
