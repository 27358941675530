import React from 'react'
import { Col, Row } from 'react-bootstrap'
import ezeeIcon from '../assets/icon/ezee_icon.svg'
import ezIconCHN from '../assets/icon/ezeelink_icon_chn.png'
import googlePlayIcon from '../assets/icon/icon_google_play_store.svg'
import appStoreIcon from '../assets/icon/icon_app_store.svg'
import instagramIcon from '../assets/icon/icon_instagram.svg'
import whatsappIcon from '../assets/icon/icon_whatsapp.svg'
import emailIcon from '../assets/icon/icon_email.svg'
import biIcon from '../assets/icon/icon_bi.svg'
import ASPIIcon from '../assets/icon/icon_ASPI.svg'
import fintechIdIcon from '../assets/icon/icon_fintech_id.svg'
import APPUIIcon from '../assets/icon/icon_APPUI.svg'
import mobile from 'is-mobile'

function Footer3() {
    const language = sessionStorage.getItem('lang')
    const isMobile = mobile()

    function toGooglePlay() {
        window.open('https://play.google.com/store/apps/details?id=com.ezee.main');
    }

    function toAppStore() {
        window.open('https://apps.apple.com/id/app/ezeelink/id1009099732')
    }

    function toInstagram() {
        window.open('https://instagram.com/ezeelink')
    }

    function toWhatsApp() {
        window.open('https://wa.me/6281280918668')
    }

    return (
        <div className={isMobile ? 'px-1' : ''} style={{  background: "#F0F0F0", padding: "48px 5rem" }}>
            {/* maxWidth: 1519 */}
            <div className={isMobile ? 'px-2' : ''}>
                <Row style={{ marginBottom: 43, fontFamily: "Exo", fontSize: isMobile ? 24 : 26, fontWeight: 700 }}>
                    {
                        language === 'eng' ?
                            <Col className={isMobile ? 'text-center' : ''}>
                               Find Out More
                            </Col> : language === 'chn' ?
                            <Col className={isMobile ? 'text-center' : ''}>
                                发现更多关于 Ezeelink
                            </Col> :
                            <Col className={isMobile ? 'text-center' : ''}>
                                Temukan Lebih Banyak tentang Ezeelink!
                            </Col>
                    }
                </Row>
                <Row style={{ marginBottom: 26 }} className={isMobile ? 'px-5' : ''}>
                    <Col className={isMobile ? 'text-center' : ''}>
                        <img src={language === 'chn' ? ezIconCHN : ezeeIcon} alt='ezeelinkIcon' />
                        <div style={{ fontSize: 16, fontWeight: 700, marginTop: 10, fontFamily: 'Exo' }} className={isMobile ? 'text-center' : ''}>PT. Ezeelink Indonesia</div>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 25 }} className={isMobile ? 'px-5' : 'd-flex justify-content-between'}>
                    <Col xl={3}>
                        {
                            language === 'eng' ?
                            <>
                                <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 24, fontFamily: 'Exo' }} className={isMobile ? 'text-center' : ''}>Address</div>
                            </> : language === 'chn' ?
                            <>
                                <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 24, fontFamily: 'Exo' }} className={isMobile ? 'text-center' : ''}>地址</div>
                            </> :
                            <>
                                <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 24, fontFamily: 'Exo' }} className={isMobile ? 'text-center' : ''}>Alamat</div>
                            </>
                        }
                        <div style={{ fontSize: 16, fontFamily: 'Open Sans', color: '#383838' }} className={isMobile ? 'text-center' : ''}>Wisma Techking 2 Jl. AM Sangaji No.24 Jakarta Pusat 10130, Indonesia</div>
                    </Col>
                    <Col xl={3} className={isMobile ? 'text-center mt-3' : ''} style={{ paddingLeft: isMobile ? "" : '3rem' }}>
                        {
                            language === 'eng' ?
                                <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 24, fontFamily: 'Exo' }}>Download</div> :
                            language === 'chn' ?
                                <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 24, fontFamily: 'Exo' }}>下载应用程序</div> :
                                <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 24, fontFamily: 'Exo' }}>Download Aplikasi</div>
                        }
                        <div style={{ marginBottom: 10, cursor: 'pointer' }}><img onClick={toGooglePlay} src={googlePlayIcon} alt='googlePlayIcon' /></div>
                        <div style={{ cursor: 'pointer' }}><img onClick={toAppStore} src={appStoreIcon} alt='appStoreIcon' /></div>
                    </Col>
                    <Col xl={3} className={isMobile ? 'mt-4' : ''} style={{ paddingLeft: isMobile ? "" : '3rem' }}>
                        {
                            language === 'eng' ?
                                <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 24, fontFamily: 'Exo' }} className={isMobile ? 'text-center' : ''}>Contact Us</div> :
                            language === 'chn' ?
                                <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 24, fontFamily: 'Exo' }} className={isMobile ? 'text-center' : ''}>联系我们</div> :
                                <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 24, fontFamily: 'Exo' }} className={isMobile ? 'text-center' : ''}>Kontak Kami</div>
                        }
                        <div className={isMobile ? 'ps-4 my-2' : 'my-2'} onClick={toInstagram} style={{ cursor: 'pointer' }}>
                            <span style={{ fontSize: 16, fontFamily: 'Open Sans', color: '#383838' }}><img src={instagramIcon} alt='instagramIcon' /> ezeelink</span>
                        </div>
                        <div className={isMobile ? 'ps-4 my-2' : 'my-2'} onClick={toWhatsApp} style={{ cursor: 'pointer' }}>
                            <span style={{ fontSize: 16, fontFamily: 'Open Sans', color: '#383838' }}><img src={whatsappIcon} alt='whatsappIcon' /> +62 812-8091-8668</span>
                        </div>
                        <div className={isMobile ? 'ps-4 my-2' : 'my-2'}>
                            <span style={{ fontSize: 16, fontFamily: 'Open Sans', color: '#383838' }}><img src={emailIcon} alt='emailIcon' /> contact@ezeelink.co.id</span>
                        </div>
                    </Col>
                    <Col xl={3} className={isMobile ? 'text-center mt-3' : (language !== 'chn' ? 'text-end' : 'ps-5')}>
                        {
                            language === 'eng' ?
                                <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 24, fontFamily: 'Exo' }} className="pe-2">Licensed and supervised by</div> :
                            language === 'chn' ?
                                <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 24, fontFamily: 'Exo' }} className="pe-2">许可和监督</div> :
                                <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 24, fontFamily: 'Exo' }} className="pe-2">Di lisensi dan diawasi oleh</div>
                        }
                        <img src={biIcon} alt='biIcon' />
                    </Col>
                </Row>
                <Row style={{ marginBottom: 24 }} >
                    <Col className={isMobile ? 'text-center' : ''}>
                        {
                            language === 'eng' ?
                                <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 24, fontFamily: 'Exo' }}>Member of</div> :
                            language === 'chn' ?
                                <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 24, fontFamily: 'Exo' }}>成员</div> :
                                <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 24, fontFamily: 'Exo' }}>Anggota dari</div>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col className={isMobile ? 'text-center' : ''}>
                        <img src={ASPIIcon} alt='ASPIIcon' className='pe-2' />{isMobile ? <br/> : ''}
                        <img src={fintechIdIcon} alt='fintechIdIcon' className={isMobile ? 'mt-4 px-2' : 'px-2'} />{isMobile ? <br/> : ''}
                        <img src={APPUIIcon} alt='APPUIIcon' className={isMobile ? 'mt-4 ps-2' : 'ps-2'} />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Footer3