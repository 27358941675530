import React, { useEffect, useState } from 'react'
import { Accordion, Col, Container, Nav, Navbar, OverlayTrigger, Popover, Row } from 'react-bootstrap'
import ezIcon from '../../assets/icon/ezeelink_icon.png'
import ezIconCHN from '../../assets/icon/ezeelink_icon_chn.png'
import remittanceIcon from '../../assets/icon/icon_Remittance_Business.svg'
import remittancePersonalIcon from '../../assets/icon/icon_Remittance_personal.svg'
import offshoreBankIcon from '../../assets/icon/icon_offshore_bank.svg'
import emoneyIcon from '../../assets/icon/icon_emoney.svg'
import whiteLabelWalletIcon from '../../assets/icon/icon_white_label_wallet.svg'
import paymentCollectionIcon from '../../assets/icon/icon_payment_collection.svg'
import cardlessCashIcon from '../../assets/icon/icon_cardless_cash.svg'
import transferIcon from '../../assets/icon/icon_Transfer.svg'
import voucherIcon from '../../assets/icon/icon_voucher.svg'
import qrisIcon from '../../assets/icon/icon_qris.svg'
import pulsaTagihanIcon from '../../assets/icon/icon_pulsa_tagihan.svg'
import loyaltyIcon from '../../assets/icon/icon_loyalty.svg'
import flagIdRoundIcon from '../../assets/icon/flag_id_round.svg'
import flagEnRoundIcon from '../../assets/icon/flag_en_round.svg'
import flagRrtRoundIcon from '../../assets/icon/flag_rrt_round.svg'
import '../css/FAQ.css'
import { useHistory, useLocation } from 'react-router-dom'
import Footer3 from '../../component/Footer3'
import dropDownWhite from '../../assets/icon/drop_down_white.svg'
import chatMessageIcon from '../../assets/icon/chat_message.svg'
import menuIcon from '../../assets/icon/menu_icon.svg'
import { useTitle, waEzeelink } from '../../function/helpers'
import mobile from 'is-mobile'
import headerFAQ from '../../assets/image/header_FAQ.jpg'
import searchIcon from '../../assets/icon/search_icon.svg'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import headerFAQMobile from '../../assets/image/header_FAQ_mobile.png'
import Autosuggest from 'react-autosuggest'
import AutosuggestHighlightMatch from 'autosuggest-highlight/match'
import AutosuggestHighlightParse from 'autosuggest-highlight/parse'

function FAQ() {
    useTitle("FAQ")

    const [push, setPush] = useState(false)
    const history = useHistory()
    const [isRemitancePersonal, setIsRemitancePersonal] = useState(false)
    const [isRemitanceBisnis, setIsRemitanceBisnis] = useState(true)
    const language = sessionStorage.getItem('lang')
    const location = useLocation()
    const [loading, setLoading] = useState(true)
    const [showPopover, setShowPopover] = useState(false)
    const [showPopoverLang, setShowPopoverLang] = useState(false)
    const [filteredQuestion, setFilteredQuestion] = useState("")
    const [keyword, setKeyword] = useState("")
    const isMobile = mobile()

    let arrFAQ = [
        {
            id: 0 ,
            question: language === 'eng' ? 'What is Ezeelink?' : language === 'chn' ? '什么是Ezeelink?' : 'Apa itu Ezeelink?',
            answer: language === 'eng' ? 'Ezeelink is a platform engaged in payment solutions and online transactions that serve personal users (consumers) as well as corporate and MSME.' : language === 'chn' ? 'Ezeelink 是一个从事支付解决方案和在线交易的平台，服务于个人用户（消费者）以及企业和 MSME' : 'Ezeelink adalah platform yang bergerak dalam bidang solusi pembayaran dan transaksi online yang melayani pengguna personal (consumer) maupun pengguna korporat dan UMKM.'
        },
        {
            id: 1 ,
            question: language === 'eng' ? 'What type of solution does Ezeelink offer?' : language === 'chn' ? 'Ezeelink 提供什么类型的解决方案?' : 'Apa saja jenis Solusi yang ditawarkan Ezeelink?',
            answer: language === 'eng' ? 'Ezeelink\'s payment and online transaction solutions include services for personal (consumer) such as Remittances, Electronic Money, Digital Vouchers, as well as for Corporate & MSME such as Business Remittance, E-Wallet, Payment Solution and Loyalty.' : language === 'chn' ? 'Ezeelink 的支付和在线交易解决方案包括针对个人（消费者）的服务，例如汇款、电子货币、数字凭证，以及针对企业和 MSME 的服务，例如商业汇款、电子钱包、支付解决方案和忠诚度。' : 'Solusi pembayaran dan transaksi online dari Ezeelink mencakup layanan untuk personal (konsumer) seperti Remitansi, Uang Elektronik, Voucher Digital, maupun untuk Korporat & UMKM seperti Remitansi Bisnis, E-Wallet, Payment Solution serta Loyalty.'
        },
        {
            id: 2 ,
            question: language === 'eng' ? 'How to get Ezeelink service?' : language === 'chn' ? '如何获取Ezeelink服务?' : 'Bagaimana mendapatkan layanan Ezeelink?',
            answer: language === 'eng' ? 'Ezeelink services can be obtained through mobile application available on Google Playstore and Apple App Store or through systems that can be connected and used on other platforms.' : language === 'chn' ? 'Ezeelink服务可以通过谷歌Playstore和Apple App Store提供的移动应用程序或通过其他平台可以连接和使用的系统获得。' : 'Layanan Ezeelink bisa didapat melalui aplikasi mobile yang tersedia di Google Playstore dan Apple App Store atau melalui sistem yang bisa dihubungkan dan digunakan di platform lainnya.'
        },
        {
            id: 3 ,
            question: language === 'eng' ? 'Is using the Ezeelink service safe?' : language === 'chn' ? '使用Ezeelink服务安全吗?' : 'Apakah menggunakan layanan Ezeelink aman?',
            answer: language === 'eng' ? 'Completely safe, because Ezeelink has a Penyedia Jasa Pembayaran (PJP) license – License Category 1 No 23/572/DKSP/Srt/B from Bank of Indonesia. Ezeelink is also known for its credibility and is a member of several financial associations and payment services.' : language === 'chn' ? '完全安全，因为 Ezeelink 拥有 Penyedia Jasa Pembayaran (PJP) 许可证 – 印度尼西亚银行颁发的许可证类别 1 No 23/572/DKSP/Srt/B。 Ezeelink 也以其信誉而闻名，是多个金融协会和支付服务的成员。' : 'Sepenuhnya aman, karena Ezeelink memiliki izin Penyedia Jasa Pembayaran (PJP) – Kategori Izin 1 No 23/572/DKSP/Srt/B dari Bank Indonesia. Ezeelink juga diakui kredibilitasnya dan tergabung dalam beberapa asosiasi finansial dan jasa pembayaran.'
        },
        {
            id: 4 ,
            question: language === 'eng' ? 'How can I as a personal user get the Ezeelink service?' : language === 'chn' ? '个人用户如何获得Ezeelink服务?' : 'Bagaimana saya sebagai pengguna personal bisa mendapatkan layanan Ezeelink?',
            answer: language === 'eng' ? 'Download and install Ezeelink application on your Android or iOS device then after the registration and verification steps are completed you can get the Ezeelink service as a personal user.' : language === 'chn' ? '在您的Android或iOS设备上下载并安装Ezeelink应用程序，然后在完成注册和验证步骤后，您可以作为个人用户获得Ezeelink服务。' : 'Download dan install aplikasi Ezeelink di perangkat Android atau iOS Anda kemudian setelah langkah-langkah registrasi dan verifikasinya selesai Anda bisa langsung mendapatkan layanan Ezeelink sebagai pengguna personal.'
        },
        {
            id: 5 ,
            question: language === 'eng' ? 'Is my personal account in Ezeelink application safe?' : language === 'chn' ? '我在Ezeelink应用程序中的个人账户安全吗?' : 'Apakah akun personal saya di aplikasi Ezeelink aman?',
            answer: language === 'eng' ? 'No need to worry, Ezeelink application is safe and reliable also guarantees the protection of a verified account (Ezeelink Prime) in the Ezeelink application.' : language === 'chn' ? '不用担心，Ezeelink 应用程序是安全可靠的，也保证了 Ezeelink 应用程序中经过验证的帐户（Ezeelink Prime）的保护。' : 'Tidak perlu khawatir, karena aplikasi Ezeelink aman dan terpercaya serta menjamin perlindungan akun terverifikasi (Ezeelink Prime) di aplikasi Ezeelink.'
        },
        {
            id: 6 ,
            question: language === 'eng' ? 'Why do I see Ezeelink logo and products in other application or ecommerce?' : language === 'chn' ? '为什么我在其他应用或电子商务中看到Ezeelink标志和产品?' : 'Kenapa saya menemui logo dan produk Ezeelink di aplikasi atau ecommerce lain?',
            answer: language === 'eng' ? 'As a payment solution and online transaction platform, Ezeelink collaborates with various partners for various products and solutions, both government and private agencies. You can confirm this through the partners page on this website.' : language === 'chn' ? '作为支付解决方案和在线交易平台，Ezeelink 与各种合作伙伴合作开发各种产品和解决方案，包括政府和私人机构。 您可以通过本网站上的合作伙伴页面确认这一点。' : 'Sebagai platform solusi pembayaran dan transaksi online, Ezeelink menjalin kerjasama dengan berbagai mitra untuk bermacam produk dan solusi baik itu instansi pemerintahan maupun swasta. Anda bisa memastikannya melalui halaman partner di website ini.'
        },
        {
            id: 7 ,
            question: language === 'eng' ? 'How can my business or the company that I work for can get Ezeelink service?' : language === 'chn' ? '我的企业或我工作的公司如何获得Ezeelink服务?' : 'Bagaimana bisnis saya atau perusahaan tempat saya bekerja bisa mendapatkan layanan Ezeelink?',
            answer: language === 'eng' ? 'For corporate and MSME users, you can contact Ezeelink via email contact@ezeelink.co.id or WhatsApp chat at 62 812-8091-8668, then you will be assisted to register your business or the company that you work for according to the Ezeelink service you want to get.' : language === 'chn' ? '对于企业和 MSME 用户，您可以通过电子邮件 contact@ezeelink.co.id 或 WhatsApp 聊天 62 812-8091-8668 联系 Ezeelink，然后您将被协助注册您的企业或您工作的公司 您想获得的 Ezeelink 服务。' : 'Untuk pengguna korporat dan UMKM bisa menghubungi Ezeelink melalui email contact@ezeelink.co.id atau WhatsApp chat di 62 812-8091-8668, selanjutnya Anda akan dibantu untuk mendaftarkan bisnis Anda atau perusahaan tempat Anda bekerja sesuai dengan layanan Ezeelink yang ingin didapatkan.'
        },
        {
            id: 8 ,
            question: language === 'eng' ? 'Do I need to have online shop or application to use Ezeelink corporate service?' : language === 'chn' ? '使用Ezeelink企业服务需要网店或申请吗?' : 'Apakah saya perlu memiliki toko online atau aplikasi untuk menggunakan layanan korporat Ezeelink?',
            answer: language === 'eng' ? 'You don\'t have to have online shop or application to get Ezeelink support. Your physical store or offline business can also get our services through various methods that can connect our system with your business.' : language === 'chn' ? '您无需拥有在线商店或应用程序即可获得 Ezeelink 支持。 您的实体店或线下业务也可以通过各种方式获得我们的服务，将我们的系统与您的业务连接起来。' : 'Anda tidak harus memililki toko online atau aplikasi untuk mendapatkan dukungan Ezeelink. Toko fisik atau bisnis offline Anda juga bisa mendapat layanan kami melalui berbagai metode yang menghubungkan sistem kami dengan bisnis Anda.'
        },
        {
            id: 9 ,
            question: language === 'eng' ? 'What should I do if my Ezeelink account is taken over by an irresponsible party?' : language === 'chn' ? '我的Ezeelink账号被不负责任的一方接管了怎么办?' : 'Apa yang harus dilakukan apabila akun Ezeelink saya diambil alih pihak tidak bertanggung jawab?',
            answer: language === 'eng' ? 'You should immediately contact Ezeelink Customer Support team via email contact@ezeelink.co.id or WhatsApp chat at 62 812-8091-8668 so that we can help.' : language === 'chn' ? '您应立即通过电子邮件 contact@ezeelink.co.id 或 WhatsApp 聊天电话 62 812-8091-8668 联系 Ezeelink 客户支持团队，以便我们提供帮助。' : 'Segera hubungi tim Customer Support Ezeelink melalui email contact@ezeelink.co.id atau WhatsApp chat di 62 812-8091-8668 untuk bisa kami bantu dengan penanganan lebih lanjut.'
        },
    ]

    /*react-autosuggest*/

    const [value, setValue] = useState('')
    const [suggestion, setSuggestion] = useState([])
    const [selectedFilteredQuestion, setSelectedFilteredQuestion] = useState(arrFAQ)
    const [isSelected, setIsSelected] = useState(false)
    const [methods, setMethods] = useState('')

    // function escapeRegexCharacters(str) {
    //     return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    // }

    function getSuggestions(value) {
        // const escapedValue = escapeRegexCharacters(value.trim());
        // console.log(escapedValue, 'escaped value');
        // if (escapedValue === '') {
        //   return [];
        // }

        // const regex = new RegExp('^' + escapedValue, 'i');

        // return arrFAQ.filter(faq => regex.test(faq.question))
        // console.log(value, 'value di funct get sugges');
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        // console.log(inputLength, 'input length');

        return inputLength === 0 ? [] : arrFAQ.filter(item => item.question && item.question.toLowerCase().includes(inputValue.toLowerCase()))
    }

    function getSuggestionValue(suggestion) {
        return suggestion.question;
    }

    function handleChange (event, {newValue, method} ) {
        setValue(newValue)
        setMethods(method)
        setIsSelected(false)
        // console.log(event);
        // console.log(newValue, "ini new value");
        // console.log(method);
    }
    function onSuggestionsFetchRequested ({value}) {
        // console.log(getSuggestions(value), 'funct get sugges');
        setSuggestion(getSuggestions(value))
    }

    function onSuggestionsClearRequested () {
        setSuggestion([])
    }

    function renderSuggestion(suggestion, { query }) {
        // console.log(suggestion, 'suggestion di render');
        // console.log(query, 'query di render');
        const matches = AutosuggestHighlightMatch(suggestion.question, query);
        const parts = AutosuggestHighlightParse(suggestion.question, matches);
        // console.log(matches, 'matches');
        // console.log(parts, 'parts');

        return (
            <span>
                {
                    parts.length !== 0 ?
                    parts.map((part, index) => {
                    const className = part.highlight ? 'react-autosuggest__suggestion-match' : null;

                        return (
                            <span className={className} key={index}>
                                {part.text}
                            </span>
                        );
                    }) :
                    <div className='text-center pb-5' style={{ fontFamily: "Open Sans", fontSize: isMobile ? 16 : 20 }}>{language === 'eng' ? <>No result for <b>{`“${query}”`}</b></> : language === 'chn' ? <><b>{`“${query}”`}</b> 没有结果</> : <>Tidak ada hasil untuk <b>{`“${query}”`}</b></>}</div>
                }
            </span>
        );
    }

    function onSuggestionSelected (event, {suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) {
        // console.log(event, 'event');
        // console.log(suggestionValue, 'suggestionValue');
        if (method === "click" || method === "enter") {
            // console.log(method, 'method');
            setIsSelected(true)
            const result = arrFAQ.filter(item => item.question && item.question.toLowerCase().includes(suggestionValue.toLowerCase()))
            // console.log(result, 'result');
            setTimeout(() => {
                setSelectedFilteredQuestion(result)
            }, 500);
        }
    }

    // console.log(selectedFilteredQuestion, "ini select filter");
    // console.log(value, 'value usestate');
    // console.log(suggestion, 'suggestion usestate');
    // console.log(isSelected, 'isSelected usestate');

    const inputProps = {
        placeholder: language === 'eng' ? "Search here" : language === 'chn' ? "这里搜索" : "Cari disini",
        value,
        type: 'search',
        onChange:  handleChange
    };

    /*react-search-autocomplete*/

    const filteredArrQuestion = arrFAQ.filter(item => item.question && item.question.toLowerCase().includes(filteredQuestion.toLowerCase()))

    function searchHandle(searchKeyWord, result) {
        // console.log(searchKeyWord, 'di funct search handle');
        // console.log(result, 'filter package');
        setKeyword(searchKeyWord)
        setFilteredQuestion(searchKeyWord)
        // result = result.filter(item => item.question && item.question.toLowerCase().includes(searchKeyWord.toLowerCase()))
        // console.log(result);
        // arrFAQ = result
    }

    function clickSearch(event, suggestion, searchKeyWord, isSelect, selectFilterQuestion) {
        // console.log(event.key, 'ini event');
        // console.log(isSelect, 'ini isSelect');
        // console.log(searchKeyWord, 'ini searchKeyWord');
        // console.log(suggestion, 'ini suggestion');
        // console.log(event.target.value, 'ini event.target.value');
        // console.log(selectFilterQuestion, 'di funct select');
        const backupSuggestion = arrFAQ.filter(item => item.question && item.question.toLowerCase().includes(searchKeyWord.toLowerCase()))
        // console.log(backupSuggestion, 'backupSuggestion');
        event.target.value = event.target.value === undefined ? "" : event.target.value
        if (event.key !== "Tab" || event.key !== "CapsLock" || event.key !== "Shift" || event.key !== "Control" || event.key !== "Alt" || event.key !== "ArrowUp" || event.key !== "ArrowDown" || event.key !== "ArrowRight" || event.key !== "ArrowLeft" || event.key !== "Backspace" || event.key !== "Meta" || event.key !== "Escape") {

        }
        if (isSelect && (event.key === undefined || event.key === "Enter")) {
            // console.log('masuk if 1');
            setSelectedFilteredQuestion(selectFilterQuestion)
            setIsSelected(false)
        } else if (!isSelect && (event.key === undefined || event.key === "Enter")) {
            // arrQuestion = arrQuestion.filter(item => item.question && item.question.toLowerCase().includes(searchKeyWord.toLowerCase()))
            // console.log('masuk if 2');
            if (suggestion.length === 0 && backupSuggestion.length === 0 && (event.target.value.length !== 0 && searchKeyWord.length !== 0)) {
                // console.log('masuk if if 1');
                // console.log(suggestion.length, "length suggest");
                // console.log(event.target.value.length, "event.target.value.length");
                const result = (
                    <div className='text-center pb-5' style={{ fontFamily: "Open Sans", fontSize: isMobile ? 16 : 20 }}>{language === 'eng' ? <>No result for <b>{`“${searchKeyWord}”`}</b></> : language === 'chn' ? <><b>{`“${searchKeyWord}”`}</b> 没有结果</> : <>Tidak ada hasil untuk <b>{`“${searchKeyWord}”`}</b></>}</div>
                )
                setSelectedFilteredQuestion(result)
            } else if (suggestion.length === 0 && backupSuggestion.length === 0 && (event.target.value.length === 0 && searchKeyWord.length !== 0)) {
                // console.log('masuk if if 2');
                // console.log(suggestion.length, "length suggest");
                // console.log(event.target.value.length, "event.target.value.length");
                // console.log(searchKeyWord, "search keyword if if 2");
                const result = (
                    <div className='text-center pb-5' style={{ fontFamily: "Open Sans", fontSize: isMobile ? 16 : 20 }}>{language === 'eng' ? <>No result for <b>{`“${searchKeyWord}”`}</b></> : language === 'chn' ? <><b>{`“${searchKeyWord}”`}</b> 没有结果</> : <>Tidak ada hasil untuk <b>{`“${searchKeyWord}”`}</b></>}</div>
                )
                setSelectedFilteredQuestion(result)
            } else if (suggestion.length === 0 && backupSuggestion.length === 0 && (event.target.value.length === 0 && searchKeyWord.length === 0))  {
                // console.log('masuk if if 3');
                setSelectedFilteredQuestion(arrFAQ)
            } else if (suggestion.length === 0 && backupSuggestion.length !== 0 && (event.target.value.length === 0 && searchKeyWord.length !== 0))  {
                // console.log('masuk if if backup');
                setSelectedFilteredQuestion(backupSuggestion)
            } else {
                // console.log('masuk if if 4');
                setSelectedFilteredQuestion(backupSuggestion)
            }
        }
    }

    // console.log(filteredArrQuestion, "ini filter");

    const formatResult = (item) => {
        // console.log(item, 'question');
        const letter = item.question.toLowerCase().replace(keyword.toLowerCase(), `<b>${keyword}</b>`)
        // filteredArrQuestion.forEach((items) => {
        //     // console.log(items.question, "items filter");
        //     const lettering = items.question.toLowerCase().replace(keyword.toLowerCase(), `<b>${keyword}</b>`)
        //     console.log(lettering, 'ini letter');
        //     return (
        //         <p dangerouslySetInnerHTML={{__html: lettering}}></p>
        //     )
        // })
        return (
            <p dangerouslySetInnerHTML={{__html: letter}}></p>
        )
    }

    function selectedHandle(selectedFAQ) {
        // console.log(selectedFAQ);
        setIsSelected(true)
        setSelectedFilteredQuestion([selectedFAQ])
    }

    function clearHandle(arrAwal) {
        setIsSelected(false)
        setSelectedFilteredQuestion(arrAwal)
    }

    function onPageLoad() {
        setLoading(false)
    }

    function chnLang() {
        sessionStorage.setItem('lang', 'chn');
        window.location.reload();
    }

    function engLang() {
        sessionStorage.setItem('lang', 'eng');
        window.location.reload();
    }

    function inaLang() {
        sessionStorage.removeItem('lang');
        window.location.reload();
    }

    function toMenu () {
        setPush(true)
        history.push('/Menu', {prevURL: location.pathname})
        window.location.reload();
    }

    function navbarHandle(menuName) {
        if (menuName === "tentang-kami") {
            history.push("/AboutUs")
            window.location.reload()
        } else if (menuName === 'pertanyaan') {
            history.push("/faq")
            window.location.reload()
        }
    }

    function navigateToHome(section) {
        history.push('/', {section: section});
        window.location.reload();
    }

    function toFaq(section) {
        document.getElementById(section).scrollIntoView()
    }

    function toForm() {
        history.push('/form')
        window.location.reload()
    }

    function tabRemitansi(tabRemitance) {
        if (tabRemitance === 'personal') {
            // sessionStorage.setItem('remitance', 'personal');
            setIsRemitancePersonal(true)
            setIsRemitanceBisnis(false)
        } else {
            // sessionStorage.removeItem('remitance', 'personal');
            setIsRemitancePersonal(false)
            setIsRemitanceBisnis(true)
        }
    }

    useEffect(() => {

        window.scroll({
            top: 100,
            left: 100,
            behavior: 'smooth'
        });

        if (document.readyState === 'complete') {
            onPageLoad()
        } else {
            window.addEventListener('load', onPageLoad)
            return () => window.removeEventListener('load', onPageLoad)
        }

        return history.listen(action => {
            if (action !== "PUSH") {
              // console.log("mskk");
                window.location.reload();
            }
        });

    }, [])

    useEffect(() => {
        searchHandle(keyword)
    }, [keyword])


    // console.log(filteredQuestion, 'filter question');
    // console.log(filteredArrQuestion, 'arr filter');

    return (
        <div className='main-content align-items-center'>
            <div className='base-content-custom' style={{ width: "100%" }}>
                <header className="header-home ">
                    <Navbar bg="transparent" expand="lg" className={isMobile ? "d-flex position-absolute justify-content-between align-items-center mt-4 px-3" : "d-flex position-absolute justify-content-between align-items-center mt-4"} style={{ width: "100%", fontFamily: 'Exo', padding: isMobile ? "" : "0rem 6rem" }}>
                        {/* <Container> */}
                            <Navbar.Brand href="/" style={{ zIndex: 1 }}><img src={language === 'chn' ? ezIconCHN : ezIcon} alt='icon-ez' width = {isMobile ? 85.96 : 90} height = {isMobile ? 45.58 : 48} className="img-fluid" /></Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toMenu} style={{ color: "#FFFFFF", border: "unset", zIndex: 1 }} ><img src={menuIcon} alt="menu"/></Navbar.Toggle>
                            {push === true ?
                                "" :
                                <>
                                    <Navbar.Collapse id="basic-navbar-nav" style={{ width: "100%"}}>
                                        <Nav className='text-center' style={{ width: "100%"}}>
                                            <div className='text-center' style={{ width: "100%", zIndex: 1 }}>
                                                <OverlayTrigger
                                                    show={showPopover}
                                                    trigger={["hover", "focus"]}
                                                    key={1}
                                                    placement="bottom"
                                                    overlay={
                                                        <Popover style={{ maxWidth: 600, maxHeight: 550 }} onMouseEnter={() => setShowPopover(true)} onMouseLeave={() => setShowPopover(false)} id='menu'>
                                                            <table className="table-products-item2 mt-4 mb-3">
                                                                <thead>
                                                                    <th>{language === 'eng' ? 'Corporate & SME' : language === 'chn' ? '企业和出海企业' : 'Korporat & UMKM'}</th>
                                                                    <th>{language === 'eng' ? 'Personal (Consumer)' : language === 'chn' ? '个人（消费者）' : 'Personal (Konsumer)'}</th>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('remitance')}>
                                                                            <div><img src={remittanceIcon} alt='remitance' /> { language === 'eng' ? 'Remittance for Business' : language === 'chn' ? '商务汇款' : 'Remitansi untuk Bisnis' }</div>
                                                                        </td>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('remitancePersonal')}>
                                                                            <div><img src={remittancePersonalIcon} alt='remitancePersonal' /> { language === 'eng' ? 'Remittance for Personal' : language === 'chn' ? '个人汇款' : 'Remitansi untuk Personal' }</div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('offshore')}>
                                                                            <div><img src={offshoreBankIcon} alt='offshoreBankAccount' /> { language === 'eng' ? 'Offshore Bank Account' : language === 'chn' ? '离岸银行账户' : 'Offshore Bank Account' }</div>
                                                                        </td>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('uangElektronik')}>
                                                                            <div><img src={emoneyIcon} alt='uangElektronik' /> { language === 'eng' ? 'Electronic Money' : language === 'chn' ? '电子货币' : 'Uang Elektronik' }</div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('whiteLabel')}>
                                                                            <div><img src={whiteLabelWalletIcon} alt='whiteLabelWallet' /> { language === 'eng' ? 'White-Label Wallet' : language === 'chn' ? '白牌钱包' : 'White-Label Wallet' }</div>
                                                                        </td>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('wallet')}>
                                                                            <div><img src={whiteLabelWalletIcon} alt='wallet' /> { language === 'eng' ? 'Wallet' : language === 'chn' ? '钱包' : 'Wallet' }</div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('payment')}>
                                                                            <div><img src={paymentCollectionIcon} alt='paymentCollection' /> { language === 'eng' ? 'Payment Collection' : language === 'chn' ? '收款' : 'Payment Collection' }</div>
                                                                        </td>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('tarikTunaiTanpaKartu')}>
                                                                            <div><img src={cardlessCashIcon} alt='tarikTunaiTanpaKartu' /> { language === 'eng' ? 'Cardless Cash Withdrawal' : language === 'chn' ? '无卡取款' : 'Tarik Tunai tanpa Kartu' }</div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('transfer')}>
                                                                            <div><img src={transferIcon} alt='transferPembayaran' /> { language === 'eng' ? 'Transfer Payment' : language === 'chn' ? '转账付款' : 'Transfer Pembayaran' }</div>
                                                                        </td>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('voucherDigital')}>
                                                                            <div><img src={voucherIcon} alt='voucherDigital' /> { language === 'eng' ? 'Digital Voucher' : language === 'chn' ? '数字优惠券' : 'Voucher Digital' }</div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('qris')}>
                                                                            <div><img src={qrisIcon} alt='qris' /> QRIS</div>
                                                                        </td>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('pulsaTagihan')}>
                                                                            <div><img src={pulsaTagihanIcon} alt='pulsaTagihan' /> { language === 'eng' ? 'Bills & Credits' : language === 'chn' ? '信用和票据' : 'Pulsa & Tagihan' }</div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('loyalty')}>
                                                                            <div><img src={loyaltyIcon} alt='loyalty' /> { language === 'eng' ? 'Loyalty Program' : language === 'chn' ? '忠诚计划' : 'Loyalty' }</div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Popover>
                                                    }
                                                >
                                                    <span onMouseEnter={() => setShowPopover(true)} onMouseLeave={() => setShowPopover(false)} className='menu-navbar-home2 py-3 px-3 mx-3' style={{ borderRadius: 4, color: "#FFFFFF", cursor: "pointer" }}>{ language === 'eng' ? 'Product & Solution' : language === 'chn' ? '产品与解决方案' : 'Produk & Solusi' } <img src={dropDownWhite} alt='arrowDropDownWhiteProduct' className={(showPopover) ? 'arrow-down-white-product-open' : "arrow-down-white-product"} /></span>
                                                </OverlayTrigger>
                                                <span onClick={() => navigateToHome('merchant')} className='menu-navbar-home2 py-3 px-3 mx-3' style={{ borderRadius: 4, color: "#FFFFFF", cursor: "pointer" }}>{ language === 'eng' ? 'Merchant & Partner' : language === 'chn' ? '商户和合作伙伴' : 'Merchant & Partner' }</span>
                                                <span onClick={() => navbarHandle("tentang-kami")} className='menu-navbar-home2 py-3 px-3 mx-3' style={{ borderRadius: 4, color: "#FFFFFF", cursor: "pointer" }}>{ language === 'eng' ? 'About Us' : language === 'chn' ? '关于我们' : 'Tentang Kami' }</span>
                                                <span onClick={() => navbarHandle("pertanyaan")} className='menu-navbar-home2 py-3 px-3 mx-3' style={{ borderRadius: 4, color: "#FFFFFF", cursor: "pointer", fontWeight: 700 }}>FAQ</span>
                                            </div>
                                        </Nav>
                                    </Navbar.Collapse>
                                    <Navbar.Collapse id="basic-navbar-nav" style={{ width: "15%", zIndex: 1 }}>
                                        <div className="text-end" style={{ width: "100%"}} >
                                            <OverlayTrigger
                                                show={showPopoverLang}
                                                trigger={["hover", "focus"]}
                                                key={2}
                                                placement="bottom"
                                                overlay={
                                                    <Popover style={{ maxWidth: 138, maxHeight: 212 }} onMouseEnter={() => setShowPopoverLang(true)} onMouseLeave={() => setShowPopoverLang(false)} id='menu'>
                                                        <Popover.Body>
                                                            <div className='mt-2 mb-4' onClick={() => inaLang()}>
                                                                <span className='menu-navbar-lang2' style={{ borderRadius: 4, color: "#383838", cursor: "pointer" }}><img src={flagIdRoundIcon} alt='idFlag' style={{ marginRight: 15 }} /> ID</span>
                                                            </div>
                                                            <div className='mt-4 mb-4' onClick={() => engLang()}>
                                                                <span className='menu-navbar-lang2' style={{ borderRadius: 4, color: "#383838", cursor: "pointer" }}><img src={flagEnRoundIcon} alt='idFlag' style={{ marginRight: 10 }} /> EN</span>
                                                            </div>
                                                            <div className='mt-4 mb-2' onClick={() => chnLang()}>
                                                                <span className='menu-navbar-lang2' style={{ borderRadius: 4, color: "#383838", cursor: "pointer" }}><img src={flagRrtRoundIcon} alt='idFlag' style={{ marginRight: 10 }} /> CN</span>
                                                            </div>
                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                            >
                                                <span onMouseEnter={() => setShowPopoverLang(true)} onMouseLeave={() => setShowPopoverLang(false)} className='menu-navbar-home2 py-3 px-3' style={{ borderRadius: 4, color: "#FFFFFF", cursor: "pointer" }}>{language === 'eng' ? <><img src={flagEnRoundIcon} alt='enFlag' /> &nbsp; EN</> : language === 'chn' ? <><img src={flagRrtRoundIcon} alt='cnFlag' /> &nbsp; CN</> : <><img src={flagIdRoundIcon} alt='idFlag' /> &nbsp; ID</>}<img src={dropDownWhite} alt='arrowDropDownWhiteLanguage' className={(showPopoverLang) ? 'arrow-down-white-language-open' : "arrow-down-white-language"} style={{ marginLeft: 12 }} /></span>
                                            </OverlayTrigger>
                                        </div>
                                    </Navbar.Collapse>
                                </>
                            }
                        {/* </Container> */}
                    </Navbar>
                    <Row>
                        <Col xl={12} style={{ paddingBottom: isMobile ? 30 : "", zIndex: 9999, paddingRight: "unset" }} className="d-flex justify-content-end align-items-center bottom-0 position-fixed">
                            <img onClick={() => waEzeelink()} src={chatMessageIcon} alt='chatMessageIcon' style={{ cursor: 'pointer'}} width = {isMobile ? 48 : 85} height = {isMobile ? 48 : 85} className="img-fluid" />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} className={isMobile ? "d-flex justify-content-center align-items-center text-center position-absolute mt-5 pt-5 ps-5" : "d-flex justify-content-center align-items-center position-absolute mt-5 pt-5"}>
                            <div style={{ fontFamily: "Exo", fontSize: isMobile ? 36 : 48, fontWeight: 700, color: "#FFFFFF", marginTop: 130 }}>{language === 'eng' ? 'Can i help you ?' : language === 'chn' ? '我可以帮你吗？' : 'Ada yang bisa kami bantu ?'}</div>
                        </Col>
                        <Col xl={12} className="d-flex justify-content-center align-items-center position-absolute mt-5 pt-5">
                            <div className='autoComplete' onKeyDown={(e) => clickSearch(e, suggestion, value, isSelected, selectedFilteredQuestion)} style={{ width: isMobile && "100%", marginTop: isMobile && 250 }}>
                                {/* <ReactSearchAutocomplete
                                    items={filteredArrQuestion}
                                    fuseOptions={{ keys: ['question'] }}
                                    resultStringKeyName='question'
                                    onSearch={searchHandle}
                                    onSelect={selectedHandle}
                                    onClear={() => clearHandle(arrFAQ)}
                                    showIcon={false}
                                    placeholder={language === 'eng' ? 'Search here' : language === 'chn' ? 'Search here' : 'Cari disini'}
                                    showNoResultsText={<div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 16 : 20 }}>{language === 'eng' ? <>No result for <b>{`“${keyword}”`}</b></> : language === 'chn' ? <>No result for <b>{`“${keyword}”`}</b></> : <>Tidak ada hasil untuk <b>{`“${keyword}”`}</b></>}</div>}
                                    autoFocus
                                    formatResult={formatResult}
                                /> */}
                                <Autosuggest
                                    suggestions={suggestion}
                                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                                    getSuggestionValue={getSuggestionValue}
                                    renderSuggestion={renderSuggestion}
                                    onSuggestionSelected={onSuggestionSelected}
                                    inputProps={inputProps}
                                />
                            </div>
                            {/* <input value={filteredQuestion} onChange={(e) => setFilteredQuestion(e.target.value)} style={{ fontFamily: 'Open Sans', marginTop: 250, marginRight: -10, width: '58%', height: 62, borderRadius: 8, padding: '16px 1px 16px 24px' }} type="text" placeholder='Cari disini' /> */}
                            <img className="search-button img-fluid" onClick={(e) => clickSearch(e, suggestion, value, isSelected, selectedFilteredQuestion)} src={searchIcon} alt='searchIcon' style={{ position: 'absolute', cursor: 'pointer' }} />
                        </Col>
                    </Row>
                    <img className='header-navbar-home2 img-fluid' src={isMobile ? headerFAQMobile : headerFAQ} alt="headerFAQ" />
                </header>
            </div>
            <div className={isMobile ? 'mt-5' : 'mt-5 px-5'} id='faq'>
                {
                    selectedFilteredQuestion.constructor !== Array ?
                    selectedFilteredQuestion :
                    <>
                        <div className={isMobile ? 'text-center px-5' : 'text-center'} style={{ fontWeight: 700, fontSize: isMobile? 26 : 36, lineHeight: "140%", fontFamily: 'Exo', color: '#383838' }}>{language === 'eng' ? 'Frequently Asked Questions About Ezeelink' : language === 'chn' ? '经常问的问题' : 'Pertanyaan Umum Mengenai Ezeelink'}</div>
                        <div className={isMobile ? ' mb-5' : 'px-5 mx-5 mb-5'} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Accordion defaultActiveKey="0" flush className='mt-3' style={{ borderBottom: "inset", width: isMobile ? '100%' : '70%' }}>
                                {
                                    selectedFilteredQuestion.map((item, idx) => {
                                        return (
                                            <Accordion.Item key={idx} eventKey={`${idx}`}>
                                                <Accordion.Header>
                                                    <div style={{ fontFamily: "Exo", fontSize: isMobile ? 16 : 20, fontWeight: 700, color: "#383838" }}>
                                                        <>{item.question}</>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400, color: '#888888' }}>
                                                        <>{item.answer}</>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    })
                                }
                            </Accordion>
                        </div>
                    </>
                }
            </div>
            <Footer3 />
        </div>
    )
}

export default FAQ