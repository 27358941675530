import React, { useEffect, useState } from 'react'
import bgForm from '../../assets/icon/bg_form.svg'
import { Col, Container, OverlayTrigger, Row, Popover, Image } from 'react-bootstrap'
import chevronLeft from '../../assets/icon/chevron_left.svg'
import flagIdRoundIcon from '../../assets/icon/flag_id_round.svg'
import flagEnRoundIcon from '../../assets/icon/flag_en_round.svg'
import flagRrtRoundIcon from '../../assets/icon/flag_rrt_round.svg'
import dropdownBlack from '../../assets/icon/drop_down_black.svg'
import remove from '../../assets/icon/remove.svg'
import '../../component/navbar.css'
import '../../pages/css/Form.css'
import { useHistory } from 'react-router-dom'
import encryptData from '../../function/encryptData'
import { auth, BaseURL, useTitle } from '../../function/helpers'
import axios from 'axios'
import { signForm } from '../../function/sign'
import loadingEzeelink from "../../assets/image/Double Ring-1s-303px.svg";
import mobile from 'is-mobile'

const Form = () => {
    useTitle("Form Registration Remittance")

    const [showPopoverLang, setShowPopoverLang] = useState(false)
    const language = sessionStorage.getItem('lang')
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const isMobile = mobile()
    const [inputHandle, setInputHandle] = useState({
        nameUser: "",
        companyName: "",
        position: "",
        phoneNumber: "",
        email: ""
    })
    const [isErrorNameUser, setIsErrorNameUser] = useState(false)
    const [isErrorCompanyName, setIsErrorCompanyName] = useState(false)
    const [isErrorPosition, setIsErrorPosition] = useState(false)
    const [isErrorPhoneNumber, setIsErrorPhoneNumber] = useState(false)
    const [isErrorEmail, setIsErrorEmail] = useState(false)

    function handleChange(e) {
        e.preventDefault();
        setInputHandle({
            ...inputHandle,
            [e.target.name]: e.target.value,
        });
        if (e.target.name === 'nameUser') {
            setIsErrorNameUser(false)
        } else if (e.target.name === 'companyName') {
            setIsErrorCompanyName(false)
        } else if (e.target.name === 'position') {
            setIsErrorPosition(false)
        } else if (e.target.name === 'phoneNumber') {
            setIsErrorPhoneNumber(false)
        } else if (e.target.name === 'email') {
            setIsErrorEmail(false)
        }
    }

    function chnLang() {
        sessionStorage.setItem('lang', 'chn');
        window.location.reload();
    }

    function engLang() {
        sessionStorage.setItem('lang', 'eng');
        window.location.reload();
    }

    function inaLang() {
        sessionStorage.removeItem('lang');
        window.location.reload();
    }

    function backToRemitance() {
        history.push('/remitance')
        window.location.reload()
    }

    function resetButton(values) {
        if (values === 'name') {
            setInputHandle({
                nameUser: "",
                companyName: inputHandle.companyName,
                position: inputHandle.position,
                phoneNumber: inputHandle.phoneNumber,
                email: inputHandle.email
            })
        } else if (values === 'company') {
            setInputHandle({
                nameUser: inputHandle.nameUser,
                companyName: "",
                position: inputHandle.position,
                phoneNumber: inputHandle.phoneNumber,
                email: inputHandle.email
            })
        } else if (values === 'position') {
            setInputHandle({
                nameUser: inputHandle.nameUser,
                companyName: inputHandle.companyName,
                position: "",
                phoneNumber: inputHandle.phoneNumber,
                email: inputHandle.email
            })
        } else if (values === 'phone') {
            setInputHandle({
                nameUser: inputHandle.nameUser,
                companyName: inputHandle.companyName,
                position: inputHandle.position,
                phoneNumber: "",
                email: inputHandle.email
            })
        } else {
            setInputHandle({
                nameUser: inputHandle.nameUser,
                companyName: inputHandle.companyName,
                position: inputHandle.position,
                phoneNumber: inputHandle.phoneNumber,
                email: ""
            })
        }
    }

    async function confirmForm(name, company, position, phone, email) {
        try {
            if (name.length === 0 || company.length === 0 || position.length === 0 || phone.length === 0 || email.length === 0) {
                if (name.length === 0) {
                    setIsErrorNameUser(true)
                }
                if (company.length === 0) {
                    setIsErrorCompanyName(true)
                }
                if (position.length === 0) {
                    setIsErrorPosition(true)
                }
                if (phone.length === 0) {
                    setIsErrorPhoneNumber(true)
                }
                if (email.length === 0) {
                    setIsErrorEmail(true)
                }
                return
            } else {
                setIsLoading(true)
                const dataParams = encryptData(`{"mreqrem_name":"${name}", "mreqrem_company_name":"${company}", "mreqrem_job_position":"${position}", "mreqrem_mobile_number":"${phone}", "mreqrem_email":"${email}"}`);
                const headers = {
                    "Content-Type": "application/json",
                    Authorization: auth,
                    sign: signForm,
                };
                const formUser = await axios.post(BaseURL +
                    "/Remittance/SaveRemittance",
                    { data: dataParams },
                    { headers: headers }
                );
                // console.log(formUser);
                if (
                    formUser.status === 200 &&
                    formUser.data.response_code === 200
                ) {
                    history.push('/confirmation')
                    // window.location.reload()
                    // setIsLoading(false)
                }
            }
        } catch (e) {
            if (e.response.status === 500) {
                alert(e.response.data.response_message)
                setIsLoading(false)
            }
        }
    }

    useEffect(() => {
        return history.listen(action => {
            if (action !== "PUSH") {
            // console.log("mskk");
            window.location.reload();
            }
        });
    }, [history]);

    const CustomLoader = () => (
        <div style={{ padding: "36px" }}>
            <Image
                className="loader-element animate__animated animate__jackInTheBox"
                src={loadingEzeelink}
                height={80}
            />
            <div>Loading...</div>
        </div>
    );

    return (
        <div className='main-content align-items-center'>
            {
                isLoading ?
                <div className="d-flex justify-content-center align-items-center vh-100">
                    <CustomLoader />
                </div> :
                <>
                    <div className='base-content-form' style={{ width: "100%" }}>
                        <Container>
                            <div className='d-flex justify-content-between align-items-center py-3'>
                                <div style={{ cursor: 'pointer' }} onClick={() => backToRemitance()} className='d-flex justify-content-between align-items-center'><img src={chevronLeft} alt="chevron left" /><span style={{ fontSize: 16, fontWeight: 700, fontFamily: 'Exo' }}>{language === 'eng' ? 'Back' : language === 'chn' ? '返回' : 'Kembali'}</span></div>
                                <div>
                                    <OverlayTrigger
                                        show={showPopoverLang}
                                        trigger={["hover", "focus"]}
                                        key={1}
                                        placement="bottom"
                                        overlay={
                                            <Popover onMouseEnter={() => setShowPopoverLang(true)} onMouseLeave={() => setShowPopoverLang(false)} style={{ maxWidth: 138, maxHeight: 212 }} id='menu'>
                                                <Popover.Body>
                                                    <div className='mt-2 mb-4' onClick={() => inaLang()}>
                                                        <span className='menu-navbar-lang2' style={{ borderRadius: 4, color: "#383838", cursor: "pointer" }}><img src={flagIdRoundIcon} alt='idFlag' style={{ marginRight: 15 }} /> ID</span>
                                                    </div>
                                                    <div className='mt-4 mb-4' onClick={() => engLang()}>
                                                        <span className='menu-navbar-lang2' style={{ borderRadius: 4, color: "#383838", cursor: "pointer" }}><img src={flagEnRoundIcon} alt='idFlag' style={{ marginRight: 10 }} /> EN</span>
                                                    </div>
                                                    <div className='mt-4 mb-2' onClick={() => chnLang()}>
                                                        <span className='menu-navbar-lang2' style={{ borderRadius: 4, color: "#383838", cursor: "pointer" }}><img src={flagRrtRoundIcon} alt='idFlag' style={{ marginRight: 10 }} /> CN</span>
                                                    </div>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <span onMouseEnter={() => setShowPopoverLang(true)} onMouseLeave={() => setShowPopoverLang(false)} className='menu-navbar-home2 py-3 px-3' style={{ borderRadius: 4, color: "#383838", cursor: "pointer" }}>{language === 'eng' ? <><img src={flagEnRoundIcon} alt='enFlag' /> &nbsp; EN</> : language === 'chn' ? <><img src={flagRrtRoundIcon} alt='cnFlag' /> &nbsp; CN</> : <><img src={flagIdRoundIcon} alt='idFlag' /> &nbsp; ID</>}<img src={dropdownBlack} alt='arrowDropDownWhiteLanguage' className={(showPopoverLang) ? 'arrow-down-white-language-open' : "arrow-down-white-language"} style={{ marginLeft: 12 }} /></span>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div className='d-flex flex-column justify-content-center position-absolute align-items-center mt-5 ' style={{width: "100%"}}>
                        <div className={isMobile ? 'base-content-form py-4 mb-5' : 'base-content-form py-4 px-4 mb-5'} style={{ width: isMobile ? '80%' : "40%", borderRadius: 8 }}>
                            <Container>
                                <div style={{ fontFamily: 'Exo', fontWeight: 700, fontSize: 24, color: "#383838" }} className={isMobile ? "text-center" : ""}>{language === 'eng' ? 'Please Fill Your Personal Information' : language === 'chn' ? '填写个人资料表格' : 'Isi Form Data Diri'}</div>
                                <div className='mt-3'>
                                    <Row >
                                        <Col xs={12} className='pb-2'>
                                            <div style={{ fontFamily: 'Nunito', fontWeight: 400, fontSize: isMobile ? 12 : 14, color: "#383838" }} className='py-1'>{language === 'eng' ? 'Full Name*' : language === 'chn' ? '全名' : 'Nama Lengkap*'}</div>
                                            <div className='pt-1 d-flex justify-content-end align-items-center position-relative'>
                                                <input name='nameUser' value={inputHandle.nameUser} onChange={handleChange} className='input-text-form' style={{ fontFamily: 'Nunito', fontWeight: 600, fontSize: isMobile ? 14 : 16, color: "#383838" }} /*placeholder='Masukkan Nama Lengkap'*/ />
                                                {inputHandle.nameUser !== "" && inputHandle.nameUser !== undefined   ?
                                                    <img onClick={() => resetButton('name')} src={remove} alt="remove" style={{cursor: "pointer"}} className='position-absolute pe-1' />
                                                    : ""
                                                }
                                            </div>
                                            {
                                                isErrorNameUser &&
                                                <span style={{ fontFamily: 'Nunito', fontWeight: 700, fontSize: 10, color: "#B9121B", marginLeft: 5 }}>{language === 'eng' ? 'Required*' : language === 'chn' ? 'Required*' : 'Wajib diisi*'}</span>
                                            }
                                        </Col>
                                        <Col xs={12} className='pb-2'>
                                            <div style={{ fontFamily: 'Nunito', fontWeight: 400, fontSize: isMobile ? 12 : 14, color: "#383838" }} className='py-1'>{language === 'eng' ? 'Company Name*' : language === 'chn' ? '公司名称' :'Nama Perusahaan*'}</div>
                                            <div className='pt-1 d-flex justify-content-end align-items-center position-relative'>
                                                <input name="companyName" value={inputHandle.companyName} onChange={handleChange} className='input-text-form' style={{ fontFamily: 'Nunito', fontWeight: 600, fontSize: isMobile ? 14 : 16, color: "#383838" }} /*placeholder='Masukkan Nama Perusahaan'*/ />
                                                {inputHandle.companyName !== "" && inputHandle.companyName !== undefined ?
                                                    <img onClick={() => resetButton('company')} src={remove} alt="remove" style={{cursor: "pointer"}} className='position-absolute pe-1' /> :
                                                    ""
                                                }
                                            </div>
                                            {
                                                isErrorCompanyName &&
                                                <span style={{ fontFamily: 'Nunito', fontWeight: 700, fontSize: 10, color: "#B9121B", marginLeft: 5 }}>{language === 'eng' ? 'Required*' : language === 'chn' ? 'Required*' : 'Wajib diisi*'}</span>
                                            }
                                        </Col>
                                        <Col xs={12} className='pb-2'>
                                            <div style={{ fontFamily: 'Nunito', fontWeight: 400, fontSize: isMobile ? 12 : 14, color: "#383838" }} className='py-1'>{language === 'eng' ? 'Position*' : language === 'chn' ? '位置' : 'Jabatan*'} </div>
                                            <div className='pt-1 d-flex justify-content-end align-items-center position-relative'>
                                                <input name='position' value={inputHandle.position} onChange={handleChange} className='input-text-form' style={{ fontFamily: 'Nunito', fontWeight: 600, fontSize: isMobile ? 14 : 16, color: "#383838" }} /*placeholder='Masukkan Jabatan'*/ />
                                                {inputHandle.position !== "" && inputHandle.position !== undefined ?
                                                    <img onClick={() => resetButton('position')} src={remove} alt="remove" style={{cursor: "pointer"}} className='position-absolute pe-1' /> :
                                                    ""
                                                }
                                            </div>
                                            {
                                                isErrorPosition &&
                                                <span style={{ fontFamily: 'Nunito', fontWeight: 700, fontSize: 10, color: "#B9121B", marginLeft: 5 }}>{language === 'eng' ? 'Required*' : language === 'chn' ? 'Required*' : 'Wajib diisi*'}</span>
                                            }
                                        </Col>
                                        <Col xs={12} className='pb-2'>
                                            <div style={{ fontFamily: 'Nunito', fontWeight: 400, fontSize: isMobile ? 12 : 14, color: "#383838" }} className='py-1'>{language === 'eng' ? 'Phone Number that Connected to Whatsapp*' : language === 'chn' ? '与 Whatsapp 关联的电话号码' : 'Nomor Telepon yang terhubung dengan Whatsapp*'}</div>
                                            <div className='pt-1 d-flex justify-content-end align-items-center position-relative'>
                                                <input type={'number'} inputMode='numeric' onKeyDown={(evt) => ["e", "E", "+", "-", ".", ","].includes(evt.key) && evt.preventDefault()} name='phoneNumber' value={inputHandle.phoneNumber} onChange={handleChange} className='input-text-form numbers' style={{ fontFamily: 'Nunito', fontWeight: 600, fontSize: isMobile ? 14 : 16, color: "#383838" }} /*placeholder='Masukkan Nomor Telepon' */ />
                                                {inputHandle.phoneNumber !== "" && inputHandle.phoneNumber !== undefined ?
                                                    <img onClick={() => resetButton('phone')} src={remove} alt="remove" style={{cursor: "pointer"}} className='position-absolute pe-1' /> :
                                                    ""
                                                }
                                            </div>
                                            {
                                                isErrorPhoneNumber &&
                                                <span style={{ fontFamily: 'Nunito', fontWeight: 700, fontSize: 10, color: "#B9121B", marginLeft: 5 }}>{language === 'eng' ? 'Required*' : language === 'chn' ? 'Required*' : 'Wajib diisi*'}</span>
                                            }
                                        </Col>
                                        <Col xs={12} className='pb-2'>
                                            <div style={{ fontFamily: 'Nunito', fontWeight: 400, fontSize: isMobile ? 12 : 14, color: "#383838" }} className='py-1'>{language === 'eng' ? 'Work Email*' : language === 'chn' ? '工作邮件' : 'Email Kerja*'}</div>
                                            <div className='pt-1 d-flex justify-content-end align-items-center position-relative'>
                                                <input name='email' value={inputHandle.email} onChange={handleChange} className='input-text-form' style={{ fontFamily: 'Nunito', fontWeight: 600, fontSize: isMobile ? 14 : 16, color: "#383838" }} /*placeholder='Masukkan Email Kerja' */ />
                                                {inputHandle.email !== "" && inputHandle.email !== undefined ?
                                                    <img onClick={() => resetButton('email')} src={remove} alt="remove" style={{cursor: "pointer"}} className='position-absolute pe-1' /> :
                                                    ""
                                                }
                                            </div>
                                            {
                                                isErrorEmail &&
                                                <span style={{ fontFamily: 'Nunito', fontWeight: 700, fontSize: 10, color: "#B9121B", marginLeft: 5 }}>{language === 'eng' ? 'Required*' : language === 'chn' ? 'Required*' : 'Wajib diisi*'}</span>
                                            }
                                        </Col>
                                        <Col xs={12} className="mt-3">
                                            <button onClick={() => confirmForm(inputHandle.nameUser, inputHandle.companyName, inputHandle.position, inputHandle.phoneNumber, inputHandle.email)} className='btn-ez-on'>{language === 'eng' ? 'Confirm & Submit' : language === 'chn' ? '确认' : 'Konfirmasi'}</button>
                                        </Col>
                                    </Row>
                                </div>
                            </Container>
                        </div>
                    </div>
                    <div className='text-center'>
                        <div className='mt-5'><img src={bgForm} alt="bgForm" className='img-fluid' /></div>
                    </div>
                </>
            }
        </div>
    )
}

export default Form