import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import copy from "../../assets/icon/copy_icon.svg";
import alertIcon from "../../assets/icon/alert_icon.svg";
import { useHistory, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import {
  auth,
  BaseURL,
  convertDateTimeStamp,
  convertToRupiah,
} from "../../function/helpers";
import encryptData from "../../function/encryptData";
import { signCheckPaymentStatus, signValidateUrl } from "../../function/sign";
import Accordion from "react-bootstrap/Accordion";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import "../css/KonfirmasiPembayaran.css";
import Countdown from "react-countdown";
import loadingEzeelink from "../../assets/image/Double Ring-1s-303px.svg";
import { Image } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";

function KonfirmasiPembayaran() {
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const dataProfile = location.state;
  const [countDownTime, setCountDownTime] = useState(0);
  const [countDownTimeStamp, setCountDownTimeStamp] = useState(0);
  const [dataConfirmPayment, setDataConfirmPayment] = useState({});
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);

  // console.log(dataConfirmPayment.logo, "data profile logo");
  // console.log(dataConfirmPayment.corporate_name, "data profile name");

  const CustomLoader = () => (
    <div style={{ padding: "36px" }}>
      <Image
        className="loader-element animate__animated animate__jackInTheBox"
        src={loadingEzeelink}
        height={80}
      />
      <div>Loading...</div>
    </div>
  );

  const showCheckboxes = () => {
    if (!expanded) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  };

  function onCompleteCountdown(imageCorp, nameCorp) {
    history.push("/error-page", { imageCorp, nameCorp });
    window.location.reload();
  }

  async function getDetailPaymentUser(paymentId) {
    try {
      const dataParams = encryptData(`{"payment_id":"${paymentId}"}`);
      const headers = {
        "Content-Type": "application/json",
        Authorization: auth,
        sign: signValidateUrl,
      };
      const detailPaymentUser = await axios.post(BaseURL +
        "/PaymentLinkWeb/ValidateUrl",
        { data: dataParams },
        { headers: headers }
      );
      // console.log(detailPaymentUser, "ini detail payment user");
      if (
        detailPaymentUser.status === 200 &&
        detailPaymentUser.data.response_code === 200
      ) {
        setLoading(false);
        setDataConfirmPayment(detailPaymentUser.data.response_data);
        const timeStamps = new Date(
          detailPaymentUser.data.response_data?.detail_va?.expired_date * 1000
        ).toLocaleString();
        const convertTimeStamps = new Date(timeStamps).getTime();
        const countDownTime = convertTimeStamps - Date.now();
        setCountDownTimeStamp(convertTimeStamps);
        setCountDownTime(countDownTime - 1000);
        if (detailPaymentUser.data.response_data.status_id !== 0) {
          if (detailPaymentUser.data.response_data.status_id !== 1) {
            onCompleteCountdown(
              detailPaymentUser.data.response_data.logo,
              detailPaymentUser.data.response_data.corporate_name
            );
          }
        }
      }
    } catch (error) {
      // console.log(error);
    }
  }

  async function confirmHandler(paymentId) {
    try {
      const dataParams = encryptData(`{"payment_id":"${paymentId}"}`);
      const headers = {
        "Content-Type": "application/json",
        Authorization: auth,
        sign: signCheckPaymentStatus,
      };
      const detailConfirm = await axios.post(BaseURL +
        "/PaymentLinkWeb/CheckPaymentStatus",
        { data: dataParams },
        { headers: headers }
      );
      if (
        detailConfirm.status === 200 &&
        detailConfirm.data.response_code === 200 &&
        detailConfirm.data.response_data.code === "001"
      ) {
        onCompleteCountdown(
          dataConfirmPayment?.logo,
          dataConfirmPayment?.corporate_name
        );
      } else if (
        detailConfirm.status === 200 &&
        detailConfirm.data.response_code === 200
      ) {
        toStatus(detailConfirm.data.response_data);
      }
    } catch (e) {
      // console.log(e);
      // if (e.response.status === 400) {
      //   history.push("/error-page")
      //   window.location.reload();
      // }
    }
  }

  const onCopy = useCallback(() => {
      setCopied(true);
  }, [])

  const onClick = useCallback(({target: {innerText}}) => {
      // console.log(`Clicked on "${innerText}"!`);
      alert("Copied!")
  }, [])

  function countDownNow (){
    const countDownTime = countDownTimeStamp - Date.now();
    setCountDownTime(countDownTime - 1000)
  }


  const toStatus = (data) => {
    history.push("/status-confirm", { data });
    window.location.reload();
  };

  useEffect(() => {
    countDownNow()
  }, [expanded, copied]);

  useEffect(() => {
    return history.listen((action) => {
      if (action !== "PUSH") {
        history.go(1);
      }
    });
  }, [history]);

  useEffect(() => {
    setLoading(true);
    let timer1 = setTimeout(
      () => getDetailPaymentUser(dataProfile.paymentId),
      1 * 1000
    );
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  return (
    <div>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <CustomLoader />
        </div>
      ) : (
        <div className="main-content align-items-center">
          <div className="base-content-custom" style={{ width: "100%" }}>
            <div className={dataConfirmPayment?.logo === null || dataConfirmPayment?.corporate_name === null ? "d-flex justify-content-center align-items-center py-4" : "d-flex justify-content-center align-items-center"}>
              <img
                src={dataConfirmPayment?.logo}
                alt=""
                width="auto" 
                height="44px"
                className="my-2"
              />
              <div className="mx-2 my-2">
                {dataConfirmPayment?.corporate_name !== null ? dataConfirmPayment?.corporate_name : ""}
              </div>
            </div>
          </div>
          <div style={{ borderRadius: 8 }}>
            <div className="d-flex justify-content-center align-items-center">
              <div className="px-4 py-4" style={{ width: 758 }}>
                <div
                  style={{
                    fontSize: "14px",
                    background: "rgba(255, 214, 0, 0.16)",
                    borderRadius: "4px",
                    fontStyle: "italic",
                    padding: "12px",
                    gap: 10,
                  }}
                  className="text-center my-2"
                >
                  <span className="mx-2">
                    <img src={alertIcon} alt="alert" />
                  </span>
                  Pastikan Anda tidak keluar dari halaman ini sebelum melakukan
                  pembayaran.
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div
                    style={{ fontSize: 20, fontWeight: 700, fontFamily: "Exo" }}
                    className="my-3"
                  >
                    Selesaikan Pembayaran Dalam Waktu
                  </div>
                  <div
                    style={{
                      color: "#DF9C43",
                      fontFamily: "Exo",
                      fontWeight: 700,
                      fontSize: 26,
                    }}
                  >
                    <Countdown
                      date={Date.now() + countDownTime + 1000}
                      daysInHours={true}
                      intervalDelay={1000}
                      onComplete={() =>
                        onCompleteCountdown(
                          dataConfirmPayment?.logo,
                          dataConfirmPayment?.corporate_name
                        )
                      }
                    />
                  </div>
                </div>
                <div style={{ color: "#888888", fontSize: 14 }}>
                  Batas Akhir Pembayaran:{" "}
                  <span
                    style={{ color: "#383838", fontSize: 14, fontWeight: 600 }}
                  >
                    {dataConfirmPayment?.detail_va?.expired_date === undefined
                      ? ""
                      : convertDateTimeStamp(
                          dataConfirmPayment?.detail_va?.expired_date
                        ).replace(".", ":")}
                  </span>
                </div>
                <div
                  style={{ border: "3px solid #F0F0F0" }}
                  className="my-3"
                ></div>
                <div className="mb-2" style={{ fontSize: 14 }}>
                  Total Pembayaran
                </div>
                <h3
                  style={{
                    margin: "unset",
                    fontFamily: "Exo",
                    fontWeight: 700,
                    fontSize: 24,
                  }}
                >
                  {convertToRupiah(dataConfirmPayment?.amount)}
                </h3>
                <div className="d-flex justify-content-between align-items-center">
                  <div style={{ color: "#888888", fontSize: 14 }}>
                    Payment ID: {dataProfile.paymentId}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                      padding: "unset",
                    }}
                  >
                    {!expanded ? (
                      <button
                        style={{
                          fontFamily: "Exo",
                          fontSize: 16,
                          fontWeight: 700,
                          alignItems: "center",
                          gap: 8,
                          height: 48,
                          color: "#077E86",
                          background: "unset",
                          border: "unset",
                        }}
                        onClick={showCheckboxes}
                      >
                        Detail{" "}
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className="mx-2"
                        />
                      </button>
                    ) : (
                      <button
                        style={{
                          fontFamily: "Exo",
                          fontSize: 16,
                          fontWeight: 700,
                          alignItems: "center",
                          gap: 8,
                          height: 48,
                          color: "#077E86",
                          background: "unset",
                          border: "unset",
                        }}
                        onClick={showCheckboxes}
                      >
                        Detail{" "}
                        <FontAwesomeIcon icon={faChevronUp} className="mx-2" />
                      </button>
                    )}
                  </div>
                </div>
                <div
                  style={{ border: "1px solid #EBEBEB" }}
                  className="my-3"
                ></div>
                {!expanded ? (
                  <>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <div
                          style={{
                            color: "#888888",
                            fontSize: 14,
                            fontFamily: "Nunito",
                          }}
                        >
                          Metode Pembayaran
                        </div>
                        <div
                          className="my-2"
                          style={{
                            color: "#393939",
                            fontSize: 16,
                            fontWeight: 700,
                            fontFamily: "Exo",
                          }}
                        >
                          {dataConfirmPayment?.detail_va?.bank}
                        </div>
                      </div>
                      <span
                        className="my-2 rounded square border border-1 d-flex justify-content-center align-items-center"
                        style={{ width: "64px", height: "55px" }}
                      >
                        <img
                          src={dataConfirmPayment?.detail_va?.bank_icon}
                          alt={dataConfirmPayment?.detail_va?.bank_icon}
                          width={50}
                        />
                      </span>
                    </div>
                    <div
                      style={{ border: "1px solid #EBEBEB" }}
                      className="my-3"
                    ></div>
                    <div>
                      <div
                        style={{
                          color: "#888888",
                          fontSize: 14,
                          fontFamily: "Nunito",
                        }}
                      >
                        No Virtual Account
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div
                          className="my-2"
                          style={{
                            color: "#393939",
                            fontSize: 16,
                            fontWeight: 700,
                            fontFamily: "Exo",
                          }}
                          id="noVA"
                        >
                          {dataConfirmPayment?.detail_va?.va_number}
                        </div>
                        <CopyToClipboard onCopy={onCopy} text={dataConfirmPayment?.detail_va?.va_number}>
                          <div
                            className="mx-2"
                            style={{
                              cursor: "pointer",
                              fontSize: 16,
                              fontFamily: "Exo",
                              fontWeight: 700,
                              color: "#077E86",
                            }}
                            onClick={onClick}
                          >
                            Salin{" "}
                            <span>
                              <img src={copy} alt="copy" />
                            </span>
                          </div>
                        </CopyToClipboard>
                      </div>
                    </div>
                    <div
                      style={{ border: "3px solid #F0F0F0" }}
                      className="my-3"
                    ></div>
                    {
                      dataConfirmPayment?.detail_va?.how_to_pay ?
                      <div
                        style={{
                          fontFamily: "Exo",
                          fontSize: 18,
                          fontWeight: 700,
                        }}
                        className="my-4"
                      >
                        Cara Pembayaran
                      </div> : null
                    }
                    <Accordion defaultActiveKey="0" flush>
                      {dataConfirmPayment?.detail_va?.how_to_pay?.map(
                        (item, index) => {
                          return (
                            <AccordionItem eventKey={index}>
                              <AccordionHeader
                                style={{
                                  fontFamily: "Exo",
                                  fontSize: 16,
                                  fontWeight: 700,
                                  cursor: "pointer",
                                }}
                              >
                                {item.payment_name}
                              </AccordionHeader>
                              <AccordionBody>
                                {item.step.map((itm, id) => {
                                  return (
                                    <div>
                                      <span>{id + 1 + ". "}</span>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: itm,
                                        }}
                                      ></span>
                                    </div>
                                  );
                                })}
                              </AccordionBody>
                            </AccordionItem>
                          );
                        }
                      )}
                    </Accordion>
                    <div
                      className="mb-5 mt-5"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <button
                        onClick={() => confirmHandler(dataProfile.paymentId)}
                        style={{
                          fontFamily: "Exo",
                          fontSize: 16,
                          fontWeight: 900,
                          alignItems: "center",
                          padding: "12px 24px",
                          gap: 8,
                          width: "100%",
                          height: 45,
                          background:
                            "linear-gradient(180deg, #F1D3AC 0%, #E5AE66 100%)",
                          border: "0.6px solid #2C1919",
                          borderRadius: 6,
                        }}
                      >
                        Saya Sudah Bayar
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        fontFamily: "Exo",
                        fontWeight: 700,
                        fontSize: 16,
                      }}
                      className="my-3"
                    >
                      Deskripsi
                    </div>
                    <div style={{ fontFamily: "Nunito", fontSize: 14 }}>
                      {dataConfirmPayment?.desc}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default KonfirmasiPembayaran;
