import React from 'react'
import ezIcon from '../assets/icon/ezeelink_icon.png'
import closed from '../assets/icon/closed.svg'
import { Accordion } from 'react-bootstrap'
import remittanceIcon from '../assets/icon/icon_Remittance_Business.svg'
import offshoreBankIcon from '../assets/icon/icon_offshore_bank.svg'
import whiteLabelWalletIcon from '../assets/icon/icon_white_label_wallet.svg'
import paymentCollectionIcon from '../assets/icon/icon_payment_collection.svg'
import transferIcon from '../assets/icon/icon_Transfer.svg'
import pulsaTagihanIcon from '../assets/icon/icon_pulsa_tagihan.svg'
import qrisIcon from '../assets/icon/icon_qris.svg'
import remittancePersonalIcon from '../assets/icon/icon_Remittance_personal.svg'
import emoneyIcon from '../assets/icon/icon_emoney.svg'
import cardlessCashIcon from '../assets/icon/icon_cardless_cash.svg'
import voucherIcon from '../assets/icon/icon_voucher.svg'
import loyaltyIcon from '../assets/icon/icon_loyalty.svg'
import { useHistory, useLocation } from 'react-router-dom'
import { language } from '../function/helpers'

const Menu = () => {
    const history = useHistory()
    const location = useLocation()

    function toHome() {
        history.push('/');
        window.location.reload();
    }

    function closeMenu() {
        // history.back()
        history.push(`${location.state.prevURL}`)
        window.location.reload();
    }

    function navigateToHome(section) {
        history.push('/', {section: section});
        window.location.reload();
    }

    function navbarHandle(menuName) {
        if (menuName === "tentang-kami") {
            history.push("/AboutUs")
            window.location.reload()
            // setIsAboutUS(true)
        } else if (menuName === 'pertanyaan') {
            history.push("/faq")
            window.location.reload()
        }
    }

    function chnLang() {
        sessionStorage.setItem('lang', 'chn');
        window.location.reload();
    }

    function engLang() {
        sessionStorage.setItem('lang', 'eng');
        window.location.reload();
    }

    function inaLang() {
        sessionStorage.removeItem('lang');
        window.location.reload();
    }
  return (
    <>
        <div className='d-flex justify-content-between align-items-center py-3 px-3'>
            <img src={ezIcon} alt="icon ezeelink" onClick={() => toHome()} />
            <img src={closed} alt="close button" onClick={() => closeMenu()} />
        </div>
        <Accordion defaultActiveKey="" flush className='mt-3' >
            <Accordion.Item style={{ border: "1px solid #EBEBEB", borderRadius: 4, gap: 12 }} id='remitance' eventKey='0' className='mx-3'>
                <Accordion.Header>
                    <div style={{ fontFamily: 'Exo', fontSize: 16, fontWeight: 700 }}>{ language === 'eng' ? 'Product & Solution' : language === 'chn' ? '产品与解决方案' : 'Produk & Solusi' } </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div style={{ fontFamily: "Exo", fontSize: 14, fontWeight: 700, color: "#888888" }}>{language === 'eng' ? 'Corporate & SME' : language === 'chn' ? '企业和出海企业' : 'Korporat & UMKM'}</div>
                    <div onClick={() => navigateToHome('remitance')} style={{ cursor: "pointer" }} className='d-flex justify-content-start align-items-center py-2 mt-3'>
                        <div><img src={remittanceIcon} alt='remitance' /></div> 
                        <div style={{ fontFamily: "Exo", fontSize: 14, fontWeight: 600, color: "#383838" }} className='ms-3'>{ language === 'eng' ? 'Remittance for Business' : language === 'chn' ? '商务汇款' : 'Remitansi untuk Bisnis' }</div>
                    </div>
                    <div onClick={() => navigateToHome('offshore')} style={{ cursor: "pointer" }} className='d-flex justify-content-start align-items-center py-2'>
                        <div><img src={offshoreBankIcon} alt='offshoreBankAccount' /></div> 
                        <div style={{ fontFamily: "Exo", fontSize: 14, fontWeight: 600, color: "#383838" }} className='ms-3'>{ language === 'eng' ? 'Offshore Bank Account' : language === 'chn' ? '离岸银行账户' : 'Offshore Bank Account' }</div>
                    </div>
                    <div onClick={() => navigateToHome('whiteLabel')} style={{ cursor: "pointer" }} className='d-flex justify-content-start align-items-center py-2'>
                        <div><img src={whiteLabelWalletIcon} alt='whiteLabelWallet' /></div> 
                        <div style={{ fontFamily: "Exo", fontSize: 14, fontWeight: 600, color: "#383838" }} className='ms-3'>{ language === 'eng' ? 'White-Label Wallet' : language === 'chn' ? '白牌钱包' : 'White-Label Wallet' }</div>
                    </div>
                    <div onClick={() => navigateToHome('payment')} style={{ cursor: "pointer" }} className='d-flex justify-content-start align-items-center py-2'>
                        <div><img src={paymentCollectionIcon} alt='paymentCollection' /></div> 
                        <div style={{ fontFamily: "Exo", fontSize: 14, fontWeight: 600, color: "#383838" }} className='ms-3'>{ language === 'eng' ? 'Payment Collection' : language === 'chn' ? '收款' : 'Payment Collection' }</div>
                    </div>
                    <div onClick={() => navigateToHome('transfer')} style={{ cursor: "pointer" }} className='d-flex justify-content-start align-items-center py-2'>
                        <div><img src={transferIcon} alt='transferPembayaran' /></div> 
                        <div style={{ fontFamily: "Exo", fontSize: 14, fontWeight: 600, color: "#383838" }} className='ms-3'>{ language === 'eng' ? 'Transfer Payment' : language === 'chn' ? '转账付款' : 'Transfer Pembayaran' }</div>
                    </div>
                    <div onClick={() => navigateToHome('qris')} style={{ cursor: "pointer" }} className='d-flex justify-content-start align-items-center py-2'>
                        <div><img src={qrisIcon} alt='qris' /></div> 
                        <div style={{ fontFamily: "Exo", fontSize: 14, fontWeight: 600, color: "#383838" }} className='ms-3'>QRIS</div>
                    </div>
                    <div onClick={() => navigateToHome('loyalty')} style={{ cursor: "pointer" }} className='d-flex justify-content-start align-items-center py-2'>
                        <div><img src={loyaltyIcon} alt='loyalty' /></div> 
                        <div style={{ fontFamily: "Exo", fontSize: 14, fontWeight: 600, color: "#383838" }} className='ms-3'>{ language === 'eng' ? 'Loyalty Program' : language === 'chn' ? '忠诚计划' : 'Loyalty' }</div>
                    </div>
                    <div className=' my-3 mt-4' style={{ borderBottom: "2px solid #EBEBEB" }}></div>
                    <div style={{ fontFamily: "Exo", fontSize: 14, fontWeight: 700, color: "#888888" }}>{language === 'eng' ? 'Personal (Consumer)' : language === 'chn' ? '个人（消费者)' : 'Personal (Konsumer)'}</div>
                    <div onClick={() => navigateToHome('remitancePersonal')} style={{ cursor: "pointer" }} className='d-flex justify-content-start align-items-center py-2 mt-3'>
                        <div><img src={remittancePersonalIcon} alt='remitancePersonal' /></div> 
                        <div style={{ fontFamily: "Exo", fontSize: 14, fontWeight: 600, color: "#383838" }} className='ms-3'>{ language === 'eng' ? 'Remittance for Personal' : language === 'chn' ? '个人汇款' : 'Remitansi untuk Personal' }</div>
                    </div>
                    <div onClick={() => navigateToHome('uangElektronik')} style={{ cursor: "pointer" }} className='d-flex justify-content-start align-items-center py-2'>
                        <div><img src={emoneyIcon} alt='uangElektronik' /></div> 
                        <div style={{ fontFamily: "Exo", fontSize: 14, fontWeight: 600, color: "#383838" }} className='ms-3'>{ language === 'eng' ? 'Electronic Money' : language === 'chn' ? '电子货币' : 'Uang Elektronik' }</div>
                    </div>
                    <div onClick={() => navigateToHome('wallet')} style={{ cursor: "pointer" }} className='d-flex justify-content-start align-items-center py-2'>
                        <div><img src={whiteLabelWalletIcon} alt='wallet' /></div> 
                        <div style={{ fontFamily: "Exo", fontSize: 14, fontWeight: 600, color: "#383838" }} className='ms-3'>{ language === 'eng' ? 'Wallet' : language === 'chn' ? '钱包' : 'Wallet' }</div>
                    </div>
                    <div onClick={() => navigateToHome('tarikTunaiTanpaKartu')} style={{ cursor: "pointer" }} className='d-flex justify-content-start align-items-center py-2'>
                        <div><img src={cardlessCashIcon} alt='tarikTunaiTanpaKartu' /></div> 
                        <div style={{ fontFamily: "Exo", fontSize: 14, fontWeight: 600, color: "#383838" }} className='ms-3'>{ language === 'eng' ? 'Cardless Cash Withdrawal' : language === 'chn' ? '无卡取款' : 'Tarik Tunai tanpa Kartu' }</div>
                    </div>
                    <div onClick={() => navigateToHome('voucherDigital')} style={{ cursor: "pointer" }} className='d-flex justify-content-start align-items-center py-2'>
                        <div><img src={voucherIcon} alt='voucherDigital' /></div> 
                        <div style={{ fontFamily: "Exo", fontSize: 14, fontWeight: 600, color: "#383838" }} className='ms-3'>{ language === 'eng' ? 'Digital Voucher' : language === 'chn' ? '数字优惠券' : 'Voucher Digital' }</div>
                    </div>
                    <div onClick={() => navigateToHome('pulsaTagihan')} style={{ cursor: "pointer" }} className='d-flex justify-content-start align-items-center py-2'>
                        <div><img src={pulsaTagihanIcon} alt='pulsaTagihan' /></div> 
                        <div style={{ fontFamily: "Exo", fontSize: 14, fontWeight: 600, color: "#383838" }} className='ms-3'>{ language === 'eng' ? 'Bills & Credits' : language === 'chn' ? '信用和票据' : 'Pulsa & Tagihan' }</div>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item style={{ border: "1px solid #EBEBEB", borderRadius: 4, gap: 12, paddingRight: 20, paddingLeft: 20 }}  onClick={() => navigateToHome('merchant')} className='mt-3 mx-3 py-3'>
                <div style={{ fontFamily: 'Exo', fontSize: 16, fontWeight: 700 }}>{ language === 'eng' ? 'Merchant & Partner' : language === 'chn' ? '商户和合作伙伴' : 'Merchant & Partner' }</div>
            </Accordion.Item>
            <Accordion.Item style={{ border: "1px solid #EBEBEB", borderRadius: 4, gap: 12, paddingRight: 20, paddingLeft: 20 }}  onClick={() => navbarHandle("tentang-kami")} className='mt-3 mx-3 py-3'>
                <div style={{ fontFamily: 'Exo', fontSize: 16, fontWeight: 700 }}>{ language === 'eng' ? 'About Us' : language === 'chn' ? '关于我们' : 'Tentang Kami' }</div>
            </Accordion.Item>
            <Accordion.Item style={{ border: "1px solid #EBEBEB", borderRadius: 4, gap: 12, paddingRight: 20, paddingLeft: 20 }} onClick={() => navbarHandle("pertanyaan")} className='mt-3 mx-3 py-3'>
                <div style={{ fontFamily: 'Exo', fontSize: 16, fontWeight: 700 }}>FAQ</div>
            </Accordion.Item>
        </Accordion>
        <div className='mx-3 my-3 mt-4' style={{ borderBottom: "2px solid #EBEBEB" }}></div>
        <div className='d-flex justify-content-between align-items-center py-2 px-3'>
            <div style={{ fontFamily: 'Open Sans', fontSize: 16, color: "#383838" }}>{ language === 'eng' ? 'Language' : language === 'chn' ? 'Language' : 'Bahasa' }</div>
            <div style={{ fontFamily: 'eXO', fontSize: 16, color: "#383838", fontWeight: 700 }} className='d-flex justify-content-between align-items-center'>
                <div onClick={() => inaLang()} style={{ color: language !== 'eng' && language !== 'chn' ? "#DF9C43" : "#383838", cursor: 'pointer' }}>ID</div>
                <div className='px-1'>|</div>
                <div onClick={() => engLang()} style={{ color: language === 'eng' ? "#DF9C43" : "#383838", cursor: 'pointer' }}>EN</div>
                <div className='px-1'>|</div>
                <div onClick={() => chnLang()} style={{ color: language === 'chn' ? "#DF9C43" : "#383838", cursor: 'pointer' }}>CN</div>
            </div>
        </div>
    </>
  )
}

export default Menu