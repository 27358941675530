import React, { useEffect, useRef, useState } from 'react'
import connectMapImage from '../assets/image/connect_map.png'
import { Accordion, Col, Container, Nav, Navbar, OverlayTrigger, Popover, Row } from 'react-bootstrap'
import remittanceIcon from '../assets/icon/icon_Remittance_Business.svg'
import offshoreBankIcon from '../assets/icon/icon_offshore_bank.svg'
import whiteLabelWalletIcon from '../assets/icon/icon_white_label_wallet.svg'
import paymentCollectionIcon from '../assets/icon/icon_payment_collection.svg'
import transferIcon from '../assets/icon/icon_Transfer.svg'
import qrisIcon from '../assets/icon/icon_qris.svg'
import loyaltyIcon from '../assets/icon/icon_loyalty.svg'
import korporatUmkmImg from '../assets/image/korporat_umkm.png'
import personalKonsumerImg from '../assets/image/personal_konsumer.png'
import remittancePersonalIcon from '../assets/icon/icon_Remittance_personal.svg'
import emoneyIcon from '../assets/icon/icon_emoney.svg'
import cardlessCashIcon from '../assets/icon/icon_cardless_cash.svg'
import voucherIcon from '../assets/icon/icon_voucher.svg'
import pulsaTagihanIcon from '../assets/icon/icon_pulsa_tagihan.svg'
import appSolutionIcon from '../assets/icon/app_solution.svg'
import portalSolutionIcon from '../assets/icon/portal_solution.svg'
import apiConnectionIcon from '../assets/icon/api_connection.svg'
import pluginSolutionIcon from '../assets/icon/plugin_solution.svg'
import weblinkSolutionIcon from '../assets/icon/weblink_solution.svg'
import widgetSolutionIcon from '../assets/icon/widget_solution.svg'
import linkedInOneImg from '../assets/image/linked_in_one.png'
import competitiveRateImg from '../assets/image/competitive_rate.png'
import makeItEzeeImg from '../assets/image/make_it_ezee.png'
import chevronRight from '../assets/icon/chevron_right.svg'
import kfc from '../assets/icon/kfc.png'
import tokopedia from '../assets/icon/tokopedia.png'
import gojek from '../assets/icon/gojek.png'
import pertamina from '../assets/icon/pertamina.png'
import alfamart from '../assets/icon/alfamart.png'
import blibli from '../assets/icon/blibli.png'
import grab from '../assets/icon/grab.png'
import indomaret from '../assets/icon/indomaret.png'
import ezeepasar from '../assets/icon/ezeepasar.png'
import map from '../assets/icon/map.png'
import shopee from '../assets/icon/shopee.png'
import transmart from '../assets/icon/transmart.png'
import traveloka from '../assets/icon/traveloka.png'
import './css/HomePage2.css'
import Footer3 from '../component/Footer3'
import { useHistory, useLocation } from 'react-router-dom'
import mobile from 'is-mobile'

import headerHome from '../assets/image/Header_Home.jpg'
import ezIcon from '../assets/icon/ezeelink_icon.png'
import ezIconCHN from '../assets/icon/ezeelink_icon_chn.png'
import dropDownWhite from '../assets/icon/drop_down_white.svg'
import flagIdRoundIcon from '../assets/icon/flag_id_round.svg'
import flagEnRoundIcon from '../assets/icon/flag_en_round.svg'
import flagRrtRoundIcon from '../assets/icon/flag_rrt_round.svg'
import chatMessageIcon from '../assets/icon/chat_message.svg'
import { useTitle, waEzeelink } from '../function/helpers'
import menuIcon from '../assets/icon/menu_icon.svg'
import imageMobileHome from '../assets/image/header_home_mobile.jpg'

function HomePage3() {
    // useTitle("Home")

    const history = useHistory()
    const isMobile = mobile();
    const language = sessionStorage.getItem('lang')
    const location = useLocation()
    const [push, setPush] = useState(false)
    const [loading, setLoading] = useState(true)
    const [showPopover, setShowPopover] = useState(false)
    const [showPopoverLang, setShowPopoverLang] = useState(false)
    let flagHistoryRemove = useRef(0)
    const [actKey1, setActKey1] = useState('0')
    const [actKey2, setActKey2] = useState('7')

    function onPageLoad() {
        setLoading(false)
    }

    function chnLang() {
        sessionStorage.setItem('lang', 'chn');
        window.location.reload();
    }

    function engLang() {
        sessionStorage.setItem('lang', 'eng');
        window.location.reload();
    }

    function inaLang() {
        sessionStorage.removeItem('lang');
        window.location.reload();
    }

    function navbarHandle(menuName) {
        if (menuName === "tentang-kami") {
            history.push("/AboutUs")
            window.location.reload()
        } else if (menuName === 'pertanyaan') {
            history.push("/faq")
            window.location.reload()
        }
    }

    function toMenu () {
        setPush(true)
        history.push('/Menu', {prevURL: location.pathname})
        window.location.reload();
    }

    function navigateToHome(section) {
        if (section === 'remitance') {
            setActKey1("0")
        } else if (section === 'remitancePersonal') {
            setActKey2("7")
        } else if (section === 'offshore') {
            setActKey1("1")
        } else if (section === 'uangElektronik') {
            setActKey2("8")
        } else if (section === 'whiteLabel') {
            setActKey1("2")
        } else if (section === 'wallet') {
            setActKey2("9")
        } else if (section === 'payment') {
            setActKey1("3")
        } else if (section === 'tarikTunaiTanpaKartu') {
            setActKey2("10")
        } else if (section === 'transfer') {
            setActKey1("4")
        } else if (section === 'voucherDigital') {
            setActKey2("11")
        } else if (section === 'qris') {
            setActKey1("5")
        } else if (section === 'pulsaTagihan') {
            setActKey2("12")
        } else if (section === 'loyalty') {
            setActKey1("6")
        }
        document.getElementById( section ).scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    function toOffshore () {
        history.push('/offshore')
        window.location.reload();
    }

    function openAccordionHanlde(accordionName) {
        // console.log(actKey1);
        // console.log(actKey2);
        if (accordionName === 'remitance') {
            if (actKey1 !== "0") {
                setActKey1("0")
            } else {
                setActKey1('')
            }
        } else if (accordionName === 'remitancePersonal') {
            if (actKey2 !== "7") {
                setActKey2("7")
            } else {
                setActKey2("")
            }
        } else if (accordionName === 'offshore') {
            if (actKey1 !== "1") {
                setActKey1("1")
            } else {
                setActKey1("")
            }
        } else if (accordionName === 'uangElektronik') {
            if (actKey2 !== "8") {
                setActKey2("8")
            } else {
                setActKey2("")
            }
        } else if (accordionName === 'whiteLabel') {
            if (actKey1 !== "2"){
                setActKey1("2")
            } else {
                setActKey1("")
            }
        } else if (accordionName === 'wallet') {
            if (actKey2 !== "9") {
                setActKey2("9")
            } else {
                setActKey2("")
            }
        } else if (accordionName === 'payment') {
            if (actKey1 !== "3") {
                setActKey1("3")
            } else {
                setActKey1("")
            }
        } else if (accordionName === 'tarikTunaiTanpaKartu') {
            if (actKey2 !== "10") {
                setActKey2("10")
            } else {
                setActKey2("")
            }
        } else if (accordionName === 'transfer') {
            if (actKey1 !== "4") {
                setActKey1("4")
            } else {
                setActKey1("")
            }
        } else if (accordionName === 'voucherDigital') {
            if (actKey2 !== "11") {
                setActKey2("11")
            } else {
                setActKey2("")
            }
        } else if (accordionName === 'qris') {
            if (actKey1 !== "5") {
                setActKey1("5")
            } else {
                setActKey1("")
            }
        } else if (accordionName === 'pulsaTagihan') {
            if (actKey2 !== "12") {
                setActKey2("12")
            } else {
                setActKey2("")
            }
        } else if (accordionName === 'loyalty') {
            if (actKey1 !== "6") {
                setActKey1("6")
            } else {
                setActKey1("")
            }
        }
    }

    function toRemitansiBisnis (section) {
        sessionStorage.setItem('remitance', section)
        history.push('/remitance', {section: section})
        window.location.reload()
    }

    // function toOffshore () {
    //     history.push('/offshore')
    //     window.location.reload()
    // }

    function handleActiveKeyFromOtherPage(menuName) {
        if (menuName === 'remitance') {
            setActKey1("0")
        } else if (menuName === 'remitancePersonal') {
            setActKey2("7")
        } else if (menuName === 'offshore') {
            setActKey1("1")
        } else if (menuName === 'uangElektronik') {
            setActKey2("8")
        } else if (menuName === 'whiteLabel') {
            setActKey1("2")
        } else if (menuName === 'wallet') {
            setActKey2("9")
        } else if (menuName === 'payment') {
            setActKey1("3")
        } else if (menuName === 'tarikTunaiTanpaKartu') {
            setActKey2("10")
        } else if (menuName === 'transfer') {
            setActKey1("4")
        } else if (menuName === 'voucherDigital') {
            setActKey2("11")
        } else if (menuName === 'qris') {
            setActKey1("5")
        } else if (menuName === 'pulsaTagihan') {
            setActKey2("12")
        } else if (menuName === 'loyalty') {
            setActKey1("6")
        }
    }

    if (!loading) {
        if (location.state !== undefined && flagHistoryRemove.current === 0) {
                const section = document.getElementById(location.state.section);
                // activeKey1.current = "0"
                handleActiveKeyFromOtherPage(location.state.section)
                flagHistoryRemove.current = 1
                section.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }

    useEffect(() => {
        // console.log(actKey1, 'actKey1 di dlm useEffect');
        // console.log(actKey2, 'actKey2 di dlm useEffect');
        document.title = "Home || Ezeelink Indonesia"
        if (document.readyState === 'complete') {
            onPageLoad()
        } else {
            window.addEventListener('load', onPageLoad)
            return () => window.removeEventListener('load', onPageLoad)
        }
    }, [actKey1, actKey2])

    return (
        <div style={{ backgroundColor: "currentColor", maxWidth: "100%", maxHeight: "100%", width: "100%", height: "100%", fontFamily: "Exo" }}>
            <header className="header-home ">
                <Navbar bg="transparent" expand="lg" className={isMobile ? "d-flex position-absolute justify-content-between align-items-center mt-4 px-3" : "d-flex position-absolute justify-content-between align-items-center mt-4"} style={{ width: "100%", padding: isMobile ? "" : "0rem 5rem" }}>
                    {/* <Container> */}
                        <Navbar.Brand href="/" style={{ zIndex: 1 }}><img src={language === 'chn' ? ezIconCHN : ezIcon} alt='icon-ez' width = {isMobile ? 85.96 : 90} height = {isMobile ? 45.58 : 48} className="img-fluid" /></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toMenu} style={{ color: "#FFFFFF", border: "unset", zIndex: 1 }} ><img src={menuIcon} alt="menu"/></Navbar.Toggle>
                        {push === true ?
                            "" :
                            <>
                                <Navbar.Collapse id="basic-navbar-nav" style={{ width: "100%"}}>
                                    <Nav className='text-center' style={{ width: "100%"}}>
                                        <div className='text-center' style={{ width: "100%", zIndex: 1 }}>
                                            <OverlayTrigger
                                                show={showPopover}
                                                trigger={["hover", "focus"]}
                                                key={1}
                                                placement="bottom"
                                                overlay={
                                                    <Popover style={{ maxWidth: 600, maxHeight: 550 }} onMouseEnter={(e) => setShowPopover(true)} onMouseLeave={(e) => setShowPopover(false)} id='menu'>
                                                        <table className="table-products-item2 mt-4 mb-3">
                                                            <thead>
                                                                <th>{language === 'eng' ? 'Corporate & SME' : language === 'chn' ? '企业和出海企业' : 'Korporat & UMKM'}</th>
                                                                <th>{language === 'eng' ? 'Personal (Consumer)' : language === 'chn' ? '个人（消费者）' : 'Personal (Konsumer)'}</th>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('remitance')}>
                                                                        <div><img src={remittanceIcon} alt='remitance' /> { language === 'eng' ? 'Remittance for Bussiness' : language === 'chn' ? '商务汇款' : 'Remitansi untuk Bisnis' }</div>
                                                                    </td>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('remitancePersonal')}>
                                                                        <div><img src={remittancePersonalIcon} alt='remitancePersonal' /> { language === 'eng' ? 'Remittance for Personal' : language === 'chn' ? '个人汇款' : 'Remitansi untuk Personal' }</div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('offshore')}>
                                                                        <div><img src={offshoreBankIcon} alt='offshoreBankAccount' /> { language === 'eng' ? 'Offshore Bank Account' : language === 'chn' ? '离岸银行账户' : 'Offshore Bank Account' }</div>
                                                                    </td>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('uangElektronik')}>
                                                                        <div><img src={emoneyIcon} alt='uangElektronik' /> { language === 'eng' ? 'Electronic Money' : language === 'chn' ? '电子货币' : 'Uang Elektronik' }</div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('whiteLabel')}>
                                                                        <div><img src={whiteLabelWalletIcon} alt='whiteLabelWallet' /> { language === 'eng' ? 'White-Label Wallet' : language === 'chn' ? '白牌钱包' : 'White-Label Wallet' }</div>
                                                                    </td>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('wallet')}>
                                                                        <div><img src={whiteLabelWalletIcon} alt='wallet' /> { language === 'eng' ? 'Wallet' : language === 'chn' ? '钱包' : 'Wallet' }</div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('payment')}>
                                                                        <div><img src={paymentCollectionIcon} alt='paymentCollection' /> { language === 'eng' ? 'Payment Collection' : language === 'chn' ? '收款' : 'Payment Collection' }</div>
                                                                    </td>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('tarikTunaiTanpaKartu')}>
                                                                        <div><img src={cardlessCashIcon} alt='tarikTunaiTanpaKartu' /> { language === 'eng' ? 'Cardless Cash Withdrawal' : language === 'chn' ? '无卡取款' : 'Tarik Tunai tanpa Kartu' }</div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('transfer')}>
                                                                        <div><img src={transferIcon} alt='transferPembayaran' /> { language === 'eng' ? 'Transfer Payment' : language === 'chn' ? '转账付款' : 'Transfer Pembayaran' }</div>
                                                                    </td>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('voucherDigital')}>
                                                                        <div><img src={voucherIcon} alt='voucherDigital' /> { language === 'eng' ? 'Digital Voucher' : language === 'chn' ? '数字优惠券' : 'Voucher Digital' }</div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('qris')}>
                                                                        <div><img src={qrisIcon} alt='qris' /> QRIS</div>
                                                                    </td>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('pulsaTagihan')}>
                                                                        <div><img src={pulsaTagihanIcon} alt='pulsaTagihan' /> { language === 'eng' ? 'Bills & Credits' : language === 'chn' ? '信用和票据' : 'Pulsa & Tagihan' }</div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('loyalty')}>
                                                                        <div><img src={loyaltyIcon} alt='loyalty' /> { language === 'eng' ? 'Loyalty Program' : language === 'chn' ? '忠诚计划' : 'Loyalty' }</div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Popover>
                                                }
                                            >
                                                <span onMouseEnter={(e) => setShowPopover(true)} onMouseLeave={(e) => setShowPopover(false)} className='menu-navbar-home2 py-3 px-3 mx-3' style={{ borderRadius: 4, color: "#FFFFFF", cursor: "pointer" }}>{ language === 'eng' ? 'Product & Solution' : language === 'chn' ? '产品与解决方案' : 'Produk & Solusi' } <img src={dropDownWhite} alt='arrowDropDownWhiteProduct' className={(showPopover) ? 'arrow-down-white-product-open' : "arrow-down-white-product"} /></span>
                                            </OverlayTrigger>
                                            <span onClick={() => navigateToHome('merchant')} className='menu-navbar-home2 py-3 px-3 mx-3' style={{ borderRadius: 4, color: "#FFFFFF", cursor: "pointer" }}>{ language === 'eng' ? 'Merchant & Partner' : language === 'chn' ? '商户和合作伙伴' : 'Merchant & Partner' }</span>
                                            <span onClick={() => navbarHandle("tentang-kami")} className='menu-navbar-home2 py-3 px-3 mx-3' style={{ borderRadius: 4, color: "#FFFFFF", cursor: "pointer" }}>{ language === 'eng' ? 'About Us' : language === 'chn' ? '关于我们' : 'Tentang Kami' }</span>
                                            <span onClick={() => navbarHandle("pertanyaan")} className='menu-navbar-home2 py-3 px-3 mx-3' style={{ borderRadius: 4, color: "#FFFFFF", cursor: "pointer", fontWeight: 700 }}>FAQ</span>
                                        </div>
                                    </Nav>
                                </Navbar.Collapse>
                                <Navbar.Collapse id="basic-navbar-nav" style={{ width: "15%"}}>
                                    <div className="text-end" style={{ width: "100%"}} >
                                        <OverlayTrigger
                                            show={showPopoverLang}
                                            trigger={["hover", "focus"]}
                                            key={2}
                                            placement="bottom"
                                            overlay={
                                                <Popover style={{ maxWidth: 138, maxHeight: 212 }} onMouseEnter={() => setShowPopoverLang(true)} onMouseLeave={() => setShowPopoverLang(false)} id='menu'>
                                                    <Popover.Body>
                                                        <div className='mt-2 mb-4' onClick={() => inaLang()}>
                                                            <span className='menu-navbar-lang2' style={{ borderRadius: 4, color: "#383838", cursor: "pointer" }}><img src={flagIdRoundIcon} alt='idFlag' style={{ marginRight: 15 }} /> ID</span>
                                                        </div>
                                                        <div className='mt-4 mb-4' onClick={() => engLang()}>
                                                            <span className='menu-navbar-lang2' style={{ borderRadius: 4, color: "#383838", cursor: "pointer" }}><img src={flagEnRoundIcon} alt='idFlag' style={{ marginRight: 10 }} /> EN</span>
                                                        </div>
                                                        <div className='mt-4 mb-2' onClick={() => chnLang()}>
                                                            <span className='menu-navbar-lang2' style={{ borderRadius: 4, color: "#383838", cursor: "pointer" }}><img src={flagRrtRoundIcon} alt='idFlag' style={{ marginRight: 10 }} /> CN</span>
                                                        </div>
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <span onMouseEnter={() => setShowPopoverLang(true)} onMouseLeave={() => setShowPopoverLang(false)} className='menu-navbar-home2 py-3 px-3' style={{ borderRadius: 4, color: "#FFFFFF", cursor: "pointer" }}>{language === 'eng' ? <><img src={flagEnRoundIcon} alt='enFlag' /> &nbsp; EN</> : language === 'chn' ? <><img src={flagRrtRoundIcon} alt='chnFlag' /> &nbsp; CN</> : <><img src={flagIdRoundIcon} alt='idFlag' /> &nbsp; ID</>}<img src={dropDownWhite} alt='arrowDropDownWhiteLanguage' className={(showPopoverLang) ? 'arrow-down-white-language-open' : "arrow-down-white-language"} style={{ marginLeft: 12 }} /></span>
                                        </OverlayTrigger>
                                    </div>
                                </Navbar.Collapse>
                            </>
                        }
                    {/* </Container> */}
                </Navbar>
                <Row>
                    <Col xl={12} style={{ paddingBottom: isMobile ? 30 : "", zIndex: 999, paddingRight: "unset" }} className="d-flex justify-content-end align-items-center bottom-0 position-fixed">
                        <img onClick={() => waEzeelink()} src={chatMessageIcon} alt='chatMessageIcon' style={{ cursor: 'pointer'}} width = {isMobile ? 48 : 85} height = {isMobile ? 48 : 85} className="img-fluid" />
                    </Col>
                </Row>
                <div  className={isMobile ? "position-absolute mt-5 pt-5" : "position-absolute mt-5 pt-5"} style={{ padding: isMobile ? "" : "0rem 2rem" }}>
                    <div className={isMobile ? "mt-5 pt-5 px-3" : "mt-5 pt-5 px-5"}>
                        <span style={{ color: "#FFFFFF", fontSize: isMobile ? 36 : 48, fontWeight: 700, fontFamily: 'Exo' }}>{ language === 'eng' ? 'Make Financial' : language === 'chn' ? '让金融交易更容易' : 'Jadikan Transaksi' }</span><br />
                        <span style={{ color: "#FFFFFF", fontSize: isMobile ? 36 : 48, fontWeight: 700, fontFamily: 'Exo' }}>{ language === 'eng' ? 'Transactions Easier' : language === 'chn' ? '' : 'Keuangan Lebih Mudah' }</span>
                        {language !== 'chn' && <><br /><br /></>}
                        {isMobile ?
                            <>
                                <span style={{ color: "#FFFFFF", fontSize: isMobile ? 16 : 20, fontWeight: 400, fontFamily: 'Open Sans' }}>{ language === 'eng' ? 'Ezeelink application and API facilitate digital transactions and provide security guarantees in every financial transaction.' : language === 'chn' ? 'Ezeelink 应用程序和 API 接口促进了数字交易，并在每笔金融交易中提供安全保证。' : 'Aplikasi dan API Ezeelink memudahkan transaksi digital dan memberikan jaminan keamanan dalam setiap transaksi keuangan.' }</span>
                            </> :
                            <>
                                {language !== 'chn' && <br />}
                                <span style={{ color: "#FFFFFF", fontSize: isMobile ? 16 : 20, fontWeight: 400, fontFamily: 'Open Sans' }}>{ language === 'eng' ? 'Ezeelink application and API facilitate digital transactions and' : language === 'chn' ? 'Ezeelink 应用程序和 API 接口促进了数字交易，并在每笔金融交易中提' : 'Aplikasi dan API Ezeelink memudahkan transaksi digital dan' }</span><br/>
                                <span style={{ color: "#FFFFFF", fontSize: isMobile ? 16 : 20, fontWeight: 400, fontFamily: 'Open Sans' }}>{ language === 'eng' ? 'provide security guarantees in every financial transaction.' : language === 'chn' ? '供安全保证。' : 'memberikan jaminan keamanan dalam setiap transaksi keuangan.' }</span>
                            </>
                        }
                    </div>
                </div>
                {isMobile ? <img className='header-navbar-home2 img-fluid' src={imageMobileHome} alt={"header_home"} /> : <img className='header-navbar-home2 img-fluid' src={headerHome} alt={"header_home"} />}
            </header>
            <div style={{ maxWidth: "100%", background: "#F9EDDC" }}>
                <Row style={{  width: "100%", margin: "unset" }}>
                    <Col xl={12} className='mt-5' style={{ textAlign: "center" }}>
                        <div style={{ fontSize: 36, fontWeight: 900, fontFamily: 'Exo' }} >{ language === 'eng' ? 'Easier Global Transaction' : language === 'chn' ? '更轻松的全球交易' : 'Transaksi Global Lebih Mudah' }</div>

                        <div style={{ fontSize: 16, fontWeight: 400, marginTop: 24, fontFamily: 'Open Sans' }}>
                        {
                            isMobile ?
                                <>
                                    <span>{ language === 'eng' ? 'Grow your business across borders with Ezeelink that makes digital transactions easier and more efficient' : language === 'chn' ? '使用 Ezeelink 跨界发展您的业务，使数字交易更轻松、更高效' : 'Kembangkan bisnis Anda melintasi batas antar negara bersama Ezeelink yang menjadikan transaksi digital lebih mudah dan efisien' }</span><br />
                                </>
                             :
                            <>
                                <span>{ language === 'eng' ? 'Grow your business across borders with Ezeelink that makes digital transactions easier and more' : language === 'chn' ? '使用 Ezeelink 跨界发展您的业务，使数字交易更轻松、更高效' : 'Kembangkan bisnis Anda melintasi batas antar negara bersama Ezeelink yang menjadikan transaksi' }</span><br />
                                <span> { language === 'eng' ? 'efficient' : language === 'chn' ? '' : 'digital lebih mudah dan efisien' }</span>
                            </>
                        }
                        </div>
                    </Col>
                    <Col xl={12} className='mt-4 text-center'>
                        <img src={connectMapImage} alt='petaKoneksi' className='text-center img-fluid' width={isMobile ? '100%' : '75%'} height={'auto'} />
                    </Col>
                </Row>
            </div>
            <div style={{ background: "#FFFFFF", paddingBottom: 2 }}>
                <Row style={{ display: "flex", justifyContent: "center", width: "100%", margin: "unset" }}>
                    <Col xl={12} className='mt-5' style={{ textAlign: "center", padding: "unset" }}>
                        <span style={{ fontSize: 36, fontWeight: 600, fontFamily: 'Exo' }}>{ language === 'eng' ? 'To Support Your Business, Or Financial Management' : language === 'chn' ? '支持您的业务或财务管理' : 'Untuk Mendukung Usaha, Atau Pengelolaan Finansial Anda' }</span>
                    </Col>
                    <Col xl={12} className="mt-4" style={{ textAlign: "center", padding: "unset", fontFamily: 'Open Sans' }}>
                        {isMobile ?
                            <span style={{ fontSize: 16, fontWeight: 400, marginTop: 24 }}>{ language === 'eng' ? 'Various Ezeelink products and services are available, either for your daily transaction needs or for your business and venture transactions' : language === 'chn' ? '各种 Ezeelink 产品和服务可供选择，既可满足您的日常交易需求，也可满足您的业务和商业交易需求' : 'Berbagai produk dan layanan Ezeelink tersedia, baik untuk keperluan transaksi sehari-hari maupun untuk transaksi bisnis dan usaha Anda.' }</span> :
                            <>
                                <span style={{ fontSize: 16, fontWeight: 400, marginTop: 24 }}>{ language === 'eng' ? 'Various Ezeelink products and services are available, either for your daily transaction needs or for' : language === 'chn' ? '各种 Ezeelink 产品和服务可供选择，既可满足您的日常交易需求，也可满足您的业务和商业交易需求' : 'Berbagai produk dan layanan Ezeelink tersedia, baik untuk keperluan transaksi sehari-hari' }</span><br/>
                                <span style={{ fontSize: 16, fontWeight: 400 }}>{ language === 'eng' ? 'your business and venture transactions' : language === 'chn' ? '' : 'maupun untuk transaksi bisnis dan usaha Anda.' }</span>
                            </>
                        }
                    </Col>
                </Row>
                {isMobile ?
                    <div className='mb-5 px-5 mt-2 align-items-center d-flex flex-column justify-content-center' >
                        <div className={isMobile ? 'py-3' : "px-5 py-2"}>
                            <Row className={isMobile ? '' : 'px-5 me-3'}>
                                <Col xs={12} className={isMobile ? 'text-center py-4' : ''} style={{ fontSize: 26, fontWeight: 700, padding: 'unset' }}> { language === 'eng' ? 'Corporate & SME' : language === 'chn' ? '企业和出海企业' : 'Korporat & UMKM' } </Col>
                                <Col xs={12} className={isMobile ? 'text-center' : ''} style={{padding: 'unset', fontFamily: "Open Sans"}}> { language === 'eng' ? 'Maximize effectiveness and efficiency in financial traffic for your venture and business through API integration, Plugin connection or transaction portal for Ezeelink products' : language === 'chn' ? '通过 Ezeelink 产品的 API 接口、插件小工具连接或交易门户，最大限度地提高您的业务和业务的财务流量的有效性和效率' : 'Maksimalkan efektifitas dan efisiensi keuangan untuk bisnis Anda melalui produk-produk Ezeelink' }</Col>
                                <Col xs={12}>
                                    <div className="text-center px-3 py-4">
                                        <img src={korporatUmkmImg} alt='korporat&UMKMImage' className={isMobile ? 'img-fluid' : ''} />
                                    </div>
                                </Col>
                                <Col xs={12} style={{padding: 'unset'}}>
                                    <Accordion defaultActiveKey="0" activeKey={actKey1} flush className='accordion-homepage mt-3' style={{ borderBottom: "inset" }}>
                                        <Accordion.Item id='remitance' eventKey='0' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('remitance')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={remittanceIcon} alt='remitance' style={{ marginRight: 10 }}/> {isMobile ? <div>{ language === 'eng' ? 'Remittance for Business' : language === 'chn' ? '商务汇款' : 'Remitansi untuk Bisnis' }</div> : `${ language === 'eng' ? 'Remittance for Business' : language === 'chn' ? '商务汇款' : 'Remitansi untuk Bisnis' }` }</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'Receive or transfer payments for vendors, clients or business partners across borders faster, easier and more affordable with Remittance for Business service from Ezeelink.' : language === 'chn' ? '使用 Ezeelink 的商业汇款服务更快、更轻松、更实惠地为供应商、客户或商业合作伙伴接收或转移付款。' : 'Terima atau transfer pembayaran untuk vendor, klien ataupun rekan bisnis lintas batas negara dengan lebih cepat, mudah dan terjangkau dengan layanan Remittance for Business Ezeelink.' }</div>
                                                <div onClick={() => toRemitansiBisnis('bisnis')} style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item id='offshore' eventKey='1' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('offshore')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={offshoreBankIcon} alt='offshoreBankAccount' style={{ marginRight: 10 }} /> {isMobile ? <div>{ language === 'eng' ? 'Offshore Bank Account' : language === 'chn' ? '离岸银行账户' : 'Offshore Bank Account' }</div> : `${ language === 'eng' ? 'Offshore Bank Account' : language === 'chn' ? '离岸银行账户' : 'Offshore Bank Account' }`}</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'Collecting and managing funds overseas can be a challenge, Ezeelink offers an offshore bank account opening option to facilitate international trade and cross border e-commerce clients.' : language === 'chn' ? '在海外安全收集和管理资金可能是一项挑战，Ezeelink 提供离岸银行开户选项，为促进国际贸易和跨境电子商务客户提供便利。' : 'Mengumpulkan dan mengelola dana di luar negeri dapat menjadi tantangan, Ezeelink menawarkan opsi pembukaan rekening bank luar negeri untuk memfasilitasi perdagangan internasional dan klien e-commerce lintas negara.' }</div>
                                                <div onClick={() => toOffshore()} style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item id='whiteLabel' eventKey='2' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('whiteLabel')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={whiteLabelWalletIcon} alt='whiteLabelWallet' style={{ marginRight: 10 }} /> {isMobile ? <div>{ language === 'eng' ? 'White-Label Wallet' : language === 'chn' ? '白牌钱包' : 'White-Label Wallet' }</div> : `${ language === 'eng' ? 'White-Label Wallet' : language === 'chn' ? '白牌钱包' : 'White-Label Wallet' }`}</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'Upgrade your level of business transaction with a digital wallet that can be customized according to your characteristics and brand identity.' : language === 'chn' ? '可根据您的需求定制的数字钱包，提升您的业务水平。' : 'Upgrade level usaha Anda dengan dompet digital yang bisa dikostumisasi sesuai dengan ciri khas dan identitas brand Anda.' }</div>
                                                {/* <div style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div> */}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item id='payment' eventKey='3' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('payment')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={paymentCollectionIcon} alt='paymentCollection' style={{ marginRight: 10 }} /> {isMobile ? <div>{ language === 'eng' ? 'Payment Collection' : language === 'chn' ? '收款' : 'Payment Collection' }</div> :`${ language === 'eng' ? 'Payment Collection' : language === 'chn' ? '收款' : 'Payment Collection' }`}</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'Receive cashless payments easily with automatic notifications for goods or services you sell through a virtual bank account or in a more conventional way, for example through minimarket counters or post office.' : language === 'chn' ? '通过虚拟银行账户或通过线下商户，轻松接收无现金付款。' : 'Terima pembayaran secara cashless dengan mudah dengan notifikasi otomatis atas barang atau jasa yang anda jual melalui akun virtual bank atau dengan cara yang lebih konvensional, misalnya melalui konter minimarket.' }</div>
                                                {/* <div style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div> */}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item id='transfer' eventKey='4' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('transfer')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={transferIcon} alt='transferPembayaran' style={{ marginRight: 10 }} /> {isMobile ? <div>{ language === 'eng' ? 'Transfer Payment' : language === 'chn' ? '转账付款' : 'Transfer Pembayaran' }</div> : `${ language === 'eng' ? 'Transfer Payment' : language === 'chn' ? '转账付款' : 'Transfer Pembayaran' }`}</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'Get the convenience of free payment transfers to various banks throughout Indonesia!' : language === 'chn' ? '享受免费转账到印度尼西亚各地银行的便利！' : 'Dapatkan kemudahan transfer pembayaran tanpa biaya ke berbagai bank di seluruh Indonesia!' }</div>
                                                {/* <div style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div> */}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item id='qris' eventKey='5' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('qris')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={qrisIcon} alt='qris' style={{ marginRight: 10 }} /> QRIS</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'Quick Response Code Indonesian Standard is Indonesia’s QR code standard to facilitate QR code-based transactions in Indonesia through Ezeelink which has a license to issue QRIS and provide QRIS solutions in your application or business' : language === 'chn' ? 'Quick Response Code Indonesia Standard (QRIS) 是一个印尼国家 QR 二维码标准。Ezeelink QRIS二维码的交易平台旨在为您的业务通过QRIS (已经连接超过一千多万商户) 提供支付和收款的解决方案。' : 'Quick Response Code Indonesian Standard merupakan standar kode QR Nasional untuk memfasilitasi transaksi berbasis kode QR di Indonesia melalui Ezeelink yang memiliki izin mengadakan QRIS (issuer) dan menyediakan solusi QRIS di aplikasi atau usaha Anda.' }</div>
                                                {/* <div style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div> */}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item id='loyalty' eventKey='6' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('loyalty')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={loyaltyIcon} alt='loyalty' style={{ marginRight: 10 }} /> {isMobile ? <div>{ language === 'eng' ? 'Loyalty Program' : language === 'chn' ? '忠诚计划' : 'Loyalty' }</div> : `${ language === 'eng' ? 'Loyalty Program' : language === 'chn' ? '忠诚计划' : 'Loyalty' }`}</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'Our Loyalty Program is designed to support your business by providing additional benefits to establish closeness and build customer loyalty to your brand.' : language === 'chn' ? '我们根据您的业务设计忠诚度计划, 增加用户粘性，并建立与用户长期亲密关系和对您品牌的忠诚度。' : 'Program Loyalty yang kami rancang dan sesuai dengan usaha Anda akan memberikan benefit tambahan untuk menjalin kedekatan dan membangun loyalitas pelanggan terhadap brand Anda.' }</div>
                                                {/* <div style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div> */}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Col>
                            </Row>
                        </div>
                    </div> :
                    <div className='mb-5 px-5 mt-5 align-items-center d-flex justify-content-center info' >
                        <div className={isMobile ? 'py-3' : "py-2"}>
                            <Row className={isMobile ? '' : 'px-5 me-3'}>
                                <Col xs={12} className={isMobile ? 'text-center py-2' : ''} style={{ fontSize: 26, fontWeight: 700, padding: 'unset' }}> { language === 'eng' ? 'Corporate & SME' : language === 'chn' ? '企业和出海企业' : 'Korporat & UMKM' } </Col>
                                <Col xs={12} className={isMobile ? 'text-center' : ''} style={{padding: 'unset'}}> { language === 'eng' ? 'Maximize effectiveness and efficiency in financial traffic for your venture and business through API integration, Plugin connection or transaction portal for Ezeelink products' : language === 'chn' ? '通过 Ezeelink 产品的 API 接口、插件小工具连接或交易门户，最大限度地提高您的业务和业务的财务流量的有效性和效率' : 'Maksimalkan efektifitas dan efisiensi keuangan untuk bisnis Anda melalui produk-produk Ezeelink' }</Col>
                                <Col xs={12} style={{padding: 'unset'}}>
                                    <Accordion activeKey={actKey1} defaultActiveKey={actKey1} flush className='accordion-homepage mt-3' style={{ borderBottom: "inset" }}>
                                        <Accordion.Item id='remitance' eventKey='0' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('remitance')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={remittanceIcon} alt='remitance' style={{ marginRight: 10 }} /> {isMobile ? <div>{ language === 'eng' ? 'Remittance for Business' : language === 'chn' ? '商务汇款' : 'Remitansi untuk Bisnis' }</div> : `${ language === 'eng' ? 'Remittance for Business' : language === 'chn' ? '商务汇款' : 'Remitansi untuk Bisnis' }`}</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'Receive or transfer payments for vendors, clients or business partners across borders faster, easier and more affordable with Remittance for Business service from Ezeelink.' : language === 'chn' ? '使用 Ezeelink 的商业汇款服务更快、更轻松、更实惠地为供应商、客户或商业合作伙伴接收或转移付款。' : 'Terima atau transfer pembayaran untuk vendor, klien ataupun rekan bisnis lintas batas negara dengan lebih cepat, mudah dan terjangkau dengan layanan Remittance for Business Ezeelink.' }</div>
                                                <div onClick={() => toRemitansiBisnis('bisnis')} style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item id='offshore' eventKey='1' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('offshore')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={offshoreBankIcon} alt='offshoreBankAccount' style={{ marginRight: 10 }} /> {isMobile ? <div>{ language === 'eng' ? 'Offshore Bank Account' : language === 'chn' ? '离岸银行账户' : 'Offshore Bank Account' }</div> : `${ language === 'eng' ? 'Offshore Bank Account' : language === 'chn' ? '离岸银行账户' : 'Offshore Bank Account' }`}</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'Collecting and managing funds overseas can be a challenge, Ezeelink offers an offshore bank account opening option to facilitate international trade and cross border e-commerce clients.' : language === 'chn' ? '在海外安全收集和管理资金可能是一项挑战，Ezeelink 提供离岸银行开户选项，为促进国际贸易和跨境电子商务客户提供便利。' : 'Mengumpulkan dan mengelola dana di luar negeri dapat menjadi tantangan, Ezeelink menawarkan opsi pembukaan rekening bank luar negeri untuk memfasilitasi perdagangan internasional dan klien e-commerce lintas negara.' }</div>
                                                <div onClick={() => toOffshore()} style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item id='whiteLabel' eventKey='2' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('whiteLabel')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={whiteLabelWalletIcon} alt='whiteLabelWallet' style={{ marginRight: 10 }} /> { language === 'eng' ? 'White-Label Wallet' : language === 'chn' ? '白牌钱包' : 'White-Label Wallet' }</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'Upgrade your level of business transaction with a digital wallet that can be customized according to your characteristics and brand identity.' : language === 'chn' ? '可根据您的需求定制的数字钱包，提升您的业务水平。' : 'Upgrade level usaha Anda dengan dompet digital yang bisa dikostumisasi sesuai dengan ciri khas dan identitas brand Anda.' }</div>
                                                {/* <div style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div> */}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item id='payment' eventKey='3' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('payment')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={paymentCollectionIcon} alt='paymentCollection' style={{ marginRight: 10 }} /> { language === 'eng' ? 'Payment Collection' : language === 'chn' ? '收款' : 'Payment Collection' }</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'Receive cashless payments easily with automatic notifications for goods or services you sell through a virtual bank account or in a more conventional way, for example through minimarket counters or post office.' : language === 'chn' ? '通过虚拟银行账户或通过线下商户，轻松接收无现金付款。' : 'Terima pembayaran secara cashless dengan mudah dengan notifikasi otomatis atas barang atau jasa yang anda jual melalui akun virtual bank atau dengan cara yang lebih konvensional, misalnya melalui konter minimarket.' }</div>
                                                {/* <div style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div> */}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item id='transfer' eventKey='4' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('transfer')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={transferIcon} alt='transferPembayaran' style={{ marginRight: 10 }} /> { language === 'eng' ? 'Transfer Payment' : language === 'chn' ? '转账付款' : 'Transfer Pembayaran' }</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'Get the convenience of free payment transfers to various banks throughout Indonesia!' : language === 'chn' ? '享受免费转账到印度尼西亚各地银行的便利！' : 'Dapatkan kemudahan transfer pembayaran tanpa biaya ke berbagai bank di seluruh Indonesia!' }</div>
                                                {/* <div style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div> */}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item id='qris' eventKey='5' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('qris')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={qrisIcon} alt='qris' style={{ marginRight: 10 }} /> QRIS</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'Quick Response Code Indonesian Standard is Indonesia’s QR code standard to facilitate QR code-based transactions in Indonesia through Ezeelink which has a license to issue QRIS and provide QRIS solutions in your application or business' : language === 'chn' ? 'Quick Response Code Indonesia Standard (QRIS) 是一个印尼国家 QR 二维码标准。Ezeelink QRIS二维码的交易平台旨在为您的业务通过QRIS (已经连接超过一千多万商户) 提供支付和收款的解决方案。' : 'Quick Response Code Indonesian Standard merupakan standar kode QR Nasional untuk memfasilitasi transaksi berbasis kode QR di Indonesia melalui Ezeelink yang memiliki izin mengadakan QRIS (issuer) dan menyediakan solusi QRIS di aplikasi atau usaha Anda.' }</div>
                                                {/* <div style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div> */}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item id='loyalty' eventKey='6' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('loyalty')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={loyaltyIcon} alt='loyalty' style={{ marginRight: 10 }} /> { language === 'eng' ? 'Loyalty Program' : language === 'chn' ? '忠诚计划' : 'Loyalty' }</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'Our Loyalty Program is designed to support your business by providing additional benefits to establish closeness and build customer loyalty to your brand.' : language === 'chn' ? '我们根据您的业务设计忠诚度计划, 增加用户粘性，并建立与用户长期亲密关系和对您品牌的忠诚度。' : 'Program Loyalty yang kami rancang dan sesuai dengan usaha Anda akan memberikan benefit tambahan untuk menjalin kedekatan dan membangun loyalitas pelanggan terhadap brand Anda.' }</div>
                                                {/* <div style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div> */}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Col>
                            </Row>
                        </div>
                        <div className="text-center px-3 py-2">
                            <img src={korporatUmkmImg} alt='korporat&UMKMImage' className={isMobile ? 'img-fluid' : ''} />
                        </div>
                    </div>
                }
                {isMobile ?
                    <div className='mb-5 px-5 mt-3 align-items-center d-flex justify-content-center '>
                        <div className={isMobile ? '' : "px-5 py-2"}>
                            <Row className={isMobile ? '' : 'px-5 ms-3'}>
                                <Col xs={12} className={isMobile ? 'text-center py-4' : ''} style={{ fontSize: 26, fontWeight: 700, padding: 'unset' }}>{ language === 'eng' ? 'Personal (Consumer)' : language === 'chn' ? '个人（消费者）' : 'Personal (Konsumer)' }</Col>
                                <Col xs={12} className={isMobile ? 'text-center' : ''} style={{padding: 'unset', fontFamily: 'Open Sans'}}>{ language === 'eng' ? 'Get the optimal experience in every digital financial transaction with products from Ezeelink.' : language === 'chn' ? '使用 Ezeelink 的产品在每笔数字金融交易中获得最佳体验。' : 'Dapatkan pengalaman optimal dalam tiap transaksi keuangan digital bersama produk-produk dari Ezeelink.' }</Col>
                                <Col xs={12}>
                                    <div className="text-center px-3 py-2">
                                        <img src={personalKonsumerImg} alt='personalKonsumerImage' className={isMobile ? 'img-fluid' : ''} />
                                    </div>
                                </Col>
                                <Col xs={12} style={{padding: 'unset'}}>
                                    <Accordion activeKey={actKey2} flush className='accordion-homepage mt-3' style={{ borderBottom: "inset" }}>
                                        <Accordion.Item id='remitancePersonal' eventKey='7' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('remitancePersonal')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={remittancePersonalIcon} alt='remitancePersonal' style={{ marginRight: 10 }} /> {isMobile ? <div>{ language === 'eng' ? 'Remittance for Personal' : language === 'chn' ? '个人汇款' : 'Remitansi untuk Personal' }</div> : `${ language === 'eng' ? 'Remittance for Personal' : language === 'chn' ? '个人汇款' : 'Remitansi untuk Personal' }`}</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'Reach families, relatives, and friends abroad through Ezeelink service for cross country transfer or receive money, with competitive exchange rates and no hidden fees.' : language === 'chn' ? '通过 Ezeelink 的跨国汇款或收款服务，以具有竞争力的汇率和无隐藏费用，联系在国外的家人、亲戚和朋友。' : 'Jangkau keluarga, kerabat maupun teman yang ada di luar negeri melalui layanan pengiriman atau penerimaan uang antar negara dari Ezeelink, dengan kurs rate bersaing dan tanpa biaya tersembunyi.' }</div>
                                                <div onClick={() => toRemitansiBisnis('personal')} style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item id='uangElektronik' eventKey='8' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('uangElektronik')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={emoneyIcon} alt='uangElektronik' style={{ marginRight: 10 }} /> {isMobile ? <div>{ language === 'eng' ? 'Electronic Money' : language === 'chn' ? '电子货币' : 'Uang Elektronik' }</div> : `${ language === 'eng' ? 'Electronic Money' : language === 'chn' ? '电子货币' : 'Uang Elektronik' }`} </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'Simplify activities and increase the security of digital transactions with electronic money managed by Ezeelink as a means of payment at various merchants that are easy to use or top up and can be customized according to your wishes.' : language === 'chn' ? '使用 Ezeelink 管理的电子货币作为各种商户的支付方案，提高电子支付的安全性，易于使用或充值，并可根据您的意愿进行定制。' : 'Permudah kegiatan dan tingkatkan keamanan transaksi digital dengan uang elektronik yang di kelola Ezeelink sebagai alat pembayaran di berbagai merchant yang mudah digunakan maupun diisi kembali (top up) dan dapat dikostumisasi sesuai keinginan Anda.' }</div>
                                                {/* <div style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div> */}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item id='wallet' eventKey='9' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('wallet')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={whiteLabelWalletIcon} alt='wallet' style={{ marginRight: 10 }} /> {isMobile ? <div>{ language === 'eng' ? 'Wallet' : language === 'chn' ? '钱包' : 'Wallet' }</div> : `${ language === 'eng' ? 'Wallet' : language === 'chn' ? '钱包' : 'Wallet' }`}</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'Our wallet feature makes it easy for users to set up and make financial transactions digitally.' : language === 'chn' ? 'Ezeelink 的钱包功能使用户可以轻松地以数字方式进行金融交易。' : 'Fitur wallet dari Ezeelink memudahkan pengguna dalam pengaturan dan melakukan transaksi keuangan secara digital.' }</div>
                                                {/* <div style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div> */}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item id='tarikTunaiTanpaKartu' eventKey='10' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('tarikTunaiTanpaKartu')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={cardlessCashIcon} alt='tarikTunaiTanpaKartu' style={{ marginRight: 10 }} /> {isMobile ? <div>{isMobile ? <div>{ language === 'eng' ? 'Cardless Cash Withdrawal' : language === 'chn' ? '无卡取款' : 'Tarik Tunai tanpa Kartu' }</div> : `${ language === 'eng' ? 'Cardless Cash Withdrawal' : language === 'chn' ? '无卡取款' : 'Tarik Tunai tanpa Kartu' }`}</div> : `${isMobile ? <div>{ language === 'eng' ? 'Cardless Cash Withdrawal' : language === 'chn' ? '无卡取款' : 'Tarik Tunai tanpa Kartu' }</div> : `${ language === 'eng' ? 'Cardless Cash Withdrawal' : language === 'chn' ? '无卡取款' : 'Tarik Tunai tanpa Kartu' }`}`} </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'An Inclusive Financial Solutions for people without a bank account will be able to make cash withdrawal.' : language === 'chn' ? '对于没有银行账户的人来说，无卡提款是一种普惠金融解决方案。' : 'Tarik Tunai Tanpa Kartu sebagai Solusi Inklusi Keuangan untuk masyarakat yang tidak memiliki rekening di bank.' }</div>
                                                {/* <div style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div> */}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item id='voucherDigital' eventKey='11' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('voucherDigital')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={voucherIcon} alt='voucherDigital' style={{ marginRight: 10 }} /> {isMobile ? <div>{ language === 'eng' ? 'Digital Voucher' : language === 'chn' ? '数字优惠券' : 'Voucher Digital' }</div> : `${ language === 'eng' ? 'Digital Voucher' : language === 'chn' ? '数字优惠券' : 'Voucher Digital' }`}</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'A concise and dynamic digital transaction method, easy to transfer as a gift, or used directly to help manage expenses' : language === 'chn' ? '简洁动态的数字化交易方式，方便作为礼物转账，或直接用于帮助节省费用' : 'Metode transaksi digital yang ringkas dan dinamis, mudah dipindahtangankan sebagai hadiah, maupun digunakan langsung untuk membantu pengaturan pengeluaran' }</div>
                                                {/* <div style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div> */}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item id='pulsaTagihan' eventKey='12' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('pulsaTagihan')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={pulsaTagihanIcon} alt='pulsaTagihan' style={{ marginRight: 10 }} /> {isMobile ? <div>{ language === 'eng' ? 'Bills & Credits' : language === 'chn' ? '信用和票据' : 'Pulsa & Tagihan' }</div> : `${ language === 'eng' ? 'Bills & Credits' : language === 'chn' ? '信用和票据' : 'Pulsa & Tagihan' }`}</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'Ease of access facilitated by Ezeelink to pay various bills or routine expenses, ranging from electricity or telephone credit, internet bills, water and various other services.' : language === 'chn' ? '使用从 Ezeelink 轻松来支付各种生活账单或日常开支，包括电费、电话费、互联网账单、水费和各种其他服务。' : 'Gunakan akses mudah dari Ezeelink untuk pembayaran berbagai tagihan atau pengeluaran rutin, mulai dari listrik, pulsa telepon, tagihan internet, air dan berbagai layanan lainnya.' }</div>
                                                {/* <div style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div> */}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Col>
                            </Row>
                        </div>
                    </div> :
                    <div className='mb-5 px-5 mt-3 align-items-center d-flex justify-content-center info2'>
                        <div className="text-center px-3 py-2">
                            <img src={personalKonsumerImg} alt='personalKonsumerImage' className={isMobile ? 'img-fluid' : ''} />
                        </div>
                        <div className={isMobile ? 'py-3' : "py-2"}>
                            <Row className={isMobile ? '' : 'px-5 ms-3'}>
                                <Col xs={12} className={isMobile ? 'text-center' : ''} style={{ fontSize: 26, fontWeight: 700, padding: 'unset' }}>{ language === 'eng' ? 'Personal (Consumer)' : language === 'chn' ? '个人（消费者）' : 'Personal (Konsumer)' }</Col>
                                <Col xs={12} className={isMobile ? 'text-center' : ''} style={{padding: 'unset'}}>{ language === 'eng' ? 'Get the optimal experience in every digital financial transaction with products from Ezeelink.' : language === 'chn' ? '使用 Ezeelink 的产品在每笔数字金融交易中获得最佳体验。' : 'Dapatkan pengalaman optimal dalam tiap transaksi keuangan digital bersama produk-produk dari Ezeelink.' }</Col>
                                <Col xs={12} style={{padding: 'unset'}}>
                                    <Accordion activeKey={actKey2} flush className='accordion-homepage mt-3' style={{ borderBottom: "inset" }}>
                                        <Accordion.Item id='remitancePersonal' eventKey='7' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('remitancePersonal')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={remittancePersonalIcon} alt='remitancePersonal' style={{ marginRight: 10 }} /> {isMobile ? <div>{ language === 'eng' ? 'Remittance for Personal' : language === 'chn' ? '个人汇款' : 'Remitansi untuk Personal' }</div> : `${ language === 'eng' ? 'Remittance for Personal' : language === 'chn' ? '个人汇款' : 'Remitansi untuk Personal' }`}</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'Reach families, relatives, and friends abroad through Ezeelink service for cross country transfer or receive money, with competitive exchange rates and no hidden fees.' : language === 'chn' ? '通过 Ezeelink 的跨国汇款或收款服务，以具有竞争力的汇率和无隐藏费用，联系在国外的家人、亲戚和朋友。' : 'Jangkau keluarga, kerabat maupun teman yang ada di luar negeri melalui layanan pengiriman atau penerimaan uang antar negara dari Ezeelink, dengan kurs rate bersaing dan tanpa biaya tersembunyi.' }</div>
                                                <div onClick={() => toRemitansiBisnis('personal')} style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item id='uangElektronik' eventKey='8' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('uangElektronik')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={emoneyIcon} alt='uangElektronik' style={{ marginRight: 10 }} /> {isMobile ? <div>{ language === 'eng' ? 'Electronic Money' : language === 'chn' ? '电子货币' : 'Uang Elektronik' } </div> : `${ language === 'eng' ? 'Electronic Money' : language === 'chn' ? '电子货币' : 'Uang Elektronik' } `}</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'Simplify activities and increase the security of digital transactions with electronic money managed by Ezeelink as a means of payment at various merchants that are easy to use or top up and can be customized according to your wishes.' : language === 'chn' ? '使用 Ezeelink 管理的电子货币作为各种商户的支付方案，提高电子支付的安全性，易于使用或充值，并可根据您的意愿进行定制。' : 'Permudah kegiatan dan tingkatkan keamanan transaksi digital dengan uang elektronik yang di kelola Ezeelink sebagai alat pembayaran di berbagai merchant yang mudah digunakan maupun diisi kembali (top up) dan dapat dikostumisasi sesuai keinginan Anda.' }</div>
                                                {/* <div style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div> */}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item id='wallet' eventKey='9' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('wallet')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={whiteLabelWalletIcon} alt='wallet' style={{ marginRight: 10 }} /> {isMobile ? <div>{ language === 'eng' ? 'Wallet' : language === 'chn' ? '钱包' : 'Wallet' }</div> : `${ language === 'eng' ? 'Wallet' : language === 'chn' ? '钱包' : 'Wallet' }`}</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'Our wallet feature makes it easy for users to set up and make financial transactions digitally.' : language === 'chn' ? 'Ezeelink 的钱包功能使用户可以轻松地以数字方式进行金融交易。' : 'Fitur wallet dari Ezeelink memudahkan pengguna dalam pengaturan dan melakukan transaksi keuangan secara digital.' }</div>
                                                {/* <div style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div> */}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item id='tarikTunaiTanpaKartu' eventKey='10' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('tarikTunaiTanpaKartu')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={cardlessCashIcon} alt='tarikTunaiTanpaKartu' style={{ marginRight: 10 }} /> {isMobile ? <div>{ language === 'eng' ? 'Cardless Cash Withdrawal' : language === 'chn' ? '无卡取款' : 'Tarik Tunai tanpa Kartu' }</div> : `${ language === 'eng' ? 'Cardless Cash Withdrawal' : language === 'chn' ? '无卡取款' : 'Tarik Tunai tanpa Kartu' }`} </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'An Inclusive Financial Solutions for people without a bank account will be able to make cash withdrawal.' : language === 'chn' ? '对于没有银行账户的人来说，无卡提款是一种普惠金融解决方案。' : 'Tarik Tunai Tanpa Kartu sebagai Solusi Inklusi Keuangan untuk masyarakat yang tidak memiliki rekening di bank.' }</div>
                                                {/* <div style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div> */}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item id='voucherDigital' eventKey='11' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('voucherDigital')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={voucherIcon} alt='voucherDigital' style={{ marginRight: 10 }} /> {isMobile ? <div>{ language === 'eng' ? 'Digital Voucher' : language === 'chn' ? '数字优惠券' : 'Voucher Digital' }</div> : `${ language === 'eng' ? 'Digital Voucher' : language === 'chn' ? '数字优惠券' : 'Voucher Digital' }` }</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'A concise and dynamic digital transaction method, easy to transfer as a gift, or used directly to help manage expenses' : language === 'chn' ? '简洁动态的数字化交易方式，方便作为礼物转账，或直接用于帮助节省费用' : 'Metode transaksi digital yang ringkas dan dinamis, mudah dipindahtangankan sebagai hadiah, maupun digunakan langsung untuk membantu pengaturan pengeluaran' }</div>
                                                {/* <div style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div> */}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item id='pulsaTagihan' eventKey='12' style={{backgroundColor: 'rgb(253,247,240)', borderRadius: 8}} onClick={() => openAccordionHanlde('pulsaTagihan')}>
                                            <Accordion.Header>
                                                <div style={{ fontSize: isMobile ? 16 : 18, fontWeight: 700 }} className={isMobile ? "d-flex justify-content-start align-items-center" : ""}><img src={pulsaTagihanIcon} alt='pulsaTagihan' style={{ marginRight: 10 }} /> { language === 'eng' ? 'Bills & Credits' : language === 'chn' ? '信用和票据' : 'Pulsa & Tagihan' }</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400 }}>{ language === 'eng' ? 'Ease of access facilitated by Ezeelink to pay various bills or routine expenses, ranging from electricity or telephone credit, internet bills, water and various other services.' : language === 'chn' ? '使用从 Ezeelink 轻松来支付各种生活账单或日常开支，包括电费、电话费、互联网账单、水费和各种其他服务。' : 'Gunakan akses mudah dari Ezeelink untuk pembayaran berbagai tagihan atau pengeluaran rutin, mulai dari listrik, pulsa telepon, tagihan internet, air dan berbagai layanan lainnya.' }</div>
                                                {/* <div style={{ color: '#077E86', fontFamily: 'Exo', fontWeight: 700, textDecorationLine: 'underline', fontSize: isMobile ? 14 : 16, cursor: 'pointer' }} className='mt-2'>{ language === 'eng' ? 'Learn more' : language === 'chn' ? '學到更多' : 'Pelajari lebih lanjut' } <span><img src={chevronRight} alt="chevron right" /></span> </div> */}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Col>
                            </Row>
                        </div>
                    </div>
                }
            </div>
            <div id="merchant" style={{ width: "100%", background: "#FFFFFF", paddingBottom: 24  }}>
                <div style={{ textAlign: 'center', marginBottom: 24, fontSize: 36, fontWeight: 700 }}>{ language === 'eng' ? 'Our Partners' : language === 'chn' ? '我们的同伴' : 'Partner Kami' }</div>
                <div style={{ textAlign: 'center' }} className="px-5">
                    <Row className={isMobile ? 'd-flex justify-content-center align-items-center' : 'd-flex justify-content-center align-items-center px-5'}>
                        <Col xl={2} xs={4}>
                            <img src={kfc} alt='kfc' className="img-fluid" />
                        </Col>
                        <Col xl={2} xs={4}>
                            <img src={tokopedia} alt='tokopedia' className="img-fluid" />
                        </Col>
                        <Col xl={2} xs={4}>
                            <img src={gojek} alt='gojek' className="img-fluid" />
                        </Col>
                        <Col xl={2} xs={4}>
                            <img src={pertamina} alt='pertamina' className="img-fluid" />
                        </Col>
                        <Col xl={2} xs={4}>
                            <img src={alfamart} alt='alfamart' className="img-fluid" />
                        </Col>
                        <Col xl={2} xs={4}>
                            <img src={blibli} alt='blibli' className="img-fluid" />
                        </Col>
                        <Col xl={2} xs={4}>
                            <img src={grab} alt='grab' className="img-fluid" />
                        </Col>
                        <Col xl={2} xs={4}>
                            <img src={indomaret} alt='indomaret' className="img-fluid" />
                        </Col>
                        <Col xl={2} xs={4}>
                            <img src={ezeepasar} alt='ezeepasar' className="img-fluid" />
                        </Col>
                        <Col xl={2} xs={4}>
                            <img src={map} alt='map' className="img-fluid" />
                        </Col>
                        <Col xl={2} xs={4}>
                            <img src={shopee} alt='shopee' className="img-fluid" />
                        </Col>
                        <Col xl={2} xs={4}>
                            <img src={transmart} alt='transmart' className="img-fluid" />
                        </Col>
                        <Col xl={2} xs={4}>
                            <img src={traveloka} alt='traveloka' className="img-fluid" />
                        </Col>
                    </Row>
                </div>
            </div>
            <div style={{ width: "100%", background: "#F9EDDC", padding: "48px 0px", textAlign: 'center' }}>
                <div style={{  fontSize: isMobile ? 26 : 36, fontWeight: 700, marginBottom: 24, padding: 'unset' }} className="text-center pt-3 ">{ language === 'eng' ? '6 Type of Solution' : language === 'chn' ? '6 种解决方案类型' : '6 Jenis Solusi' }</div>
                <div className='d-flex justify-content-center'>
                    <div style={{ maxWidth: 760, width: '100%', marginBottom: 48, fontFamily: 'Open Sans', fontSize: isMobile ? 14 : 16, fontWeight: 400, color: '#888888' }}>
                        {
                            language === 'eng' ?
                            'Products from Ezeelink platform are suitable for Corporate & SME use as well as for Personal (Consumer) use which are specified into 6 main types of solutions.' :
                            language === 'chn' ?
                            'Ezeelink 平台的产品适用于企业和中小企业使用以及个人（消费者）使用，分为 6 种主要服务类型' :
                            'Produk-produk dari platform Ezeelink sesuai untuk penggunaan Korporat & UMKM maupun untuk penggunaan Personal (Konsumer) yang terbagi dalam 6 tipe solusi utama.'
                        }
                    </div>
                </div>
                <Container>
                    <Row >
                        <Col xl={6} xs={12} style={{ display: 'flex', justifyContent: 'center' }} className={isMobile ? "px-4 my-2" : "px-3 my-1"}>
                            <Row style={{ maxWidth: 568, backgroundColor: '#FFFFFF', boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)', borderRadius: 8 }}>
                                <Col xl={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={apiConnectionIcon} alt='apiConnection' />
                                </Col>
                                <Col xl={8} style={{ textAlign: 'left', padding: 24 }}>
                                    <div style={{ fontSize: isMobile ? 20 : 24, fontWeight: 700, marginBottom: 4 }}>{ language === 'eng' ? 'API-based Solution' : language === 'chn' ? '基于 API 的解决方案' : 'Solusi berbasis API' }</div>
                                    <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 12, color: '#888888' }}>{ language === 'eng' ? 'Corporate' : language === 'chn' ? '公司的' : 'Korporat' }</div>
                                    <div style={{ fontSize: 14, color: '#888888', fontFamily: "Open Sans" }}>{ language === 'eng' ? 'integrate with your system and provide a better user experience while allowing you to customize the system.' : language === 'chn' ? '与您的系统集成并提供更好的用户体验，同时允许您自定义系统。' : 'terintegrasi dengan sistem Anda dan memberi pengalaman lebih kepada pengguna sekaligus memungkinkan Anda untuk mengkostumisasi sistem.' }</div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={6} xs={12} style={{ display: 'flex', justifyContent: 'center' }} className={isMobile ? "px-4 my-2" : "px-3 my-1"}>
                            <Row style={{ maxWidth: 568, backgroundColor: '#FFFFFF', boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)', borderRadius: 8 }}>
                                <Col xl={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={portalSolutionIcon} alt='portalSolution' />
                                </Col>
                                <Col xl={8} style={{ textAlign: 'left', padding: 24 }}>
                                    <div style={{ fontSize: isMobile ? 20 : 24, fontWeight: 700, marginBottom: 4 }}>{ language === 'eng' ? 'Portal-based Solution' : language === 'chn' ? '基于门户的解决方案' : 'Solusi berbasis Portal' }</div>
                                    <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 12, color: '#888888' }}>{ language === 'eng' ? 'Corporate & SME' : language === 'chn' ? '企業和中小微企業' : 'Korporat & UMKM' }</div>
                                    <div style={{ fontSize: 14, color: '#888888', fontFamily: "Open Sans" }}>{ language === 'eng' ? 'are practical digital transaction services because they do not require integration or installation.' : language === 'chn' ? '是实用的数字交易服务，因为它们不需要集成或安装。' : 'merupakan solusi transaksi digital praktis karena tidak memerlukan integrasi maupun instalasi.' }</div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row >
                        <Col xl={6} xs={12} style={{ display: 'flex', justifyContent: 'center' }} className={isMobile ? "px-4 my-2" : "px-3 my-1"}>
                            <Row style={{ maxWidth: 568, backgroundColor: '#FFFFFF', boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)', borderRadius: 8 }}>
                                <Col xl={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={pluginSolutionIcon} alt='pluginSolution' />
                                </Col>
                                <Col xl={8} style={{ textAlign: 'left', padding: 24 }}>
                                    <div style={{ fontSize: isMobile ? 20 : 24, fontWeight: 700, marginBottom: 4 }}>{ language === 'eng' ? 'Plugin-based Solution' : language === 'chn' ? '基于插件的解决方案' : 'Solusi berbasis Plugin' }</div>
                                    <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 12, color: '#888888' }}>{ language === 'eng' ? 'Corporate & SME' : language === 'chn' ? '企業和中小微企業' : 'Korporat & UMKM' }</div>
                                    <div style={{ fontSize: 14, color: '#888888', fontFamily: "Open Sans" }}>{ language === 'eng' ? 'add features to your application and provide convenience to both you and your business users.' : language === 'chn' ? '为您的应用程序添加功能并为您和您的业务用户提供便利。' : 'menambahkan fitur untuk aplikasi Anda dan memberi kemudahan baik kepada Anda maupun kepada pengguna bisnis Anda.' }</div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={6} xs={12} style={{ display: 'flex', justifyContent: 'center' }} className={isMobile ? "px-4 my-2" : "px-3 my-1"}>
                            <Row style={{ maxWidth: 568, backgroundColor: '#FFFFFF', boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)', borderRadius: 8 }}>
                                <Col xl={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={weblinkSolutionIcon} alt='weblinkSolution' />
                                </Col>
                                <Col xl={8} style={{ textAlign: 'left', padding: 24 }}>
                                    <div style={{ fontSize: isMobile ? 20 : 24, fontWeight: 700, marginBottom: 4 }}>{ language === 'eng' ? 'Web Link-based Solution' : language === 'chn' ? 'Web 链接的解决方案' : 'Solusi berbasis Web Link' }</div>
                                    <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 12, color: '#888888' }}>{ language === 'eng' ? 'Corporate & SME' : language === 'chn' ? '企業和中小微企業' : 'Korporat & UMKM' }</div>
                                    <div style={{ fontSize: 14, color: '#888888', fontFamily: "Open Sans" }}>{ language === 'eng' ? 'is a practical solution for accepting payments using a link to a Web page that can be personalized and distributed via social media or chat applications.' : language === 'chn' ? '可通过社交媒体或聊天应用进行个性化和分发的网页链接来接受付款。' : 'merupakan solusi praktis untuk menerima pembayaran dengan menggunakan link ke suatu halaman Web yang dapat di personalisasi dan disebarkan melalui media sosial ataupun aplikasi chat.' }</div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row >
                        <Col xl={6} xs={12} style={{ display: 'flex', justifyContent: 'center' }} className={isMobile ? "px-4 my-2" : "px-3 my-1"}>
                            <Row style={{ maxWidth: 568, backgroundColor: '#FFFFFF', boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)', borderRadius: 8 }}>
                                <Col xl={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={widgetSolutionIcon} alt='widgetSolution' />
                                </Col>
                                <Col xl={8} style={{ textAlign: 'left', padding: 24 }}>
                                    <div style={{ fontSize: isMobile ? 20 : 24, fontWeight: 700, marginBottom: 4 }}>{ language === 'eng' ? 'Widget-based Solution' : language === 'chn' ? '基于Web和App应用内插入小工具的解决方案' : 'Solusi berbasis Widget' }</div>
                                    <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 12, color: '#888888' }}>{ language === 'eng' ? 'Corporate' : language === 'chn' ? '公司的' : 'Korporat' }</div>
                                    <div style={{ fontSize: 14, color: '#888888', fontFamily: "Open Sans" }}>{ language === 'eng' ? 'is a solution in the form of independent Web applications to provide options and accept payments with a graphical user interface that can be embedded as part of your mobile or web application.' : language === 'chn' ? '应用小工具可通过用户界面提供选项并接受付款，该界面可嵌入到您的Web或 App 应用程序中。' : 'merupakan solusi berupa aplikasi Web independen untuk memberikan pilihan dan menerima pembayaran dengan user interface berbasis grafis yang bisa di tanamkan (embed) sebagai bagian dari aplikasi mobile atau web anda.' }</div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={6} xs={12} style={{ display: 'flex', justifyContent: 'center' }} className={isMobile ? "px-4 my-2" : "px-3 my-1"}>
                            <Row style={{ maxWidth: 568, backgroundColor: '#FFFFFF', boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)', borderRadius: 8 }}>
                                <Col xl={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={appSolutionIcon} alt='appSolution' />
                                </Col>
                                <Col xl={8} style={{ textAlign: 'left', padding: 24 }}>
                                    <div style={{ fontSize: isMobile ? 20 : 24, fontWeight: 700, marginBottom: 4 }}>{ language === 'eng' ? 'Ezeelink Application Solution' : language === 'chn' ? 'Ezeelink 应用解决方案' : 'Solusi Aplikasi Ezeelink' }</div>
                                    <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 12, color: '#888888' }}>{ language === 'eng' ? 'Personal (Consumer)' : language === 'chn' ? '個人（消費者）' : 'Personal (Konsumer)' }</div>
                                    <div style={{ fontSize: 14, color: '#888888', fontFamily: "Open Sans" }}>{ language === 'eng' ? 'is a mobile platform that makes it easy for its users to fulfill daily needs by providing a variety of digital products ranging from shopping vouchers, fuel, to phone credit and bill payments.' : language === 'chn' ? '是一个移动平台，通过提供从餐厅和购物券、水电单等一般生活消费到信用卡和账单支付等各种数字产品，可以轻松满足日常需求。' : 'merupakan platform mobile yang memberi kemudahan untuk pemenuhan kebutuhan harian dengan menyediakan berbagai macam produk digital mulai dari voucher belanja, bahan bakar, sampai pulsa dan pembayaran tagihan.' }</div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div style={{ width: "100%", background: "#FFFFFF", padding: "48px 0px" }}>
                <div style={{  fontSize: isMobile ? 26 : 36, fontWeight: 700, marginBottom: 24, padding: 'unset' }} className="text-center pt-3 ">{ language === 'eng' ? 'With Various Advantages!' : language === 'chn' ? '具有多种优势！' : 'Dengan Berbagai Keunggulan!' }</div>
                <Container>
                    <Row className=''>
                        <Col xl={6} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }} className="py-4">
                            <img src={linkedInOneImg} alt='linkedInOne' className="img-fluid" />
                        </Col>
                        <Col xl={6} xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} className="py-4">
                            <Row style={{ maxWidth: 568 }}>
                                <Col style={{ textAlign: 'left'}}>
                                    <div style={{ fontSize: 24, fontWeight: 700, marginBottom: 4 }}>{ language === 'eng' ? 'Linked in One' : language === 'chn' ? '一站式应用' : 'Terintegrasi jadi Satu' }</div>
                                    <div style={{ fontSize: 14, color: '#888888', fontFamily: "Open Sans" }}>{ language === 'eng' ? 'Ezeelink platform works quickly in integrating and converting digital (e-wallet, e-money) and conventional (bank, cash) transactions. So that it will be easier for you to manage your finances, expenses and income.' : language === 'chn' ? 'Ezeelink 平台可快速集成和转换数字（电子钱包、电子货币）和传统（银行、现金）交易。 因此，您可以更轻松地管理您的财务、开支和收入。' : 'Platform Ezeelink bekerja dengan cepat dalam mengintegrasikan dan mengubah transaksi digital (e-wallet, e-money) dan konvensional (bank, cash). Sehingga akan lebih mudah bagi Anda untuk mengatur keuangan, pengeluaran dan pemasukan Anda.' }</div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className=' info'>
                        <Col xl={6} xs={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} className="py-4">
                            <Row style={{ maxWidth: 568 }}>
                                <Col style={{ textAlign: 'left'}}>
                                    <div style={{ fontSize: 24, fontWeight: 700, marginBottom: 4 }}>{ language === 'eng' ? 'Competitive Rate' : language === 'chn' ? '有竞争力的性价比' : 'Tarif yang Kompetitif' } </div>
                                    <div style={{ fontSize: 14, color: '#888888', fontFamily: "Open Sans" }}>{ language === 'eng' ? 'Make sure you enjoy competitive rates for the value, quality and support we provide. So that in the long run it will provide extra benefits for you' : language === 'chn' ? '具性价比的本地化和贴地服务，确保您能享受我们各方面有质量的支持。为您本地和出海的业务提供极具竞争力的解决方案，达到最大的商业价值。' : 'Pastikan Anda menikmati tarif rate yang bersaing untuk value kualitas dan dukungan yang kami berikan. Sehingga dalam jangka panjang akan memberi keuntungan ekstra bagi Anda' }</div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={6} xs={12} style={{ display: 'flex', justifyContent: 'flex-start' }} className="py-4">
                            <img src={competitiveRateImg} alt='competitiveRate' className="img-fluid" />
                        </Col>
                    </Row>
                    <Row className=''>
                        <Col xl={6} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }} className="py-4">
                            <img src={makeItEzeeImg} alt='makeItEzee' className="img-fluid" />
                        </Col>
                        <Col xl={6} xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} className="py-4">
                            <Row style={{ maxWidth: 568 }}>
                                <Col style={{ textAlign: 'left'}}>
                                    <div style={{ fontSize: 24, fontWeight: 700, marginBottom: 4 }}>#MakeitEzee</div>
                                    <div style={{ fontSize: 14, color: '#888888', fontFamily: "Open Sans" }}>
                                        {
                                            language === 'eng' ?
                                            'Ezeelink platform is easy to get in to with self-explanatory information displays making it very convenient to use. So that your business growth and daily financial transactions become easier and safer!' :
                                            language === 'chn' ?
                                            'Ezeelink 平台易于掌握，简易理解的信息显示，易于使用。 让业务增长和您的日常财务交易变得更轻松、更安全！' :
                                            'Platform Ezeelink mudah dikuasai dengan tampilan informasi yang cukup jelas menjadikannya sangat mudah untuk digunakan. Sehingga pertumbuhan usaha maupun transaksi finansial harian Anda jadi lebih mudah dan aman!'
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer3/>
        </div>
    )
}

export default HomePage3