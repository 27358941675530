import React, { useEffect, useState } from 'react'
import Navbar3 from '../../component/Navbar3'
// import offshoreIcon from '../../assets/icon/offshore_icon.svg'
import whatsapp from '../../assets/icon/whatsapp.svg'
import '../../pages/css/Offshore.css'
import { Accordion, Col, Container, Nav, Navbar, OverlayTrigger, Popover, Row } from 'react-bootstrap'
import fleksibel from '../../assets/icon/fleksibel.svg'
import simple from '../../assets/icon/simple.svg'
import secure from '../../assets/icon/secure.svg'
import lebihHemat from '../../assets/icon/lebih_hemat_icon.svg'
import terpercaya from '../../assets/icon/terpercaya_icon.svg'
import Footer3 from '../../component/Footer3'
import { language, useTitle, waEzeelink } from '../../function/helpers'
import { useHistory, useLocation } from 'react-router-dom'
import mobile from 'is-mobile'
import ezIcon from '../../assets/icon/ezeelink_icon.png'
import ezIconCHN from '../../assets/icon/ezeelink_icon_chn.png'
import remittanceIcon from '../../assets/icon/icon_Remittance_Business.svg'
import remittancePersonalIcon from '../../assets/icon/icon_Remittance_personal.svg'
import offshoreBankIcon from '../../assets/icon/icon_offshore_bank.svg'
import emoneyIcon from '../../assets/icon/icon_emoney.svg'
import whiteLabelWalletIcon from '../../assets/icon/icon_white_label_wallet.svg'
import paymentCollectionIcon from '../../assets/icon/icon_payment_collection.svg'
import cardlessCashIcon from '../../assets/icon/icon_cardless_cash.svg'
import transferIcon from '../../assets/icon/icon_Transfer.svg'
import voucherIcon from '../../assets/icon/icon_voucher.svg'
import qrisIcon from '../../assets/icon/icon_qris.svg'
import pulsaTagihanIcon from '../../assets/icon/icon_pulsa_tagihan.svg'
import loyaltyIcon from '../../assets/icon/icon_loyalty.svg'
import menuIcon from '../../assets/icon/menu_icon.svg'
import dropDownWhite from '../../assets/icon/drop_down_white.svg'
import flagIdRoundIcon from '../../assets/icon/flag_id_round.svg'
import flagEnRoundIcon from '../../assets/icon/flag_en_round.svg'
import flagRrtRoundIcon from '../../assets/icon/flag_rrt_round.svg'
import chatMessageIcon from '../../assets/icon/chat_message.svg'
import headerOffshoreMobile from '../../assets/image/header_offshore_mobile.png'
import headerOffshore from '../../assets/image/header_offshore.png'

const Offshore = () => {
    useTitle("Offshore")

    const [push, setPush] = useState(false)
    const history = useHistory()
    const location = useLocation()
    const [showPopover, setShowPopover] = useState(false)
    const [showPopoverLang, setShowPopoverLang] = useState(false)
    const isMobile = mobile()

    function chnLang() {
        sessionStorage.setItem('lang', 'chn');
        window.location.reload();
    }

    function engLang() {
        sessionStorage.setItem('lang', 'eng');
        window.location.reload();
    }

    function inaLang() {
        sessionStorage.removeItem('lang');
        window.location.reload();
    }

    function toMenu () {
        setPush(true)
        history.push('/Menu', {prevURL: location.pathname})
        window.location.reload();
    }

    function tentangKamiHandle(menuName) {
        if (menuName === "tentang-kami") {
            history.push("/AboutUs")
            window.location.reload()
            // setIsAboutUS(true)
        }
    }

    function navbarHandle(menuName) {
        if (menuName === "tentang-kami") {
            history.push("/AboutUs")
            window.location.reload()
        } else if (menuName === 'pertanyaan') {
            history.push("/faq")
            window.location.reload()
        }
    }

    function navigateToHome(section) {
        history.push('/', {section: section});
        window.location.reload();
    }

    useEffect(() => {
        window.scroll({
            top: 100,
            left: 100,
            behavior: 'smooth'
        });
    }, [])
    return (
        <div style={{ maxWidth: "100%", maxHeight: "100%", width: "100%", height: "100%", fontFamily: "Exo" }}>
            <header className="header-home ">
                <Navbar bg="transparent" expand="lg" className={isMobile ? "d-flex position-absolute justify-content-between align-items-center mt-4 px-3" : "d-flex position-absolute justify-content-between align-items-center mt-4"} style={{ width: "100%", padding: isMobile ? "" : "0rem 5rem" }}>
                    {/* <Container> */}
                        <Navbar.Brand href="/" style={{ zIndex: 1 }}><img src={language === 'chn' ? ezIconCHN : ezIcon} alt='icon-ez' width = {isMobile ? 85.96 : 90} height = {isMobile ? 45.58 : 48} className="img-fluid" /></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toMenu} style={{ color: "#FFFFFF", border: "unset", zIndex: 1 }} ><img src={menuIcon} alt="menu"/></Navbar.Toggle>
                        {push === true ?
                            "" :
                            <>
                                <Navbar.Collapse id="basic-navbar-nav" style={{ width: "100%"}}>
                                    <Nav className='text-center' style={{ width: "100%"}}>
                                        <div className='text-center' style={{ width: "100%", zIndex: 1 }}>
                                            <OverlayTrigger
                                                show={showPopover}
                                                trigger={["hover", "focus"]}
                                                key={1}
                                                placement="bottom"
                                                overlay={
                                                    <Popover style={{ maxWidth: 600, maxHeight: 550 }} onMouseEnter={() => setShowPopover(true)} onMouseLeave={() => setShowPopover(false)} id='menu'>
                                                        <table className="table-products-item2 mt-4 mb-3">
                                                            <thead>
                                                                <th>{language === 'eng' ? 'Corporate & SME' : language === 'chn' ? '企业和出海企业' : 'Korporat & UMKM'}</th>
                                                                <th>{language === 'eng' ? 'Personal (Consumer)' : language === 'chn' ? '个人（消费者）' : 'Personal (Konsumer)'}</th>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('remitance')}>
                                                                        <div><img src={remittanceIcon} alt='remitance' /> { language === 'eng' ? 'Remittance for Bussiness' : language === 'chn' ? '商务汇款' : 'Remitansi untuk Bisnis' }</div>
                                                                    </td>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('remitancePersonal')}>
                                                                        <div><img src={remittancePersonalIcon} alt='remitancePersonal' /> { language === 'eng' ? 'Remittance for Personal' : language === 'chn' ? '个人汇款' : 'Remitansi untuk Personal' }</div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('offshore')}>
                                                                        <div><img src={offshoreBankIcon} alt='offshoreBankAccount' /> { language === 'eng' ? 'Offshore Bank Account' : language === 'chn' ? '离岸银行账户' : 'Offshore Bank Account' }</div>
                                                                    </td>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('uangElektronik')}>
                                                                        <div><img src={emoneyIcon} alt='uangElektronik' /> { language === 'eng' ? 'Electronic Money' : language === 'chn' ? '电子货币' : 'Uang Elektronik' }</div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('whiteLabel')}>
                                                                        <div><img src={whiteLabelWalletIcon} alt='whiteLabelWallet' /> { language === 'eng' ? 'White-Label Wallet' : language === 'chn' ? '白牌钱包' : 'White-Label Wallet' }</div>
                                                                    </td>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('wallet')}>
                                                                        <div><img src={whiteLabelWalletIcon} alt='wallet' /> { language === 'eng' ? 'Wallet' : language === 'chn' ? '钱包' : 'Wallet' }</div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('payment')}>
                                                                        <div><img src={paymentCollectionIcon} alt='paymentCollection' /> { language === 'eng' ? 'Payment Collection' : language === 'chn' ? '收款' : 'Payment Collection' }</div>
                                                                    </td>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('tarikTunaiTanpaKartu')}>
                                                                        <div><img src={cardlessCashIcon} alt='tarikTunaiTanpaKartu' /> { language === 'eng' ? 'Cardless Cash Withdrawal' : language === 'chn' ? '无卡取款' : 'Tarik Tunai tanpa Kartu' }</div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('transfer')}>
                                                                        <div><img src={transferIcon} alt='transferPembayaran' /> { language === 'eng' ? 'Transfer Payment' : language === 'chn' ? '转账付款' : 'Transfer Pembayaran' }</div>
                                                                    </td>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('voucherDigital')}>
                                                                        <div><img src={voucherIcon} alt='voucherDigital' /> { language === 'eng' ? 'Digital Voucher' : language === 'chn' ? '数字优惠券' : 'Voucher Digital' }</div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('qris')}>
                                                                        <div><img src={qrisIcon} alt='qris' /> QRIS</div>
                                                                    </td>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('pulsaTagihan')}>
                                                                        <div><img src={pulsaTagihanIcon} alt='pulsaTagihan' /> { language === 'eng' ? 'Bills & Credits' : language === 'chn' ? '信用和票据' : 'Pulsa & Tagihan' }</div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => navigateToHome('loyalty')}>
                                                                        <div><img src={loyaltyIcon} alt='loyalty' /> { language === 'eng' ? 'Loyalty Program' : language === 'chn' ? '忠诚计划' : 'Loyalty' }</div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Popover>
                                                }
                                            >
                                                <span onMouseEnter={() => setShowPopover(true)} onMouseLeave={() => setShowPopover(false)} className='menu-navbar-home2 py-3 px-3 mx-3' style={{ borderRadius: 4, color: "#FFFFFF", cursor: "pointer" }}>{ language === 'eng' ? 'Product & Solution' : language === 'chn' ? '产品与解决方案' : 'Produk & Solusi' } <img src={dropDownWhite} alt='arrowDropDownWhiteProduct' className={(showPopover) ? 'arrow-down-white-product-open' : "arrow-down-white-product"} /></span>
                                            </OverlayTrigger>
                                            <span onClick={() => navigateToHome('merchant')} className='menu-navbar-home2 py-3 px-3 mx-3' style={{ borderRadius: 4, color: "#FFFFFF", cursor: "pointer" }}>{ language === 'eng' ? 'Merchant & Partner' : language === 'chn' ? '商户和合作伙伴' : 'Merchant & Partner' }</span>
                                            <span onClick={() => navbarHandle("tentang-kami")} className='menu-navbar-home2 py-3 px-3 mx-3' style={{ borderRadius: 4, color: "#FFFFFF", cursor: "pointer" }}>{ language === 'eng' ? 'About Us' : language === 'chn' ? '关于我们' : 'Tentang Kami' }</span>
                                            <span onClick={() => navbarHandle("pertanyaan")} className='menu-navbar-home2 py-3 px-3 mx-3' style={{ borderRadius: 4, color: "#FFFFFF", cursor: "pointer", fontWeight: 700, fontFamily: 'Exo' }}>FAQ</span>
                                        </div>
                                    </Nav>
                                </Navbar.Collapse>
                                <Navbar.Collapse id="basic-navbar-nav" style={{ width: "15%"}}>
                                    <div className="text-end" style={{ width: "100%"}} >
                                        <OverlayTrigger
                                            show={showPopoverLang}
                                            trigger={["hover", "focus"]}
                                            key={1}
                                            placement="bottom"
                                            overlay={
                                                <Popover style={{ maxWidth: 138, maxHeight: 212 }} onMouseEnter={() => setShowPopoverLang(true)} onMouseLeave={() => setShowPopoverLang(false)} id='menu'>
                                                    <Popover.Body>
                                                        <div className='mt-2 mb-4' onClick={() => inaLang()}>
                                                            <span className='menu-navbar-lang2' style={{ borderRadius: 4, color: "#383838", cursor: "pointer" }}><img src={flagIdRoundIcon} alt='idFlag' style={{ marginRight: 15 }} /> ID</span>
                                                        </div>
                                                        <div className='mt-4 mb-4' onClick={() => engLang()}>
                                                            <span className='menu-navbar-lang2' style={{ borderRadius: 4, color: "#383838", cursor: "pointer" }}><img src={flagEnRoundIcon} alt='idFlag' style={{ marginRight: 10 }} /> EN</span>
                                                        </div>
                                                        <div className='mt-4 mb-2' onClick={() => chnLang()}>
                                                            <span className='menu-navbar-lang2' style={{ borderRadius: 4, color: "#383838", cursor: "pointer" }}><img src={flagRrtRoundIcon} alt='idFlag' style={{ marginRight: 10 }} /> CN</span>
                                                        </div>
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <span onMouseEnter={() => setShowPopoverLang(true)} onMouseLeave={() => setShowPopoverLang(false)} className='menu-navbar-home2 py-3 px-3' style={{ borderRadius: 4, color: "#FFFFFF", cursor: "pointer" }}>{language === 'eng' ? <><img src={flagEnRoundIcon} alt='enFlag' /> &nbsp; EN</> : language === 'chn' ? <><img src={flagRrtRoundIcon} alt='cnFlag' /> &nbsp; CN</> : <><img src={flagIdRoundIcon} alt='idFlag' /> &nbsp; ID</>}<img src={dropDownWhite} alt='arrowDropDownWhiteLanguage' className={(showPopoverLang) ? 'arrow-down-white-language-open' : "arrow-down-white-language"} style={{ marginLeft: 12 }} /></span>
                                        </OverlayTrigger>
                                    </div>
                                </Navbar.Collapse>
                            </>
                        }
                    {/* </Container> */}
                </Navbar>
                <Row>
                    <Col xl={12} style={{ paddingBottom: isMobile ? 30 : "", zIndex: 999, paddingRight: "unset" }} className="d-flex justify-content-end align-items-center bottom-0 position-fixed">
                        <img onClick={() => waEzeelink()} src={chatMessageIcon} alt='chatMessageIcon' style={{ cursor: 'pointer'}} width = {isMobile ? 48 : 85} height = {isMobile ? 48 : 85} className="img-fluid" />
                    </Col>
                </Row>
                <div  className={isMobile ? "position-absolute mt-5 pt-5" : "position-absolute mt-1 pt-5"} style={{ padding: isMobile ? "" : "0rem 2rem" }}>
                    <div className={isMobile ? "mt-5 pt-5 px-3" : (language === 'chn' ? "pt-5 mt-5 px-5" : language === 'eng' ? "pt-4 px-5" : "pt-3 px-5")}>
                        <br/><br/><div style={{ color: "#FFFFFF", fontSize: isMobile ? 36 : 48, fontWeight: 700, lineHeight: 1.4 }}>{ language === 'eng' ? <>Expand Your Business {isMobile ? '' : <br/>} Faster</> : language === 'chn' ? '更快地扩展您的业务' : <>Perluas Bisnis Anda Lebih {isMobile ? '' : <br/>} Cepat</>}</div>{language === 'chn' ? <br/> : ''}
                        <div style={{ color: "#FFFFFF", fontSize: isMobile ? 24 : 26, fontWeight: 700 }}>{ language === 'eng' ? 'What is Offshore Bank Account?' : language === 'chn' ? '什么是离岸银行账户' : 'Apa Itu Offshore Bank Account?' }</div><br/>
                        <br/><div style={{ color: "#FFFFFF", fontSize: isMobile ? 16 : 20, fontWeight: 400, fontFamily: 'Open Sans', width: isMobile ? '' : 598 }}>{ language === 'eng' ? <>Ezeelink provides a handy and hassle-free solution allowing you to create a bank account in Indonesia whilst smooth sailing on your business growth. <br/><br/> The bank account opening process doesn't require you to be present in the country, nor having a legal entity established in Indonesia.</> : language === 'chn' ? <>Ezeelink 提供了一个方便且无忧的解决方案，让您可以在印度尼西亚创建一个银行账户，同时为您的业务发展顺利进行。<br/><br/> 银行开户流程不需要您在该国，也不需要在印度尼西亚设立法人主体。</> : <>Ezeelink memberi solusi praktis dan mudah yang memungkinkan Anda membuat rekening bank di Indonesia sambil mengembangkan bisnis Anda dengan lancar. <br/><br/> Solusi dari Ezeelink ini memungkinkan Anda untuk membuat rekening bank di Indonesia. <br/><br/> Proses pembuatan rekening ini tidak mengharuskan Anda hadir, atau memiliki badan hukum yang didirikan di Indonesia.</>}</div>
                        <div style={{ fontFamily: 'Open Sans', fontSize:isMobile ? 14 : 16, fontWeight: 400, color: "#FFFFFF" }} className='mt-4'>{language === 'eng' ? 'Get More Info!' : language === 'chn' ? '获取更多信息！' : 'Dapatkan info lebih lanjut!'}</div>
                        <div className={isMobile ? 'mt-2 text-center' : 'mt-2'} style={{ width: isMobile ? "" : "100%" }} ><button onClick={() => waEzeelink()} className='btn-web-offshore' style={{ width: isMobile ? "100%" : 230 }}><span><img src={whatsapp} alt="whatsapp"/></span> +62 812-8091-8668</button></div>
                    </div>
                </div>
                <img className='header-navbar-home2 img-fluid' src={(isMobile) ? headerOffshoreMobile : headerOffshore} alt='header_offshore' />
            </header>
            <div className={isMobile ? 'text-center' : 'px-5 text-center'} style={{ width: "100%", background: "#F9EDDC", padding: "48px 0px" }}>
                <div className={isMobile ? 'px-3' : ''} style={{ fontWeight: 700, fontSize: 36, lineHeight: '50.4px', fontFamily: 'Exo' }}>{language === 'eng' ? 'Offshore Bank Account Benefits' : language === 'chn' ? '离岸银行账户福利' : 'Keuntungan Offshore Bank Account'}</div>
                <Row className={isMobile ? "d-flex justify-content-center align-items-center mt-5 gx-0 px-3" : "d-flex justify-content-center align-items-center mt-5 gx-0"}>
                    <Col xl={2} md={4} xs={12} className='px-3 mx-2 my-1' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: language === 'chn' ? 300 : 375,  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)", borderRadius: 8, background: "#FFFFFF", gap: 16 }}>
                        <div><img src={simple} alt="simple" /></div>
                        <div style={{ fontWeight: 700, fontSize: 24, lineHeight: "140%", fontFamily: 'Exo', color: '#383838' }} className="my-2">{language === 'eng' ? 'Simple' : language === 'chn' ? '简单' : 'Simpel'}</div>
                        <div style={{ fontWeight: 400, fontSize: 16, lineHeight: "140%", fontFamily: 'Open Sans', color: '#888888' }}>{language === 'eng' ? 'Save time and effort, and can skip on the Country\'s administrative matters on opening up a business entity.' : language === 'chn' ? '省时省力，可以节省在印尼开办法人主体的行政事务的时间和精力。' : 'Hemat waktu dan tenaga, dan dapat melewati urusan administrasi pemerintahan dalam membuka badan usaha.'}</div>
                    </Col>
                    <Col xl={2} md={4} xs={12} className='px-3 mx-2 my-1' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: language === 'chn' ? 300 : 375,  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)", borderRadius: 8, background: "#FFFFFF", gap: 16 }}>
                        <div><img src={terpercaya} alt="terpercaya" /></div>
                        <div style={{ fontWeight: 700, fontSize: 24, lineHeight: "140%", fontFamily: 'Exo', color: '#383838' }} className="my-2">{language === 'eng' ? 'Trusted' : language === 'chn' ? '值得信赖' : 'Terpercaya'}</div>
                        <div style={{ fontWeight: 400, fontSize: 16, lineHeight: "140%", fontFamily: 'Open Sans', color: '#888888' }}>{language === 'eng' ? 'Licensed by Bank of Indonesia and funds placed in the offshore account are guaranteed by the Indonesian government up to 2 billion IDR (equiv to 1 million RMB).' : language === 'chn' ? '获得印尼银行许可，存入离岸账户的资金由印尼政府担保高达20亿印尼盾（相当于100万人民币）' : 'Berlisensi Bank Indonesia dan dana nasabah yang ditempatkan pada offshore account dijamin pemerintah RI hingga 2 Miliar IDR (setara 1 milion RMB)'}</div>
                    </Col>
                    <Col xl={2} md={4} xs={12} className='px-3 mx-2 my-1' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: language === 'chn' ? 300 : 375,  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)", borderRadius: 8, background: "#FFFFFF", gap: 16 }}>
                        <div><img src={secure} alt="secure" /></div>
                        <div style={{ fontWeight: 700, fontSize: 24, lineHeight: "140%", fontFamily: 'Exo', color: '#383838' }} className="my-2">{language === 'eng' ? 'Secure' : language === 'chn' ? '安全' : 'Aman'}</div>
                        <div style={{ fontWeight: 400, fontSize: 16, lineHeight: "140%", fontFamily: 'Open Sans', color: '#888888' }}>{language === 'eng' ? 'Your privacy as a Bank customer is more protected.' : language === 'chn' ? '您作为银行客户的隐私受到更多保护' : 'Privasi Anda sebagai nasabah bank lebih terjaga'}</div>
                    </Col>
                    <Col xl={2} md={4} xs={12} className='px-3 mx-2 my-1' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: language === 'chn' ? 300 : 375,  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)", borderRadius: 8, background: "#FFFFFF", gap: 16 }}>
                        <div><img src={lebihHemat} alt="lebihHemat" /></div>
                        <div style={{ fontWeight: 700, fontSize: 24, lineHeight: "140%", fontFamily: 'Exo', color: '#383838' }} className="my-2">{language === 'eng' ? 'More Efficient' : language === 'chn' ? '更高效' : 'Lebih Hemat'}</div>
                        <div style={{ fontWeight: 400, fontSize: 16, lineHeight: "140%", fontFamily: 'Open Sans', color: '#888888' }}>{language === 'eng' ? 'Potentially tax-efficient and fees savings in Indonesian currency.' : language === 'chn' ? '印尼货币潜在的节税和费用节省' : 'Memungkinkan untuk efisiensi pajak dan biaya-biaya dalam mata uang Indonesia'}</div>
                    </Col>
                    <Col xl={2} md={4} xs={12} className='px-3 mx-2 my-1' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: language === 'chn' ? 300 : 375,  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)", borderRadius: 8, background: "#FFFFFF", gap: 16 }}>
                        <div><img src={fleksibel} alt="fleksibel" /></div>
                        <div style={{ fontWeight: 700, fontSize: 24, lineHeight: "140%", fontFamily: 'Exo', color: '#383838' }} className="my-2">{language === 'eng' ? 'Flexible' : language === 'chn' ? '灵活' : 'Fleksibel'}</div>
                        <div style={{ fontWeight: 400, fontSize: 16, lineHeight: "140%", fontFamily: 'Open Sans', color: '#888888' }}>{language === 'eng' ? 'Your bank accounts can be used for various purposes.' : language === 'chn' ? '您的银行账户可用于各种目的' : 'Rekening bank Anda bisa digunakan untuk berbagai keperluan'}</div>
                    </Col>
                </Row>
            </div>
            <div className={isMobile ? 'mt-5 px-2' : 'mt-5 px-5'}>
                <div className='text-center' style={{ fontWeight: 700, fontSize: 36, lineHeight: "140%", fontFamily: 'Exo', color: '#383838' }}>{language === 'eng' ? 'Frequently Asked Questions' : language === 'chn' ? '经常问的问题' : 'Pertanyaan Umum Offshore Bank Account Ezeelink'}</div>
                <div className={isMobile ? 'mb-5' : 'px-5 mx-5 mb-5'}>
                    <Accordion defaultActiveKey="0" flush className='mt-3' style={{ borderBottom: "inset" }}>
                        <Accordion.Item id='remitance' eventKey='0'>
                            <Accordion.Header>
                                <div style={{ fontSize: isMobile ? 16 : 20, fontWeight: 700, color: "#383838" }}>{language === 'eng' ? 'What is Ezeelink Offshore Bank Account?' : language === 'chn' ? '什么是Ezeelink离岸银行账户?' : 'Apa itu Offshore Bank Account Ezeelink?'}</div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400, color: "#888888" }}>{language === 'eng' ? 'Offshore Bank Account service from Ezeelink is the best solution for you, as a foreign individual, to have a bank account in Indonesia and can be used in various purposes.' : language === 'chn' ? 'Ezeelink 的离岸银行账户服务是一种解决方案，可帮助您作为外国人在印度尼西亚拥有可用于各种目的的银行账户.' : 'Layanan Offshore Bank Account dari Ezeelink adalah solusi terbaik bagi Anda selaku warga negara asing untuk memiliki rekening bank di Indonesia yang bisa Anda gunakan untuk berbagai keperluan.'}</div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item id='offshore' eventKey='1'>
                            <Accordion.Header>
                                <div style={{ fontSize: isMobile ? 16 : 20, fontWeight: 700, color: "#383838" }}>{language === 'eng' ? 'How to open a bank account in Indonesia using this service?' : language === 'chn' ? '如何使用该服务在印尼开设银行账户?' : 'Bagaimana cara membuka rekening bank di Indonesia menggunakan layanan ini?'}</div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400, color: "#888888" }}>{language === 'eng' ? 'Please contact our Customer Support for more enquiries in opening an Ezeelink Offshore Bank Account service in Indonesia.' : language === 'chn' ? '请联系我们的客户服务支持，以帮助您在印度尼西亚开设和使用Ezeelink离岸银行账户服务.' : 'Silakan hubungi Customer Support kami untuk pertanyaan tentang pembukaan rekening bank di Indonesia menggunakan layanan Offshore Bank Account Ezeelink.'}</div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item id='whiteLabel' eventKey='2'>
                            <Accordion.Header>
                                <div style={{ fontSize: isMobile ? 16 : 20, fontWeight: 700, color: "#383838" }}>{language === 'eng' ? 'Is offshore bank account legal?' : language === 'chn' ? '离岸银行账户是否合法?' : 'Apakah Offshore Bank Account ini legal?'}</div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400, color: "#888888" }}>{language === 'eng' ? 'Offshore Bank Account is fully legal, all banking activities will be monitored by Bank Indonesia as the central bank of Indonesia. Ezeelink is also registered and has a Payment Service Provider Permit (PJP) – Kategori Izin 1 NK 23/572/DKSP/Srt/B from Bank Indonesia.' : language === 'chn' ? '离岸银行账户是完全合法的，Ezeelink 也已注册并拥有印度尼西亚银行的支付服务提供商一级许可证 (PJP) – Kategori Izin 1 NK 23/572/DKSP/Srt/B。所有银行活动都将由印尼中央银行进行监控.' : 'Offshore Bank Account sepenuhnya legal, seluruh aktifitas kegiatan perbankan akan di pantau oleh Bank Indonesia selaku bank sentral Indonesia. Ezeelink juga terdaftar dan memiliki izin Penyedia Jasa Pembayaran (PJP) – Kategori Izin 1 NK 23/572/DKSP/Srt/B dari Bank Indonesia.'}</div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item id='payment' eventKey='3'>
                            <Accordion.Header>
                                <div style={{ fontSize: isMobile ? 16 : 20, fontWeight: 700, color: "#383838" }}>{language === 'eng' ? 'What is Offshore Bank Account Used For?' : language === 'chn' ? '离岸银行账户是做什么用的?' : 'Apa kegunaan dari Offshore Bank Account?'}</div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400, color: "#888888" }}>{language === 'eng' ? 'Besides it useful to help for managing business in Indonesia, having a bank account in Indonesia can also be very helpful for those of you who want to stay in Indonesia, whether for traveling or working.' : language === 'chn' ? '除了有助于管理在印度尼西亚的业务外，无论是旅行还是工作，在印度尼西亚拥有银行账户也对那些想留在印度尼西亚的您非常有帮助.' : 'Selain berguna untuk memudahkan pengelolaan bisnis di Indonesia, memiliki rekening bank di Indonesia juga bisa sangat membantu Anda yang ingin tinggal di Indonesia baik untuk berwisata ataupun bekerja.'}</div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item id='transfer' eventKey='4'>
                            <Accordion.Header>
                                <div style={{ fontSize: isMobile ? 16 : 20, fontWeight: 700, color: "#383838" }}>{language === 'eng' ? 'Why you will need Offshore Bank Account solution from Ezeelink?' : language === 'chn' ? '为什么您需要 Ezeelink 的离岸银行账户解决方案?' : 'Mengapa Anda memerlukan solusi Offshore Bank Account dari Ezeelink?'}</div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div style={{ fontFamily: "Open Sans", fontSize: isMobile ? 14 : 16, fontWeight: 400, color: "#888888" }}>{language === 'eng' ? 'Foreign individuals who want to open a bank account in Indonesia will require various supporting documents and administrative processes. Ezeelink with Offshore Bank Account solution will make it easier to manage bank account creation in Indonesia for foreign individuals.' : language === 'chn' ? '想要在印度尼西亚开设银行账户的外国人只需要提交 有关证明文件, 带有离岸银行账户解决方案的 Ezeelink 将使外国人更容易管理在印度尼西亚的银行账户.' : 'Bagi warga negara asing yang ingin membuka rekening bank di Indonesia akan memerlukan berbagai dokumen pendukung dan proses administrasi. Solusi Offshore Bank Account dari Ezeelink akan memudahkan pengurusan pembuatan rekening bank di Indonesia bagi warga negara asing.'}</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
            <Footer3 />
        </div>
    )
}

export default Offshore