import React, { useEffect } from 'react'

function RedirectBlog() {

    useEffect(() => {
        window.location.replace('http://ezeelinkblog.com/')
    }, [])


    return (
        <div>Redirecting...</div>
    )
}

export default RedirectBlog