import React, { useEffect } from "react"


export const BaseURL = "https://api.ezeelink.co.id/ezpaygateway/portal"
// export const BaseURL = "https://apid.ezeelink.co.id/mobile-demo/mobile2"

export const getToken = () => {
    return localStorage.getItem("token") || sessionStorage.getItem("token") || null
}

export const auth = "Basic ZXplZWxpbms6ZXplZWxpbms="

export function convertToRupiah(money) {
    return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', maximumFractionDigits: 0}).format(money)
}

export const convertDateTimeStamp = (time) => {
    return new Intl.DateTimeFormat('id-ID', { dateStyle: 'full', timeStyle: 'short' }).format(new Date(time * 1000))
}

export const waEzeelink = () => {
    window.open('https://wa.me/6281280918668')
}

export const language = sessionStorage.getItem('lang')

export function chnLang() {
    sessionStorage.setItem('lang', 'chn');
    window.location.reload();
}

export function engLang() {
    sessionStorage.setItem('lang', 'eng');
    window.location.reload();
}

export function inaLang() {
    sessionStorage.removeItem('lang');
    window.location.reload();
}

export const GOOGLE_ANALYTICS_ID = 'G-J8WZ3XWM2T'
// export const GOOGLE_ANALYTICS_ID = 'G-NVZ9H6DH26'
// export const GOOGLE_ANALYTICS_ID = 'UA-41655776-1'

export function useTitle(title) {
    useEffect(() => {
        const prevTitle = document.title + " || Ezeelink Indonesia"
        document.title = title + " || Ezeelink Indonesia"
        document.title = ""
        return () => {
            document.title = prevTitle
        }
    })
}