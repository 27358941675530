import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import "../../pages/css/LamanPayment.css";
import { useHistory, useLocation } from "react-router-dom";
import { auth, BaseURL, convertToRupiah } from "../../function/helpers";
import { signGenerateVaHowToPay } from "../../function/sign";
import encryptData from "../../function/encryptData";
import axios from "axios";

function MetodePembayaran() {
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const dataProfile = location.state;

  const showCheckboxes = () => {
    if (!expanded) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  };

  async function howToPay(bankCode, email, name, paymentId, payTypeId) {
    try {
      const dataParams = encryptData(
        `{"bank_code":"${bankCode}", "cust_email":"${email}", "cust_name":"${name}", "typaylink_code":"${paymentId}", "paytype_id":${payTypeId} }`
      );
      const headers = {
        "Content-Type": "application/json",
        Authorization: auth,
        sign: signGenerateVaHowToPay,
      };
      const howToPayGenerateVA = await axios.post(BaseURL +
        "/PaymentLinkWeb/GenerateVirtualAcc",
        { data: dataParams },
        { headers: headers }
      );
      // console.log(howToPayGenerateVA, 'generate va');
      if (
        howToPayGenerateVA.status === 200 &&
        howToPayGenerateVA.data.response_code === 200
      ) {
        toConfirm(
          paymentId
        );
      }
    } catch (e) {
      if (e.response.status === 400) {
        alert(e.response.data.response_message)
      }
    }
  }

  const toConfirm = (paymentId) => {
    history.push("/payment-confirm", { paymentId});
    window.location.reload();
  };

  useEffect(() => {
    return history.listen(action => {
      if (action !== "PUSH") {
        // console.log("mskk");
        window.location.reload();
      }
    });
  }, [history]);

  return (
    <div className="main-content align-items-center">
      <div className="base-content-custom" style={{ width: "100%" }}>
        <div className={(dataProfile.detailPayment.logo === null || dataProfile.detailPayment.corporate_name === null) ? "d-flex justify-content-center align-items-center py-4" : "d-flex justify-content-center align-items-center"}>
          <img src={dataProfile.detailPayment.logo} alt="" width="auto" height="44px" className="my-2" />
          <div className="mx-2">{dataProfile.detailPayment.corporate_name !== null ? dataProfile.detailPayment.corporate_name : ""}</div>
        </div>
      </div>
      <div style={{ borderRadius: 8 }}>
        <div className="d-flex justify-content-center align-items-center">
          <div className="px-4 py-4" style={{ width: 480 }}>
            <div className="mb-2" style={{ fontSize: 14 }}>
              Total Pembayaran
            </div>
            <h3
              style={{
                margin: "unset",
                fontFamily: "Exo",
                fontWeight: 700,
                fontSize: 24,
              }}
            >
              {convertToRupiah(dataProfile?.detailPayment.amount)}
            </h3>
            <div className="d-flex justify-content-between align-items-center">
              <div style={{ color: "#888888", fontSize: 14 }}>
                Payment ID: {dataProfile?.paymentId}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                  padding: "unset",
                }}
              >
                {!expanded ? (
                  <button
                    style={{
                      fontFamily: "Exo",
                      fontSize: 16,
                      fontWeight: 700,
                      alignItems: "center",
                      gap: 8,
                      height: 48,
                      color: "#077E86",
                      background: "unset",
                      border: "unset",
                    }}
                    onClick={showCheckboxes}
                  >
                    Detail{" "}
                    <FontAwesomeIcon icon={faChevronDown} className="mx-2" />
                  </button>
                ) : (
                  <button
                    style={{
                      fontFamily: "Exo",
                      fontSize: 16,
                      fontWeight: 700,
                      alignItems: "center",
                      gap: 8,
                      height: 48,
                      color: "#077E86",
                      background: "unset",
                      border: "unset",
                    }}
                    onClick={showCheckboxes}
                  >
                    Detail{" "}
                    <FontAwesomeIcon icon={faChevronUp} className="mx-2" />
                  </button>
                )}
              </div>
            </div>
            <div style={{ border: "3px solid #F0F0F0" }}></div>
            {!expanded ? (
              <>
                <div
                  style={{ fontFamily: "Exo", fontWeight: 700, fontSize: 16 }}
                  className="my-3"
                >
                  Pilih Metode Pembayaran
                </div>
                <div>
                  {" "}
                  {dataProfile?.detailPayment?.payment_list?.map((item, index) => {
                    return (
                      <div onClick={() => howToPay(item.mpaytype_bank_code, dataProfile.email, dataProfile.name, dataProfile.paymentId, item.mpaytype_id)} className="my-2 d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <div className="d-flex justify-content-start align-items-center">
                          <span
                            className="my-2 rounded square border border-1 d-flex justify-content-center align-items-center"
                            style={{ width: "64px", height: "55px" }}
                          >
                            <img
                              src={item.mpaytype_icon}
                              alt={item.mpaytype_name}
                              width={50}
                            />
                          </span>

                          <div className="mx-2">{item.mpaytype_name}</div>
                        </div>
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <>
                <div
                  style={{ fontFamily: "Exo", fontWeight: 700, fontSize: 16 }}
                  className="my-3"
                >
                  Deskripsi
                </div>
                <div style={{ fontFamily: "Nunito", fontSize: 14 }}>
                  {dataProfile.detailPayment.desc}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MetodePembayaran;
